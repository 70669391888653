import { deleteWithCsrf, postWithCsrf, fetchWithCredentials } from './restClient';
import { outageUrl, outageCandidatesUrl } from './urls';

export const getOutagesByMonth = (month, year) => fetchWithCredentials(`${outageUrl}?month=${month}&year=${year}`)();

export const createOutage = postWithCsrf(outageUrl, {
  'Content-Type': 'application/json',
});

export const deleteOutage = (id) =>
  deleteWithCsrf(`${outageUrl}${id}/`, {
    'Content-Type': 'application/json',
  });

export const getOutageCandidates = fetchWithCredentials(outageCandidatesUrl);
