import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Col, Row, Navbar } from 'react-bootstrap';
import SideBar from '../SideBar';
import Welcome from './Welcome';
import PipeLogo from '../icons/pipeLogo.svg';

function Header() {
  return (
    <Navbar className='navbar' fixed='top'>
      <div className='col-sm-2' style={{ textAlign: 'center' }}>
        <img src={PipeLogo} />
      </div>
      <Welcome />
    </Navbar>
  );
}

const ProtectedPage = ({ children, authCondition }) => {
  let navigate = useNavigate();

  const authenticated = useSelector((state) => {
    return Boolean(state.auth?.user?.email || state.auth?.user?.username);
  });

  useEffect(() => {
    if (authCondition !== authenticated) {
      const nextPath = authenticated ? '/' : '/login';
      navigate(nextPath);
    }
  }, [authenticated, authCondition, navigate]);

  return children;
};

ProtectedPage.propTypes = {
  children: PropTypes.node,
  authCondition: PropTypes.bool,
};

const LoginRequiredPage = ({ children }) => {
  return (
    <ProtectedPage authCondition={true}>
      <Header />
      <div className='main-container'>
        <div className='side-bar'>
          <SideBar />
        </div>
        <div className='component'>{children}</div>
      </div>
    </ProtectedPage>
  );
};

LoginRequiredPage.propTypes = {
  children: PropTypes.node,
};

export const Level1RequiredPage = ({ children }) => {
  let navigate = useNavigate();

  const authenticated = useSelector((state) => {
    return Boolean(state.auth?.user?.email || state.auth?.user?.username) && Boolean(state.auth?.user?.level === 1);
  });

  useEffect(() => {
    authenticated || navigate('/');
  }, [authenticated, navigate]);

  return (
    <>
      <Header />
      <div className='main-container'>
        <div className='side-bar'>
          <SideBar />
        </div>
        <div className='component'>{children}</div>
      </div>
    </>
  );
};

Level1RequiredPage.propTypes = {
  children: PropTypes.node,
};

export const LogoutRequiredPage = ({ children }) => {
  return <ProtectedPage authCondition={false}>{children}</ProtectedPage>;
};

LogoutRequiredPage.propTypes = {
  children: PropTypes.node,
};

export default LoginRequiredPage;
