import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, FloatingLabel, Form, Offcanvas, Row } from 'react-bootstrap';
import { Button, toastifyTheme } from '../../theme';
import PropTypes, { func } from 'prop-types';
import { asSelectOptions } from '../types';
import { Priority, Probability, Complexity } from './Types';
import { backendErrorParser } from '../backendErrorParser';
import { withNotAvailableOption } from '../OptionSelectUtils';
import { sortByFullname } from '../staffSorter';
import { fetchCasePrincipalCandidates, createCase } from '../../api/case';
import NumberInputField from '../common/NumberInputField/NumberInputField';
import { fetchCaseCandidates } from '../../store/candidates';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

const AddNewCaseForm = (props) => {
  const dispatch = useDispatch();
  const csrf = useSelector((state) => state.auth?.csrf);
  const [formMessage, setFormMessage] = useState();
  const [casePrincipalCandidates, setCasePrincipalCandidates] = useState([]);
  const startDateInput = useRef();
  const endDateInput = useRef();
  const budgetRef = useRef();

  useEffect(() => {
    fetchCasePrincipalCandidates()
      .then((candidates) => {
        setCasePrincipalCandidates(sortByFullname(candidates));
        setFormMessage(null);
      })
      .catch((error) => {
        setFormMessage('Fetch case principal candidates failed');
      });
  }, []);

  const casePrincipalDropdown = withNotAvailableOption(
    casePrincipalCandidates.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );

  function cancelHandler(e) {
    e.preventDefault();
    props.onClose();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormMessage(null);
    const data = new FormData(event.currentTarget);
    const case_code = data.get('case_code');
    const case_name = data.get('case_name');
    const description = data.get('description');
    const client = data.get('client');
    const budget = data.get('budget').replace(/,/g, '') || 0;
    const start_date = data.get('start_date') ? data.get('start_date') : null;
    const end_date = data.get('end_date') ? data.get('end_date') : null;
    const principal = data.get('principal');
    const xo = data.get('xo');
    const mdps = data.get('mdps');
    const priority = data.get('priority');
    const complexity = data.get('complexity');
    const probability = data.get('probability');

    const body = {
      case_code,
      case_name,
      description,
      client,
      budget,
      start_date,
      end_date,
      principal,
      xo,
      mdps,
      priority,
      complexity,
      probability,
    };

    const response = await createCase(csrf, {
      body: JSON.stringify(body),
    });
    if (response.success) {
      dispatch(fetchCaseCandidates());
      toast.success(`Case ${case_name} registered successfully`, toastifyTheme);
      props.onClose();
      event.target.reset();
    } else {
      Object.keys(response.data).map((fieldName) => {
        if (fieldName === 'timeline') {
          raiseFieldError(event, 'start_date');
          raiseFieldError(event, 'end_date');
        } else raiseFieldError(event, fieldName);
      });
      setFormMessage(backendErrorParser(response.data));
    }
  };

  const raiseFieldError = (event, fieldName) => {
    const errorInputField = Object.values(event.target).find((field) => field.name == fieldName);
    if (errorInputField) errorInputField.classList.add('error-input-field');
  };

  const handleDateChange = (event) => {
    const startDate = startDateInput.current.value;
    const endDate = endDateInput.current.value;
    if (startDate && endDate && moment(startDate).isSameOrAfter(moment(endDate))) {
      startDateInput.current.classList.add('error-input-field');
      endDateInput.current.classList.add('error-input-field');
      setFormMessage(backendErrorParser({ timeline: 'Timeline end date must be later than start date' }));
    } else {
      startDateInput.current.classList.remove('error-input-field');
      endDateInput.current.classList.remove('error-input-field');
      setFormMessage(backendErrorParser({}));
    }
  };

  const handleOnClick = (event) => {
    event.target.classList.remove('error-input-field');
  };

  return (
    <>
      <Offcanvas show={props.show} onHide={props.onClose} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Add new case</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Case Code' className='mb-3'>
                    <Form.Control
                      data-testid='input-case-code'
                      name='case_code'
                      placeholder='dummy'
                      onClick={handleOnClick}
                    />
                  </FloatingLabel>
                  <div className='text-muted'>Example: 000000-00</div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Case Name' className='mb-3'>
                    <Form.Control
                      data-testid='input-case-name'
                      name='case_name'
                      placeholder='dummy'
                      onClick={handleOnClick}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='Description' className='mb-3'>
                    <Form.Control
                      name='description'
                      as='textarea'
                      placeholder='dummy'
                      data-testid='input-description'
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='Client'>
                      <Form.Control
                        data-testid='input-client'
                        name='client'
                        placeholder='dummy'
                        onClick={handleOnClick}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <NumberInputField
                      label='Budget'
                      id='input-budget'
                      name='budget'
                      placeholder='dummy'
                      refProp={budgetRef}
                      onClick={handleOnClick}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='Start Date' className='mb-3'>
                      <Form.Control
                        data-testid='input-start'
                        name='start_date'
                        type='date'
                        onChange={handleDateChange}
                        onClick={handleOnClick}
                        ref={startDateInput}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='End Date' className='mb-3'>
                      <Form.Control
                        data-testid='input-end'
                        name='end_date'
                        type='date'
                        onChange={handleDateChange}
                        onClick={handleOnClick}
                        ref={endDateInput}
                      />
                    </FloatingLabel>
                    <Form.Control.Feedback type='invalid'>Please provide a valid city.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Principal' className='mb-3'>
                    <Form.Select data-testid='select-principal' name='principal'>
                      {casePrincipalDropdown}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='XO' className='mb-3'>
                    <Form.Control data-testid='input-xo' name='xo' placeholder='dummy' onClick={handleOnClick} />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='MDPs' className='mb-3'>
                    <Form.Control data-testid='input-mdps' name='mdps' placeholder='dummy' onClick={handleOnClick} />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='Priority' className='mb-3'>
                      <Form.Select data-testid='select-priority' name='priority'>
                        {asSelectOptions(Priority)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='Probability' className='mb-3'>
                      <Form.Select data-testid='select-probability' name='probability'>
                        {asSelectOptions(Probability)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='Complexity' className='mb-3'>
                      <Form.Select data-testid='select-complexity' name='complexity'>
                        {asSelectOptions(Complexity)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              <div className='form-footer-btn-group'>
                <div></div>
                <div className='form-footer-btn-group-right'>
                  <Button data-testid='add-case-form-cancel' outlined onClick={cancelHandler}>
                    Cancel
                  </Button>
                  <Button data-testid='add-case-form-submit' type='submit'>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
AddNewCaseForm.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
};
export default AddNewCaseForm;
