import React from 'react';
import { useEffect, useCallback, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { fetchAvailableCapacity } from '../../api/dashboard';
import { availableCapacityFormatter } from '../../formatters/dashboard';
import styles from './Dashboard.module.css';

const AvailableCapacity = () => {
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState('this_week_capacity');
  const userLevel = useSelector((state) => state.auth?.user?.level);

  const getData = useCallback(async () => {
    const _data = await fetchAvailableCapacity();
    await setData(availableCapacityFormatter(_data));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const tabSelectHandler = useCallback((eventKey, event) => {
    setCurrentTab(eventKey);
  }, []);

  const currentTabData = data[currentTab] || {};

  return (
    <>
      <h3>Available capacity</h3>
      {userLevel !== 3 && (
        <>
          <Tabs defaultActiveKey='this_week_capacity' className='mb-3' onSelect={tabSelectHandler}>
            <Tab eventKey='this_week_capacity' title='This week'></Tab>
            <Tab eventKey='next_week_capacity' title='Next week'></Tab>
            <Tab eventKey='week_after_next_capacity' title='Next 2 weeks'></Tab>
          </Tabs>
          <Table borderless className={styles['capacity-table']}>
            <thead>
              <tr>
                <th className={styles['capacity-header']}>{currentTabData.PL?.count || 0} PL</th>
                <th className={styles['capacity-header']}>{currentTabData.Anchor?.count || 0} Anchor</th>
                <th className={styles['capacity-header']}>{currentTabData.Utility?.count || 0} Utility</th>
                <th className={styles['capacity-header']}>{currentTabData.Developing?.count || 0} Developing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {['PL', 'Anchor', 'Utility', 'Developing'].map((role) => {
                  return (
                    <td key={role} className={styles['capacity-items']}>
                      {currentTabData[role]?.roster_members?.map((rosterMember, id) => (
                        <div className={styles['capacity-item']} key={id}>
                          {rosterMember.fullname}
                          <span className={styles['capacity-item-score']}> ({rosterMember.score})</span>
                        </div>
                      ))}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default AvailableCapacity;
