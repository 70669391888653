import { fetchWithCredentials } from './restClient';
import {
  totalRingfenceRosterUrl,
  staffedOnActiveCaseUrl,
  casesStartingNextWeekUrl,
  availableCapacityUrl,
} from './urls';

export const fetchTotalRingfenceRoster = fetchWithCredentials(totalRingfenceRosterUrl);

export const fetchStaffedOnActiveCase = fetchWithCredentials(staffedOnActiveCaseUrl);

export const fetchCasesStartingNextWeek = fetchWithCredentials(casesStartingNextWeekUrl);

export const fetchAvailableCapacity = fetchWithCredentials(availableCapacityUrl);
