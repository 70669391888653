import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Alert, Container } from 'react-bootstrap';

const ErrorWithRetry = ({ text, onRetry = () => {} }) => {
  return (
    <Container>
      <Row className='justify-content-sm-center'>
        <Alert variant='danger'>{text}</Alert>
        <Button data-testid='retry-button' variant='outline-success' onClick={onRetry} style={{ maxWidth: '10%' }}>
          Try again.
        </Button>
      </Row>
    </Container>
  );
};

ErrorWithRetry.propTypes = {
  text: PropTypes.string,
  onRetry: PropTypes.func,
};

export default ErrorWithRetry;
