import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import { Button, toastifyTheme } from '../../theme';
import { backendErrorParser } from '../backendErrorParser';
import { withNotAvailableOption } from '../OptionSelectUtils';
import { updateDraftStaffing } from '../../api/staffing';
import { fetchStaffingByCase, selectAllStaffing } from '../../store/staffing';
import {
  fetchStaffingRosterMemberCandidates,
  selectAllStaffingRosterMemberCandidates,
} from '../../store/staffingRosterMemberCandidates';
import RemoveButton from '../common/RemoveButton/RemoveButton';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog';
import AddNewButton from '../common/AddNewButton/AddNewButton';
import { toast } from 'react-toastify';

const ManualDraftStaffingForm = () => {
  const minNumberOfRosterField = 5;
  const staffingData = useSelector(selectAllStaffing);
  const draftStaffingData = useSelector(selectAllStaffingRosterMemberCandidates);
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState();
  const [selectedCase, setSelectedCase] = useState();
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [selectedRoster, setSelectedRoster] = useState();
  const [numberOfRosterField, setNumberOfRosterField] = useState(minNumberOfRosterField);
  const [currentRosterMemberList, setCurrentRosterMemberList] = useState([]);
  const rolesRef = useRef([]);
  const rosterMembersRef = useRef([]);
  const csrf = useSelector((state) => state.auth?.csrf);

  useEffect(() => {
    dispatch(fetchStaffingByCase());
    dispatch(fetchStaffingRosterMemberCandidates());
  }, []);

  useEffect(() => {
    setCurrentRosterMemberList(getMemberList(selectedCase));
  }, [selectedCase]);

  useEffect(() => {
    (async () => {
      await setNumberOfRosterField(
        currentRosterMemberList.length > minNumberOfRosterField
          ? currentRosterMemberList.length
          : minNumberOfRosterField
      );
      refreshRosterMember();
      setRosterMember();
    })();
  }, [currentRosterMemberList]);

  const rosterMembersDropDown = withNotAvailableOption(
    draftStaffingData.map((item) => (
      <option key={item.id} value={item.id}>
        {item.fullname}
      </option>
    ))
  );

  const caseDropDown = withNotAvailableOption(
    staffingData.map((item) => (
      <option key={item.id} value={item.id}>
        {item.case_code} - ({item.case_name})
      </option>
    ))
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    const memberIds = rosterMembersRef.current.filter((i) => i?.value).map((i) => i?.value);
    const res = await updateDraftStaffing(selectedCase)(csrf, { body: JSON.stringify(memberIds) });

    if (res.success) {
      toast.success('Assigned successfully', toastifyTheme);
      event.target.reset();
      dispatch(fetchStaffingByCase());
      setSelectedCase();
      setNumberOfRosterField(minNumberOfRosterField);
      setCurrentRosterMemberList([]);
    } else {
      const errorBody = await response.json();
      setFormMessage(backendErrorParser(errorBody));
    }
  };

  const handleRosterMembersDropdownChange = (event) => {
    const currentRef = event.target;
    const index = rosterMembersRef.current.indexOf(currentRef);
    const member = draftStaffingData.find((it) => it.id == currentRef.value);
    rolesRef.current[index].value = member ? member.role : '';
  };

  const handleOnClick = () => {
    setFormMessage();
  };

  const getMemberList = (currentCaseId) => {
    let memberList = [];
    const currentCase = staffingData.find((item) => item.id == currentCaseId);
    const currentCaseStaffing = currentCase?.all_staffing;
    if (currentCaseStaffing)
      Object.entries(currentCaseStaffing).forEach(([_, rMemberList]) => {
        rMemberList.forEach((member) => memberList.push(member.id));
      });
    return memberList;
  };

  const refreshRosterMember = () => {
    Array.from(Array(numberOfRosterField).keys()).map((index) => {
      if (rosterMembersRef.current[index]) rosterMembersRef.current[index].value = '';
      if (rolesRef.current[index]) rolesRef.current[index].value = '';
    });
  };

  const setRosterMember = () => {
    const rMemberList = draftStaffingData.filter((rMember) => currentRosterMemberList.includes(rMember.id));
    rMemberList.map((rMember, index) => {
      rosterMembersRef.current[index].value = rMember?.id;
      rolesRef.current[index].value = rMember?.role;
    });
  };

  const handleCaseDropdownChange = (event) => {
    setSelectedCase(event.currentTarget.value);
  };

  const handleRemove = (event) => {
    event.preventDefault();
    setShowRemoveDialog(true);
    setSelectedRoster(event.target.id);
  };

  const handlePositiveClick = (event) => {
    event.preventDefault();
    setShowRemoveDialog(false);
  };

  const handleNegativeClick = (event) => {
    event.preventDefault();
    setShowRemoveDialog(false);
    rosterMembersRef.current[selectedRoster].value = '';
    rolesRef.current[selectedRoster].value = '';
  };

  const handleOnClickAddNewMember = (event) => {
    event.preventDefault();
    setNumberOfRosterField((preVal) => preVal + 1);
  };

  const addRosterMemberField = (item) => {
    return (
      <Row key={item} className='w-100 justify-content-between'>
        <Form.Group className='mb-2 col-sm-6'>
          <FloatingLabel label='Roster member' className='mb-3'>
            <Form.Select
              onClick={handleOnClick}
              ref={(i) => (rosterMembersRef.current[item] = i)}
              onChange={handleRosterMembersDropdownChange}
            >
              {rosterMembersDropDown}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className='mb-2 col-sm-4 float-right p-0'>
          <FloatingLabel label='Role'>
            <Form.Control className='w-100 pe-0' ref={(i) => (rolesRef.current[item] = i)} readOnly />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className='m-0 col-sm-1 p-0 align-end d-flex flex-column justify-content-center'>
          <RemoveButton id={item} onClick={handleRemove}>
            Remove
          </RemoveButton>
        </Form.Group>
      </Row>
    );
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className='staffing-form'>
        <Row className='w-100 justify-content-between'>
          <Form.Group className='mb-2'>
            <FloatingLabel label='Case code - Case name (Client)' className='mb-3'>
              <Form.Select onClick={handleOnClick} onChange={handleCaseDropdownChange} required={true}>
                {caseDropDown}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Row>
        {Array.from(Array(numberOfRosterField).keys()).map((item) => addRosterMemberField(item))}
        <Row className='w-100'>
          <div className='col'>{formMessage ? <div>{formMessage}</div> : <>&nbsp;</>}</div>
        </Row>
        <Row className='w-100 justify-content-between'>
          <div className='col my-3'>
            <div className='float-left m-0'>
              <AddNewButton text='Add additional member' onClick={handleOnClickAddNewMember} />
            </div>
          </div>
          <div className='col my-3 p-0'>
            <Button data-testid='submit' type='submit' className='float-right m-0'>
              Save
            </Button>
          </div>
        </Row>
      </Form>
      <GeneralDialog
        isOpen={showRemoveDialog}
        title='Warning'
        message='Are you sure you want to remove this member?'
        positiveLabel='Cancel'
        negativeLabel='Remove'
        onClickPositive={handlePositiveClick}
        onClickNegative={handleNegativeClick}
      />
    </div>
  );
};

export default ManualDraftStaffingForm;
