import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllStaffingCapacity } from '../api/staffing';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchStaffingCapacity = createAsyncThunk('staffing/fetchAllStaffingCapacity', async () => {
  return await fetchAllStaffingCapacity();
});

const staffingCapacitySlice = createSlice({
  name: 'staffingCapacity',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStaffingCapacity.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchStaffingCapacity.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchStaffingCapacity.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default staffingCapacitySlice.reducer;

export const selectAllStaffingCapacity = (state) => state.staffingCapacity.data;
export const selectStaffingCapacityStatus = (state) => state.staffingCapacity.status;
export const selectStaffingCapacityError = (state) => state.staffingCapacity.error;
