import { fetchWithCredentials } from './restClient';
import {
  getStaffingByCaseUrl,
  availableCapacityUrl,
  getStaffingRosterMemberCandidatesUrl,
  finalizeStaffingUrl,
  deleteStaffingUrl,
  getCaseAssignStaffingUrl,
} from './urls';
import { postWithCsrf, deleteWithCsrf } from './restClient';

export const fetchAllStaffingByCase = fetchWithCredentials(getStaffingByCaseUrl);
export const fetchAllStaffingCapacity = fetchWithCredentials(availableCapacityUrl);
export const fetchAllStaffingRosterMemberCandidates = fetchWithCredentials(getStaffingRosterMemberCandidatesUrl);
export const updateDraftStaffing = (id) =>
  postWithCsrf(getCaseAssignStaffingUrl(id), {
    'Content-Type': 'application/json',
  });
export const finalizeDraftStaffing = (id) =>
  postWithCsrf(finalizeStaffingUrl(id), {
    'Content-Type': 'application/json',
  });
export const deleteStaffing = (csrf, caseId) =>
  deleteWithCsrf(deleteStaffingUrl(caseId), {
    'Content-Type': 'application/json',
  })(csrf);
