import * as React from 'react';
import { FormField } from '../Forms/FormField';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/authentication';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginForm.module.css';
import { Form } from 'react-bootstrap';
import background from '../../icons/signinBackground.svg';
import PipeLogo from '../../icons/pipeLogo.svg';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastifyTheme } from '../../theme';

export default function LoginForm() {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const email = useSelector((state) => {
    return state.auth?.user?.email;
  });
  const loginError = useSelector((state) => {
    return state.auth?.error;
  });
  const lastLoginFailed = useSelector((state) => {
    return state.auth?.lastLoginFailed;
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    dispatch(login({ username: data.get('username'), password: data.get('password') }));
  };

  useEffect(() => {
    if (email) navigate('/');
  }, [email, navigate]);

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, { ...toastifyTheme, position: 'top-right' });
    }
  }, [lastLoginFailed, loginError]);

  return (
    <div>
      <div className={styles['login-form-wrapper']}>
        <Form className={styles['login-form']} onSubmit={handleSubmit}>
          <div className='col-sm-2'>
            <img src={PipeLogo} />
          </div>
          <span className={styles['header']}>Sign in</span>
          <br />
          <span className={styles['subheader']}>Sign in to stay connected</span>
          <FormField id='username' label='Username' name='username' className={styles['login-form-field']} />
          <FormField
            id='password'
            type='password'
            label='Password'
            name='password'
            className={styles['login-form-field']}
          />
          <Button type='submit' data-testid='submit' className={styles['submit-button']} variant='secondary'>
            Sign in
          </Button>
        </Form>
      </div>
      <div className={styles['login-background']} style={{ backgroundImage: `url(${background})` }}></div>
    </div>
  );
}
