import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllStaffingByCase } from '../api/staffing';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchStaffingByCase = createAsyncThunk('staffing/fetchAllStaffingByCase', async () => {
  return await fetchAllStaffingByCase();
});

const staffingSlice = createSlice({
  name: 'staffing',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStaffingByCase.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchStaffingByCase.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchStaffingByCase.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default staffingSlice.reducer;

export const selectAllStaffing = (state) => state.staffing.data;
export const selectStaffingStatus = (state) => state.staffing.status;
export const selectStaffingError = (state) => state.staffing.error;
export const selectDraftStaffingById = (state, id) =>
  state.staffing.data.find((item) => Number(item.id) === Number(id));
