import React, { useEffect, useState, useCallback } from 'react';
import { fetchTotalRingfenceRoster } from '../../api/dashboard';
import { useSelector } from 'react-redux';
import { reduce } from 'lodash';
import Table from 'react-bootstrap/Table';
import styles from './Dashboard.module.css';

const TotalRingfenceRoster = () => {
  const [data, setData] = useState({});
  const userLevel = useSelector((state) => state.auth?.user?.level);

  const getData = useCallback(async () => {
    const _data = await fetchTotalRingfenceRoster();
    await setData({
      ..._data,
      current_roster_by_role: reduce(
        _data.current_roster_by_role,
        (res, val) => {
          res[val.role] = val;
          return res;
        },
        {}
      ),
      current_roster_by_tenure: reduce(
        _data.current_roster_by_tenure,
        (res, val) => {
          res[val.staffing] = val;
          return res;
        },
        {}
      ),
    });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h3>Total ringfence roster</h3>
      <div className={styles.summary}>
        <div className={`${styles['summary-box']} ${styles.green}`}>
          <p className={styles['summary-box-title']}>Total</p>
          <p className={styles['summary-box-content']}>{data?.total}</p>
        </div>
        <div className={styles['grey-large-text']}>
          {data.total_pl_roster} PLs | {data?.total_ac_roster} A/Cs
        </div>
      </div>
      <div>
        {userLevel !== 3 && (
          <div className={styles['current-roster-table']}>
            <p>Current Roster by Role</p>
            <Table borderless>
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Affiliation</th>
                  <th>Number</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PL</td>
                  <td>
                    RF
                    <br />
                    GP
                  </td>
                  <td>
                    {data?.current_roster_by_role?.PL?.roster_with_affiliation_rf}
                    <br />
                    {data?.current_roster_by_role?.PL?.roster_with_affiliation_gp}
                  </td>
                  <td>{data?.current_roster_by_role?.PL?.total_roster}</td>
                </tr>
                <tr>
                  <td>Anchor</td>
                  <td>
                    RF
                    <br />
                    GP
                  </td>
                  <td>
                    {data?.current_roster_by_role?.Anchor?.roster_with_affiliation_rf}
                    <br />
                    {data?.current_roster_by_role?.Anchor?.roster_with_affiliation_gp}
                  </td>
                  <td>{data?.current_roster_by_role?.Anchor?.total_roster}</td>
                </tr>
                <tr>
                  <td>Utility</td>
                  <td>
                    RF
                    <br />
                    GP
                  </td>
                  <td>
                    {data?.current_roster_by_role?.Utility?.roster_with_affiliation_rf}
                    <br />
                    {data?.current_roster_by_role?.Utility?.roster_with_affiliation_gp}
                  </td>
                  <td>{data?.current_roster_by_role?.Utility?.total_roster}</td>
                </tr>
                <tr>
                  <td>Developing</td>
                  <td>
                    RF
                    <br />
                    GP
                  </td>
                  <td>
                    {data?.current_roster_by_role?.Developing?.roster_with_affiliation_rf}
                    <br />
                    {data?.current_roster_by_role?.Developing?.roster_with_affiliation_gp}
                  </td>
                  <td>{data?.current_roster_by_role?.Developing?.total_roster}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
        <div className={styles['current-roster-table']}>
          <p>Current Roster by Tenure</p>
          <Table borderless>
            <thead>
              <tr>
                <th>Tenure</th>
                <th>Affiliation</th>
                <th>Number</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PL</td>
                <td>
                  RF
                  <br />
                  GP
                </td>
                <td>
                  {data?.current_roster_by_tenure?.PL?.roster_with_affiliation_rf}
                  <br />
                  {data?.current_roster_by_tenure?.PL?.roster_with_affiliation_gp}
                </td>
                <td>{data?.current_roster_by_tenure?.PL?.total_roster}</td>
              </tr>
              <tr>
                <td>APC/C2</td>
                <td>
                  RF
                  <br />
                  GP
                </td>
                <td>
                  {data?.current_roster_by_tenure &&
                    data.current_roster_by_tenure['APC/C2']?.roster_with_affiliation_rf}
                  <br />
                  {data?.current_roster_by_tenure &&
                    data.current_roster_by_tenure['APC/C2']?.roster_with_affiliation_gp}
                </td>
                <td>{data?.current_roster_by_tenure && data.current_roster_by_tenure['APC/C2']?.total_roster}</td>
              </tr>
              <tr>
                <td>A2</td>
                <td>
                  RF
                  <br />
                  GP
                </td>
                <td>
                  {data?.current_roster_by_tenure?.A2?.roster_with_affiliation_rf}
                  <br />
                  {data?.current_roster_by_tenure?.A2?.roster_with_affiliation_gp}
                </td>
                <td>{data?.current_roster_by_tenure?.A2?.total_roster}</td>
              </tr>
              <tr>
                <td>A1/C1</td>
                <td>
                  RF
                  <br />
                  GP
                </td>
                <td>
                  {data?.current_roster_by_tenure && data.current_roster_by_tenure['A1/C1']?.roster_with_affiliation_rf}
                  <br />
                  {data?.current_roster_by_tenure && data.current_roster_by_tenure['A1/C1']?.roster_with_affiliation_gp}
                </td>
                <td>{data?.current_roster_by_tenure && data.current_roster_by_tenure['A1/C1']?.total_roster}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default TotalRingfenceRoster;
