import React from 'react';
import PropTypes from 'prop-types';

const BCGerName = ({ bcger }) => {
  const nameWithCohort = bcger.cohort ? (
    <div>
      {bcger.fullname || bcger.name} ({bcger.cohort})
    </div>
  ) : (
    <div>{bcger.fullname || bcger.name}</div>
  );

  return (
    <div>
      {nameWithCohort}
      <div>{bcger.affiliation}</div>
    </div>
  );
};

BCGerName.propTypes = {
  bcger: PropTypes.object,
};

export default BCGerName;
