import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const GeneralDialog = ({
  isOpen = false,
  title = '',
  message = '',
  positiveLabel = '',
  negativeLabel = '',
  onClickPositive = () => {},
  onClickNegative = () => {},
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClickPositive}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid='dialog-negative-button' color='error' onClick={onClickNegative}>
          {negativeLabel}
        </Button>
        <Button data-testid='dialog-postive-button' onClick={onClickPositive} autoFocus>
          {positiveLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GeneralDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  positiveLabel: PropTypes.string,
  negativeLabel: PropTypes.string,
  onClickPositive: PropTypes.func,
  onClickNegative: PropTypes.func,
};

export default GeneralDialog;
