import React, { useState } from 'react';
import GenericViewLayout from '../GenericHeader';
import AddNewCaseForm from './AddNewCaseForm';
import { CurrentAndUpcomingCasesListView } from './CurrentAndUpcomingCasesListView';
import { CurrentAndUpcomingCasesCalendarView } from './CurrentAndUpcomingCasesCalendarView';
import banner from '../../icons/greenBanner.svg';
import { Button } from '../../theme';
import styles from './CurrentAndUpcomingCases.module.css';

const CurrentAndUpcomingCases = () => {
  const [addFormIsShown, setAddFormIsShown] = useState(false);
  const [listView, setListView] = useState(true);

  function showAddFormHandler() {
    setAddFormIsShown(true);
  }
  function hideAddFormHandler() {
    setAddFormIsShown(false);
  }

  const selectListView = () => {
    setListView(true);
  };

  const selectCalendarView = () => {
    setListView(false);
  };

  return (
    <>
      <GenericViewLayout
        header={'Current and upcoming cases'}
        rightButton={
          <Button variant='light' data-testid='add-new-case' onClick={showAddFormHandler} className='header-button'>
            + Add new case
          </Button>
        }
        banner={banner}
      />
      <div className={styles['view-selector']}>
        <Button outlined onClick={selectListView}>
          List
        </Button>
        <Button outlined onClick={selectCalendarView}>
          Calendar
        </Button>
      </div>
      {listView ? (
        <CurrentAndUpcomingCasesListView showAddCaseForm={addFormIsShown} />
      ) : (
        <CurrentAndUpcomingCasesCalendarView />
      )}
      <AddNewCaseForm show={addFormIsShown} onClose={hideAddFormHandler} />
    </>
  );
};
export default CurrentAndUpcomingCases;
