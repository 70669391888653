import React, { useState } from 'react';
import GenericViewLayout from '../GenericHeader';

import banner from '../../icons/greenBanner.svg';
import * as PropTypes from 'prop-types';
import AddExperienceForm from './AddExperienceForm';
import { ExperienceListView } from './ExperienceListView';
import Button from 'react-bootstrap/Button';

AddExperienceForm.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
};
const Experience = () => {
  const [addFormIsShown, setAddFormIsShown] = useState(false);

  function showAddFormHandler() {
    setAddFormIsShown(true);
  }
  function hideAddFormHandler() {
    setAddFormIsShown(false);
  }
  return (
    <>
      <GenericViewLayout
        header={'Case history'}
        rightButton={
          <Button
            variant='light'
            data-testid='add-new-experience'
            onClick={showAddFormHandler}
            className='header-button'
          >
            + Add new case experience
          </Button>
        }
        banner={banner}
      />
      <ExperienceListView showAddNewForm={addFormIsShown} />
      <AddExperienceForm show={addFormIsShown} onClose={hideAddFormHandler} />
    </>
  );
};
export default Experience;
