const fetchCandidates = async (getCandidateUrl) => {
  const response = await fetch(getCandidateUrl, { credentials: 'include' });
  if (!response.ok) {
    throw new Error('Get candidates failed');
  }
  const responseData = await response.json();
  const candidates = responseData.map((item) => ({
    bcger_id: item.bcger_id,
    fullname: item.fullname,
  }));

  return candidates;
};

export default fetchCandidates;
