import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { usePatchWithCsrf } from '../../hooks/restClient';
import { asSelectOptions } from '../types';
import { Priority, Probability, Complexity } from './Types';
import { getUpdateCaseInfoUrl } from '../../urls';
import { backendErrorParser } from '../backendErrorParser';
import { withNotAvailableOption } from '../OptionSelectUtils';
import { useSelector } from 'react-redux';
import NumberInputField from '../common/NumberInputField/NumberInputField';
import { selectCasePrincipalStatus, selectCasePrincipalData } from '../../store/candidates';
import { current } from '@reduxjs/toolkit';

const EditCaseInfoForm = (props) => {
  const currentCase = props.case;
  var [currentCaseData, setCurrentCaseData] = useState(props.case);
  const patch = usePatchWithCsrf();
  const casePrincipalStatus = useSelector(selectCasePrincipalStatus);
  const casePrincipal = useSelector(selectCasePrincipalData);

  const caseCodeRef = useRef();
  const caseNameRef = useRef();
  const descriptionRef = useRef();
  const clientRef = useRef();
  const budgetRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const principalRef = useRef();
  const xoRef = useRef();
  const mdpsRef = useRef();
  const priorityRef = useRef();
  const complexityRef = useRef();
  const probabilityRef = useRef();

  const populateCaseInfo = useCallback(() => {
    caseCodeRef.current.value = currentCase.case_code;
    caseNameRef.current.value = currentCase.case_name;
    descriptionRef.current.value = currentCase.description;
    clientRef.current.value = currentCase.client;
    budgetRef.current.value = BigInt(currentCase.budget).toLocaleString();
    principalRef.current.value = currentCase.principal;
    xoRef.current.value = currentCase.xo;
    mdpsRef.current.value = currentCase.mdps;
    priorityRef.current.value = currentCase.priority;
    complexityRef.current.value = currentCase.complexity;
    probabilityRef.current.value = currentCase.probability;

    if (currentCase.start_date) {
      startDateRef.current.value = normalizeDateTime(currentCase.start_date);
    }

    if (currentCase.end_date) {
      endDateRef.current.value = normalizeDateTime(currentCase.end_date);
    }
  }, [currentCase]);

  const finishLoadData = casePrincipalStatus === 'succeeded';

  useEffect(() => {
    if (finishLoadData) {
      populateCaseInfo();
    }
  }, [finishLoadData, populateCaseInfo]);

  const casePrincipalDropdown = withNotAvailableOption(
    casePrincipal.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );

  const normalizeDateTime = (date) => {
    if (!date) return date;
    return new Date(`${date} GMT`).toISOString().split('T')[0];
  };

  const normalizeBudget = (amount) => {
    return String(amount).replace(/,/g, '') || 0;
  };

  const checkDataChanged = (o1, o2) => {
    return (
      String(o1.case_code) !== String(o2.case_code) ||
      String(o1.case_name) !== String(o2.case_name) ||
      String(o1.description) !== String(o2.description) ||
      String(o1.client) !== String(o2.client) ||
      normalizeBudget(o1.budget) !== normalizeBudget(o2.budget) ||
      normalizeDateTime(o1.start_date) !== normalizeDateTime(o2.start_date) ||
      normalizeDateTime(o1.end_date) !== normalizeDateTime(o2.end_date) ||
      String(o1.principal) !== String(o2.principal) ||
      String(o1.xo) !== String(o2.xo) ||
      String(o1.mdps) !== String(o2.mdps) ||
      String(o1.priority) !== String(o2.priority) ||
      String(o1.complexity) !== String(o2.complexity) ||
      String(o1.probability) !== String(o2.probability)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const case_code = data.get('caseCode');
    const case_name = data.get('caseName');
    const description = data.get('description');
    const client = data.get('client');
    const budget = normalizeBudget(data.get('budget'));
    const start_date = data.get('startDate') ? data.get('startDate') : null;
    const end_date = data.get('endDate') ? data.get('endDate') : null;
    const principal = data.get('principal');
    const xo = data.get('xo');
    const mdps = data.get('mdps');
    const priority = data.get('priority');
    const complexity = data.get('complexity');
    const probability = data.get('probability');

    const body = {
      case_code,
      case_name,
      description,
      client,
      budget,
      start_date,
      end_date,
      principal,
      xo,
      mdps,
      priority,
      complexity,
      probability,
    };
    if (!checkDataChanged(currentCaseData, body)) {
      props.showFormMessage('');
      return;
    }
    const response = await patch(getUpdateCaseInfoUrl(currentCase.id), {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      setCurrentCaseData(body);
      props.showToastMessage(`Case ${case_name} updated successfully`);
    } else {
      const errorBody = await response.json();
      props.showFormMessage(backendErrorParser(errorBody));
    }
  };

  return (
    <div>
      <h3>Case Info</h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group className='mb-3'>
            <FloatingLabel label='Case Code'>
              <Form.Control data-testid='input-case-code' name='caseCode' ref={caseCodeRef} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3'>
            <FloatingLabel label='Case Name'>
              <Form.Control data-testid='input-case-name' name='caseName' ref={caseNameRef} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3'>
            <FloatingLabel label='Description'>
              <Form.Control data-testid='input-case-description' name='description' ref={descriptionRef} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <FloatingLabel label='Client'>
                <Form.Control data-testid='input-client' name='client' ref={clientRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <NumberInputField label='Budget' id='input-budget' name='budget' refProp={budgetRef} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <FloatingLabel controlId='floatingTextarea' label='Start Date' className='mb-3'>
                <Form.Control data-testid='input-start' name='startDate' type='date' ref={startDateRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <FloatingLabel controlId='floatingTextarea' label='End Date' className='mb-3'>
                <Form.Control data-testid='input-end' name='endDate' type='date' ref={endDateRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Form.Group className='mb-3'>
            <FloatingLabel controlId='floatingTextarea' label='Principal' className='mb-3'>
              <Form.Select data-testid='select-principal' name='principal' ref={principalRef}>
                {casePrincipalDropdown}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3'>
            <FloatingLabel label='XO'>
              <Form.Control data-testid='input-xo' name='xo' ref={xoRef} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3'>
            <FloatingLabel label='MDPs'>
              <Form.Control data-testid='input-mdps' name='mdps' ref={mdpsRef} />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={5}>
            <Form.Group>
              <FloatingLabel label='Priority'>
                <Form.Select data-testid='select-priority' name='priority' ref={priorityRef}>
                  {asSelectOptions(Priority)}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <FloatingLabel label='Probability'>
                <Form.Select data-testid='select-probability' name='probability' ref={probabilityRef}>
                  {asSelectOptions(Probability)}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <FloatingLabel label='Complexity'>
                <Form.Select data-testid='select-complexity' name='complexity' ref={complexityRef}>
                  {asSelectOptions(Complexity)}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <button ref={props.submitRef} type='submit' style={{ display: 'none' }}></button>
      </Form>
    </div>
  );
};

EditCaseInfoForm.propTypes = {
  submitRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  showFormMessage: PropTypes.func,
  showToastMessage: PropTypes.func,
  case: PropTypes.object,
};

export default EditCaseInfoForm;
