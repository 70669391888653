import React from 'react';
import * as PropTypes from 'prop-types';
import StaffListView from './StaffListView/StaffListView';
import GenericHeader from '../GenericHeader';
import banner from '../../icons/greenBanner.svg';

StaffListView.propTypes = {
  onClick: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.any,
};
export default function Staff() {
  return (
    <>
      <div style={{ height: 400, width: '100%' }}>
        <GenericHeader header={'All Staff'} banner={banner}></GenericHeader>
        <StaffListView />
      </div>
    </>
  );
}
