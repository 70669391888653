import React, { useState } from 'react';
import GenericViewLayout from '../GenericHeader';
import banner from '../../icons/greenBanner.svg';
import { PastCasesListView } from './PastCasesListView';

const PastCases = () => {
  return (
    <>
      <GenericViewLayout header={'Past cases'} banner={banner} />
      <div>
        <PastCasesListView />
      </div>
    </>
  );
};
export default PastCases;
