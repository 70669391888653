import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchStaffingByCase,
  selectAllStaffing,
  selectStaffingStatus,
  selectStaffingError,
  selectDraftStaffingById,
} from '../../store/staffing';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import { TimelineField } from '../common/TimelineField/TimelineField';
import StaffingByCaseForm from './StaffingByCaseForm';
import styles from './Staffing.module.css';

export function StaffingByCaseListView() {
  const staffingData = useSelector(selectAllStaffing);
  const status = useSelector(selectStaffingStatus);
  const error = useSelector(selectStaffingError);
  const dispatch = useDispatch();
  const [showEditCaseForm, setShowEditCaseForm] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState();
  const selectedCase = useSelector((state) => selectDraftStaffingById(state, selectedCaseId));
  let content;

  useEffect(() => {
    dispatch(fetchStaffingByCase());
  }, []);

  useEffect(() => {
    if (showEditCaseForm) return;
    dispatch(fetchStaffingByCase());
  }, [showEditCaseForm]);

  const handleClick = useCallback((event) => {
    setSelectedCaseId(event.currentTarget.id);
    setShowEditCaseForm(true);
  }, []);

  function hideEditFormHandler() {
    setShowEditCaseForm(false);
  }

  const tableListView = (cases) =>
    cases.map((item) => (
      <tr key={item.id} id={item.id} onClick={handleClick} data-testid={`row-${item.id}`}>
        <td>{item.case_code}</td>
        <td>{item.case_name}</td>
        <td>{item.principal}</td>
        <td>
          <span className='secondary-text'>Start: </span> {TimelineField(item.start_date)} <br />
          <span className='secondary-text'>End: </span> {item.end_date}
        </td>
        <td>{item.priority}</td>
        <td data-testid={`complexity-cell-${item.id}`}>{item.complexity}</td>
        <td>
          {item.all_staffing['PL']?.map((plItem) => (
            <div key={plItem.fullname}>{plItem.fullname}</div>
          ))}
        </td>
        <td>
          {item.all_staffing['Anchor']?.map((plItem) => (
            <div key={plItem.fullname}>{plItem.fullname}</div>
          ))}
        </td>
        <td>
          {item.all_staffing['Utility']?.map((plItem) => (
            <div key={plItem.fullname}>{plItem.fullname}</div>
          ))}
        </td>
        <td>
          {item.all_staffing['Developing']?.map((plItem) => (
            <div key={plItem.fullname}>{plItem.fullname}</div>
          ))}
        </td>
        <td>{item.budget.toLocaleString()}</td>
        <td>{item.xo}</td>
        <td>{item.mdps}</td>
        <td>{item.probability}</td>
        <td>{item.client}</td>
      </tr>
    ));

  switch (status) {
    case 'succeeded':
    case 'loading':
      content = tableListView(staffingData);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={2}>
            <ErrorWithRetry
              text={error}
              onRetry={(e) => {
                dispatch(fetchStaff());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table case-by-case-table'>
          <thead>
            <tr>
              <th>Case code</th>
              <th>Case Name</th>
              <th>Principal</th>
              <th>Timeline</th>
              <th>Priority</th>
              <th>Complexity</th>
              <th>PL</th>
              <th>Anchor</th>
              <th>Ultility</th>
              <th>Developing</th>
              <th>Budget</th>
              <th>XO</th>
              <th>MDPs</th>
              <th>Probability</th>
              <th>Client</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
      </div>
      {status === 'loading' && (
        <div className='table-loading-container'>
          <LoadingSpinner />
        </div>
      )}
      {showEditCaseForm && (
        <StaffingByCaseForm case={selectedCase} show={showEditCaseForm} handleClose={hideEditFormHandler} />
      )}
    </>
  );
}
