import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchMe = createAsyncThunk('authentication/fetchMe', async (_, thunkAPI) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/auth/me/`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  thunkAPI.dispatch(getCsrf());
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Fetch me failed');
  }
});

export const getCsrf = createAsyncThunk('authentication/getCsrf', async (thunkAPI) => {
  const csrfResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/csrf/`, {
    credentials: 'include',
  });
  return csrfResponse.headers.get('X-CSRFToken');
});

export const login = createAsyncThunk(
  'authentication/login',
  async ({ username, password }, { rejectWithValue, dispatch, getState }) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/login/`;

    const { csrf } = getState().auth;

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf,
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      dispatch(getCsrf());
      return await response.json();
    } else {
      throw rejectWithValue('Login failed');
    }
  }
);

export const logout = createAsyncThunk('authentication/logout', async (args, { getState, rejectWithValue }) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/auth/logout/`;
  const { csrf } = getState().auth;
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrf,
    },
    body: JSON.stringify({}),
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw rejectWithValue('Logout failed');
  }
});

const initialState = {
  csrf: (() => {
    try {
      return JSON.parse(localStorage.getItem('csrf'));
    } catch (err) {
      return null;
    }
  })(),
  user: (() => {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch (err) {
      return {};
    }
  })(),
};

const loggedInUserSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      return { ...state, user: action.payload };
    });
    builder.addCase(fetchMe.rejected, (state, action) => {
      localStorage.removeItem('user');
      return { ...state, user: {} };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      return { ...state, user: action.payload, error: '' };
    });
    builder.addCase(login.rejected, (state, action) => {
      localStorage.removeItem('user');
      return { ...state, user: {}, error: action.payload, lastLoginFailed: new Date().getMilliseconds() };
    });
    builder.addCase(getCsrf.fulfilled, (state, action) => {
      localStorage.setItem('csrf', JSON.stringify(action.payload));
      return { ...state, csrf: action.payload };
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      localStorage.removeItem('user');
      localStorage.removeItem('csrf');
      return { ...state, user: {}, csrf: null, error: '' };
    });
    builder.addCase(logout.rejected, (state, action) => {
      return { ...state, error: action.payload };
    });
  },
});

export default loggedInUserSlice.reducer;

export const selectUserLevel = (state) => state.auth?.user?.level;
