import './App.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { fetchMe } from './store/authentication';

import TermsOfUse from './components/TermsOfUse';
import LoginForm from './components/Login/LoginForm';
import Staff from './components/Staff/Staff';
import Import from './components/Import/Import';
import CurrentRoster from './components/Roster/CurrentRoster';
import PotentialPipeline from './components/Roster/PotentialPipeline';
import CurrentAndUpcomingCases from './components/Case/CurrentAndUpcomingCases';
import PastCases from './components/Case/PastCases';
import Experience from './components/Experience/Experience';
import ACDevelopment from './components/Development/ACDevelopment';
import PLDevelopment from './components/Development/PLDevelopment';
import Outage from './components/Outage/Outage';
import Dashboard from './components/Dashboard/Dashboard';
import LoginRequiredPage, { LogoutRequiredPage, Level1RequiredPage } from './components/ProtectedPage';
import Alumni from './components/Roster/Alumni';
import StaffingByCase from './components/Staffing/StaffingByCase';
import Optimization from './components/Staffing/Optimization';
import { ToastContainer } from 'react-toastify';

function App() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <LoginRequiredPage>
              <Dashboard />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/login'
          element={
            <LogoutRequiredPage>
              <LoginForm />
            </LogoutRequiredPage>
          }
        ></Route>
        <Route path='/tos' element={<TermsOfUse />}></Route>
        <Route
          path='/staff'
          element={
            <LoginRequiredPage>
              <Staff />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/import'
          element={
            <LoginRequiredPage>
              <Import />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/roster'
          element={
            <LoginRequiredPage>
              <CurrentRoster />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/current-roster'
          element={
            <LoginRequiredPage>
              <CurrentRoster />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/pipeline'
          element={
            <LoginRequiredPage>
              <PotentialPipeline />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/alumni'
          element={
            <LoginRequiredPage>
              <Alumni />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/optimization'
          element={
            <LoginRequiredPage>
              <Optimization />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/staffing-by-case'
          element={
            <LoginRequiredPage>
              <StaffingByCase />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/current-upcoming-cases'
          element={
            <LoginRequiredPage>
              <CurrentAndUpcomingCases />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/past-cases'
          element={
            <LoginRequiredPage>
              <PastCases />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/experience'
          element={
            <LoginRequiredPage>
              <Experience />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/ac-development'
          element={
            <LoginRequiredPage>
              <ACDevelopment />
            </LoginRequiredPage>
          }
        ></Route>
        <Route
          path='/pl-development'
          element={
            <Level1RequiredPage>
              <PLDevelopment />
            </Level1RequiredPage>
          }
        ></Route>
        <Route
          path='/outage'
          element={
            <LoginRequiredPage>
              <Outage />
            </LoginRequiredPage>
          }
        ></Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
