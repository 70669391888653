import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import AlumniForm from './AlumniForm';
import { useSelector, useDispatch } from 'react-redux';
import Ethnic from '../../icons/Ethnic.svg';
import EditButton from '../common/EditButton/EditButton';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import {
  fetchBuddy,
  fetchCaseCandidates,
  fetchMentor,
  selectBuddyStatus,
  selectCaseCandidatesStatus,
  selectMentorStatus,
} from '../../store/candidates';
import { fetchStaff, selectStaffStatus } from '../../store/staff';
import { fetchAlumni, selectAlumni, selectAlumniById } from '../../store/alumni';
import { selectUserLevel } from '../../store/authentication';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import BCGerName from '../common/BCGerName/BCGerName';

export function AlumniListView() {
  let content;
  const dispatch = useDispatch();
  const staffStatus = useSelector(selectStaffStatus);
  const buddyStatus = useSelector(selectBuddyStatus);
  const mentorStatus = useSelector(selectMentorStatus);
  const caseStatus = useSelector(selectCaseCandidatesStatus);
  const userLevel = useSelector(selectUserLevel);
  const alumni = useSelector(selectAlumni);
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const selectedBcger = useSelector((state) => selectAlumniById(state, selectedId));

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    setSelectedId(Number(e.target.id));
    setShowForm(true);
  }, []);

  useEffect(() => {
    if (showForm) return;
    if (staffStatus === 'idle') {
      dispatch(fetchStaff());
    }
    if (buddyStatus === 'idle') {
      dispatch(fetchBuddy());
    }
    if (mentorStatus === 'idle') {
      dispatch(fetchMentor());
    }
    if (caseStatus === 'idle') {
      dispatch(fetchCaseCandidates());
    }
    dispatch(fetchAlumni());
  }, [showForm]);

  const tableListView = (data) => {
    return data.map((item) => (
      <tr key={item.bcger_id} data-testid={`row-${item.bcger_id}`}>
        <td>
          <EditButton id={item.bcger_id} onClick={handleEdit} />
        </td>
        <td>
          <div className='fullname-col'>
            <BCGerName bcger={item} />
          </div>
        </td>
        {userLevel !== 3 && (
          <td>
            <div className={`role-badge ${item.role}`}>{item.role}</div>
          </td>
        )}
        <td>
          Start: {item.rf_start} <br />
          End: {item.rf_end}
        </td>
        <td>{item.staffing}</td>
        <td data-testid={`tenure-cell-${item.bcger_id}`}>{item.tenure}</td>
        {userLevel !== 3 && <td>{item.score}</td>}
        {userLevel !== 3 && <td>{item.off_axis}</td>}
        <td>
          {item.case_code}
          <br />
          {item.case_name}
        </td>
        <td>{item.gender}</td>
        <td>{item.ethnic === 'Y' && <img src={Ethnic} />}</td>
        <td>{item.buddy_name}</td>
        <td>{item.mentor_name}</td>
      </tr>
    ));
  };

  switch (alumni.status) {
    case 'succeeded':
    case 'loading':
      content = tableListView(alumni.data);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={7}>
            <ErrorWithRetry
              text={alumni.error}
              onRetry={(_) => {
                dispatch(fetchAlumni());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table'>
          <thead>
            <tr>
              <th styles={{ width: 'auto' }}></th>
              <th>Name</th>
              {userLevel !== 3 && <th>Role</th>}
              <th>RF Timeline</th>
              <th>Staffing</th>
              <th>Tenure</th>
              {userLevel !== 3 && <th>Score</th>}
              {userLevel !== 3 && <th>Off-axis</th>}
              <th>Case</th>
              <th>Gender</th>
              <th>DEI</th>
              <th>Buddy</th>
              <th>Mentor</th>
            </tr>
          </thead>
          <tbody data-testid='roster-table-body'>{content}</tbody>
        </Table>
      </div>
      {alumni.status === 'loading' && (
        <div className='table-loading-container'>
          <LoadingSpinner />
        </div>
      )}
      {selectedBcger && <AlumniForm alumni={selectedBcger} showForm={showForm} setShowForm={setShowForm} />}
    </>
  );
}
