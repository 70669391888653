import React from 'react';
import { Row } from 'react-bootstrap';
import GenericViewLayout from '../GenericHeader';
import TotalRingfenceRoster from './TotalRingfenceRoster';
import CasesStartingNextWeek from './CasesStartingNextWeek';
import StaffedOnActiveCases from './StaffedOnActiveCases';
import AvailableCapacity from './AvailableCapacity';
import banner from '../../icons/greenBanner.svg';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  return (
    <>
      <GenericViewLayout header={'Summary of current capacity'} banner={banner} />
      <div className={styles.dbcontainer}>
        <Row className='mb-3 flex-nowrap'>
          <div className='col-md-9'>
            <div className={styles['dashboard-panel']}>
              <AvailableCapacity />
            </div>
          </div>
          <div className='col-md-3'>
            <div className={styles['dashboard-panel']}>
              <CasesStartingNextWeek />
            </div>
          </div>
        </Row>
        <Row className='mb-3'>
          <div className='col-md-6'>
            <div className={styles['dashboard-panel']}>
              <TotalRingfenceRoster />
            </div>
          </div>
          <div className='col-md-6'>
            <div className={styles['dashboard-panel']}>
              <StaffedOnActiveCases />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};
export default Dashboard;
