import React from 'react';
import MonthView from 'react-big-calendar/lib/Month';
import { chunk, filter } from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

class CustomMonthView extends MonthView {
  render() {
    let { date, localizer, className } = this.props,
      month = filter(localizer.visibleDays(date, localizer), (ele) => ![0, 6].includes(new Date(ele).getDay())),
      weeks = chunk(month, 5);
    if (weeks[0] && new Date(weeks[0].at(-1)) < moment(date).startOf('month').toDate()) weeks.shift();
    if (weeks.at(-1) && new Date(weeks.at(-1)[0]) > moment(date).endOf('month').toDate()) weeks.pop();
    this._weekCount = weeks.length;

    return (
      <div className={clsx('rbc-month-view', className)} role='table' aria-label='Month View' ref={this.containerRef}>
        <div className='rbc-row rbc-month-header' role='row'>
          {this.renderHeaders(weeks[0])}
        </div>
        {weeks.map(this.renderWeek)}
        {this.props.popup && this.renderOverlay()}
      </div>
    );
  }
}

export default CustomMonthView;
