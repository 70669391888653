import React from 'react';
import { Accordion } from 'react-bootstrap';
import RosterIconWhite from './icons/roster-white.svg';
import CurrentCasesIcon from './icons/currentcase.svg';
import PastCasesIcon from './icons/pastcase.svg';
import DashboardIcon from './icons/dashboard.svg';
import StaffingIcon from './icons/staffing.svg';
import OptimizationIcon from './icons/optimization.svg';
import ACPLIconWhite from './icons/acpl-white.svg';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserLevel } from './store/authentication';

export default function SideBar() {
  const userLevel = useSelector(selectUserLevel);
  return (
    <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
      <h4>Home</h4>
      <NavLink className='nav-link' to='/'>
        <img src={DashboardIcon} className='link-icon' />
        Dashboard
      </NavLink>
      <hr />
      <h4>Staff</h4>
      <Accordion.Item eventKey='0'>
        <Accordion.Header className='always-open-accordion-header'>
          <div>
            <img src={RosterIconWhite} className='link-icon icon' />
          </div>
          <div data-testid='roster'>Ringfence roster</div>
        </Accordion.Header>
        <Accordion.Body>
          <NavLink className='nav-link' to='/roster' data-testid='current-roster'>
            Current roster
          </NavLink>
          <NavLink className='nav-link' to='/pipeline' data-testid='pipeline'>
            Potential pipeline
          </NavLink>
          <NavLink className='nav-link' to='/alumni'>
            Alumni
          </NavLink>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <Accordion.Header className='always-open-accordion-header'>
          <div data-testid='acpl'>
            <img src={ACPLIconWhite} className='link-icon icon' />
            A/C/PL
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <NavLink className='nav-link' to='/experience' data-testid='experience'>
            Case history
          </NavLink>
          {userLevel !== 3 && (
            <NavLink className='nav-link' to='/ac-development' data-testid='ac-development'>
              AC Development
            </NavLink>
          )}
          {userLevel === 1 && (
            <NavLink className='nav-link' to='/pl-development' data-testid='pl-development'>
              PL Development
            </NavLink>
          )}
          <NavLink className='nav-link' to='/outage' data-testid='ac-development'>
            Outage
          </NavLink>
        </Accordion.Body>
        {userLevel === 1 && (
          <>
            <hr />
            <h4>Staffing</h4>
            <NavLink className='nav-link' to='/optimization' data-testid='optimization'>
              <img src={OptimizationIcon} className='link-icon' />
              Optimization
            </NavLink>
            <NavLink className='nav-link' to='/staffing-by-case' data-testid='staffing-by-case'>
              <img src={StaffingIcon} className='link-icon' />
              Draft staffing assignments
            </NavLink>
          </>
        )}
      </Accordion.Item>
      <hr />
      <h4>Cases</h4>
      <NavLink className='nav-link' to='/current-upcoming-cases' data-testid='current-upcoming-cases'>
        <img src={CurrentCasesIcon} className='link-icon' />
        Current & Upcoming
      </NavLink>
      <NavLink className='nav-link' to='/past-cases' data-testid='past-cases'>
        <img src={PastCasesIcon} className='link-icon' />
        Past
      </NavLink>
    </Accordion>
  );
}
