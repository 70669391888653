import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Offcanvas, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { backendErrorParser } from '../backendErrorParser';
import { useSelector } from 'react-redux';
import { Button, toastifyTheme } from '../../theme';
import RemoveButton from '../common/RemoveButton/RemoveButton';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog';
import { finalizeDraftStaffing, deleteStaffing } from '../../api/staffing';
import styles from './Staffing.module.css';
import { toast } from 'react-toastify';

const StaffingByCaseForm = (props) => {
  const [formMessage, setFormMessage] = useState();
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const caseCodeRef = useRef();
  const caseNameRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const principalRef = useRef();
  const priorityRef = useRef();
  const csrf = useSelector((state) => state.auth?.csrf);

  const populateCaseInfo = useCallback(() => {
    caseCodeRef.current.value = props.case.case_code;
    caseNameRef.current.value = props.case.case_name;
    principalRef.current.value = props.case.principal || '';
    priorityRef.current.value = props.case.priority;

    if (props.case.start_date) {
      startDateRef.current.value = normalizeDateTime(props.case.start_date);
    }

    if (props.case.end_date) {
      endDateRef.current.value = normalizeDateTime(props.case.end_date);
    }
  }, [props.case]);

  useEffect(() => {
    populateCaseInfo();
  }, [populateCaseInfo]);

  const normalizeDateTime = (date) => {
    if (!date) return date;
    return new Date(`${date} GMT`).toISOString().split('T')[0];
  };

  const handleClose = (e) => {
    e.preventDefault();
    props.handleClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const staffingId = props.case.id;
    const res = await finalizeDraftStaffing(staffingId)(csrf);

    if (res.success) {
      props.handleClose();
      toast.success('Assigned successfully', toastifyTheme);
    } else {
      const errorBody = await response.json();
      setFormMessage(backendErrorParser(errorBody));
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    setShowRemoveDialog(true);
  };

  const handlePostiveClick = (e) => {
    e.preventDefault();
    setShowRemoveDialog(false);
  };

  const handleNegativeClick = async (e) => {
    e.preventDefault();
    setShowRemoveDialog(false);
    const response = await deleteStaffing(csrf, props.case.id);
    if (response.success) {
      toast.success('Draft staffing removed.', toastifyTheme);
      props.handleClose();
    } else {
      const errorBody = data;
      setFormMessage(`Draft staffing removal failed: ${backendErrorParser(errorBody)}`);
    }
  };

  return (
    <Offcanvas show={props.show} onHide={props.handleClose} placement='end'>
      <Offcanvas.Header closeButton>
        <h3>Edit draft staffing assignments</h3>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Group className='mb-3'>
                <FloatingLabel label='Case Code'>
                  <Form.Control data-testid='case-code' name='caseCode' ref={caseCodeRef} readOnly={true} />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className='mb-3'>
                <FloatingLabel label='Case Name'>
                  <Form.Control data-testid='case-name' name='caseName' ref={caseNameRef} readOnly={true} />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='Start Date' className='mb-3'>
                    <Form.Control data-testid='start' name='startDate' type='date' ref={startDateRef} readOnly={true} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='End Date' className='mb-3'>
                    <Form.Control data-testid='end' name='endDate' type='date' ref={endDateRef} readOnly={true} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className='mb-3'>
                <FloatingLabel controlId='floatingTextarea' label='Principal' className='mb-3'>
                  <Form.Control data-testid='principal' name='principal' ref={principalRef} readOnly={true} />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <FloatingLabel label='Priority'>
                    <Form.Control data-testid='priority' name='priority' ref={priorityRef} readOnly={true} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <h3>Staffing</h3>
            <br />
            <Row>
              <div className={styles['role-label']}>PL</div>
              {props.case.all_staffing?.PL ? (
                props.case.all_staffing?.PL.map((item) => <div key={item.fullname}>{item.fullname}</div>)
              ) : (
                <div>{'--'}</div>
              )}
              <br />
              <br />
            </Row>
            <Row>
              <div className={styles['role-label']}>Anchor</div>
              {props.case.all_staffing?.Anchor ? (
                props.case.all_staffing?.Anchor.map((item) => <div key={item.fullname}>{item.fullname}</div>)
              ) : (
                <div>{'--'}</div>
              )}
              <br />
              <br />
            </Row>
            <Row>
              <div className={styles['role-label']}>Utility</div>
              {props.case.all_staffing?.Utility ? (
                props.case.all_staffing?.Utility.map((item) => <div key={item.fullname}>{item.fullname}</div>)
              ) : (
                <div>{'--'}</div>
              )}
              <br />
              <br />
            </Row>
            <Row>
              <div className={styles['role-label']}>Developing</div>
              {props.case.all_staffing?.Developing ? (
                props.case.all_staffing?.Developing.map((item) => <div key={item.fullname}>{item.fullname}</div>)
              ) : (
                <div>{'--'}</div>
              )}
              <br />
              <br />
            </Row>
            <button ref={props.submitRef} type='submit' style={{ display: 'none' }}></button>
          </Form>
        </div>
        <Form>
          <div className='form-footer'>
            {formMessage && <div className='form-footer-message'>{formMessage}</div>}
            <div className='form-footer-btn-group'>
              <div>
                <RemoveButton id={Number(props.case.id)} onClick={handleRemove}>
                  Remove
                </RemoveButton>
              </div>
              <div className='form-footer-btn-group-right'>
                <Button data-testid='edit-draft-staffing-cancel-btn' outlined onClick={handleClose}>
                  Cancel
                </Button>
                <Button data-testid='edit-draft-staffing-submit-btn' type='submit' onClick={handleSubmit}>
                  Finalize
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Offcanvas.Body>
      <GeneralDialog
        isOpen={showRemoveDialog}
        title='Warning'
        message='Are you sure you want to remove this record?'
        positiveLabel='Cancel'
        negativeLabel='Remove'
        onClickPositive={handlePostiveClick}
        onClickNegative={handleNegativeClick}
      />
    </Offcanvas>
  );
};

StaffingByCaseForm.propTypes = {
  submitRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  case: PropTypes.object,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
};

export default StaffingByCaseForm;
