import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Button, toastifyTheme } from '../../theme';
import { editAlumni } from '../../api/roster';
import { FormField } from '../Forms/FormField';
import { FormDropdown } from '../Forms/FormDropdown';
import { Ethnic, Gender, OffAxis, Role, Score, Staffing, Affiliation } from './Types';
import { find, map } from 'lodash';
import { removeAlumni } from '../../api/roster';
import RemoveButton from '../common/RemoveButton/RemoveButton';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog';
import { Level3RestrictedComponent, Level2And3RestrictedComponent } from '../Forms/RestrictedComponent';
import { selectUserLevel } from '../../store/authentication';
import {
  selectBuddyData,
  selectBuddyStatus,
  selectCaseCandidatesData,
  selectCaseCandidatesStatus,
  selectMentorData,
  selectMentorStatus,
} from '../../store/candidates';
import { toast } from 'react-toastify';
import { backendErrorParser } from '../backendErrorParser';

const AlumniForm = ({ alumni = {}, showForm, setShowForm }) => {
  const userLevel = useSelector(selectUserLevel);
  const buddyData = useSelector(selectBuddyData);
  const mentorData = useSelector(selectMentorData);
  const casedData = useSelector(selectCaseCandidatesData);
  const buddyStatus = useSelector(selectBuddyStatus);
  const mentorStatus = useSelector(selectMentorStatus);
  const caseStatus = useSelector(selectCaseCandidatesStatus);

  const csrf = useSelector((state) => state.auth?.csrf);
  const [caseDropdown, setCaseDropdown] = useState([]);
  const [formMessage, setFormMessage] = useState(null);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [formData, setFormData] = useState({
    affiliation: '',
    cohort: '',
    ethnic: '',
    gender: '',
    buddy_id: '',
    mentor_id: '',
    off_axis: '',
    preferences: '',
    notes: '',
    rf_end: '',
    rf_start: '',
    role: '',
    score: '',
    staffing: '',
    active_case_id: '',
    tenure: '',
  });

  const formatDate = (date) => date && new Date(`${date} GMT`).toISOString().split('T')[0];

  const finishLoadData =
    alumni && buddyStatus === 'succeeded' && mentorStatus === 'succeeded' && caseStatus === 'succeeded';

  useEffect(() => {
    if (!finishLoadData) return;

    normalizeCaseDropdown();
    setFormData({
      ...alumni,
      rf_start: formatDate(alumni?.rf_start) || '',
      rf_end: formatDate(alumni?.rf_end) || '',
    });
    setFormMessage(null);
  }, [showForm, finishLoadData]);

  const normalizeCaseDropdown = () => {
    let currentCases = [...casedData];
    alumni.case_code !== undefined &&
      find(currentCases, { case_code: alumni.case_code }) === undefined &&
      currentCases.push({ id: alumni.active_case_id, case_code: alumni.case_code, case_name: alumni.case_name });
    setCaseDropdown(
      map(currentCases, (ele) => ({ ...ele, case_code_and_name: `${ele.case_code} - ${ele.case_name}` }))
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const bodyData = {
      ...formData,
      rf_start: formData.rf_start ? formData.rf_start : null,
      rf_end: formData.rf_end ? formData.rf_end : null,
    };
    const res = await editAlumni(alumni.bcger_id)(csrf, {
      body: JSON.stringify(bodyData),
    });
    if (res.success) {
      setShowForm(false);
      toast.success('Your changes were saved successfully!', toastifyTheme);
    } else {
      setFormMessage(backendErrorParser(res.data));
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleRemove = (e) => {
    e.preventDefault();
    setShowRemoveDialog(true);
  };

  const handlePostiveClick = () => {
    setShowRemoveDialog(false);
  };

  const handleNegativeClick = async () => {
    setShowRemoveDialog(false);
    const res = await removeAlumni(alumni.bcger_id)(csrf);
    if (res.success) {
      setShowForm(false);
      toast.success(`Alumni ${alumni.fullname} was removed successfully!`, toastifyTheme);
    } else {
      setFormMessage(res.data[Object.keys(res.data)[0]]);
    }
  };

  const onHideHandler = () => {
    setShowForm(false);
  };

  return (
    <>
      <Offcanvas show={showForm} onHide={onHideHandler} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Edit Alumni</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <FormField className='mb-3' name='name' value={alumni.fullname} disabled={true} label='Name' />
              </Row>
              <Row>
                <FormDropdown
                  classes='mb-3 col-6'
                  name='affiliation'
                  label='Affiliation'
                  dropdownList={Affiliation}
                  valueKey='key'
                  labelKey='viewableText'
                  onChange={handleChange}
                  value={formData.affiliation}
                />
                <FormField
                  className='mb-3 col-6'
                  name='preferences'
                  value={formData.preferences}
                  label='Preferences'
                  onChange={handleChange}
                  helpText='IPA, FPA, etc.'
                />
              </Row>
              <Row>
                <FormField
                  className='mb-3'
                  name='notes'
                  value={formData.notes}
                  label='Notes'
                  onChange={handleChange}
                  helpText=''
                />
              </Row>
              <Row>
                <FormField
                  className='mb-3'
                  name='tenure'
                  value={formData.tenure}
                  label='Tenure'
                  onChange={handleChange}
                  type='number'
                  otherProps={{
                    min: 0,
                    max: 99,
                  }}
                />
              </Row>
              <Row>
                <FormField
                  className='mb-3 col-6'
                  name='rf_start'
                  value={formData.rf_start}
                  label='RF start'
                  onChange={handleChange}
                  type='date'
                />
                <FormField
                  className='mb-3 col-6'
                  name='rf_end'
                  value={formData.rf_end}
                  label='RF end'
                  onChange={handleChange}
                  type='date'
                />
              </Row>
              <Row>
                <FormDropdown
                  name='staffing'
                  label='Staffing'
                  dropdownList={Staffing}
                  valueKey='key'
                  labelKey='viewableText'
                  onChange={handleChange}
                  value={formData.staffing.toString()}
                />
                <Level3RestrictedComponent userLevel={userLevel}>
                  <FormDropdown
                    name='role'
                    label='Role'
                    dropdownList={Role}
                    valueKey='key'
                    labelKey='viewableText'
                    onChange={handleChange}
                    value={formData.role}
                  />
                </Level3RestrictedComponent>
              </Row>
              <Row>
                <Level2And3RestrictedComponent
                  userLevel={userLevel}
                  isRolePLSelected={alumni.role === 'PL' || formData?.role === 'PL' || false}
                >
                  <>
                    <FormDropdown
                      name='score'
                      label='Score'
                      dropdownList={Score}
                      valueKey='key'
                      labelKey='viewableText'
                      onChange={handleChange}
                      value={formData.score?.toString() || ''}
                    />
                    <FormDropdown
                      name='off_axis'
                      label='Off-axis'
                      dropdownList={OffAxis}
                      valueKey='key'
                      labelKey='viewableText'
                      onChange={handleChange}
                      value={formData.off_axis || ''}
                    />
                  </>
                </Level2And3RestrictedComponent>
                <FormDropdown
                  name='gender'
                  label='Gender'
                  dropdownList={Gender}
                  valueKey='key'
                  labelKey='viewableText'
                  onChange={handleChange}
                  value={formData.gender}
                />
                <FormDropdown
                  name='ethnic'
                  label='DEI'
                  dropdownList={Ethnic}
                  valueKey='key'
                  labelKey='viewableText'
                  onChange={handleChange}
                  value={formData.ethnic}
                />
              </Row>
              <Row>
                <FormDropdown
                  name='buddy_id'
                  label='Buddy'
                  onChange={handleChange}
                  dropdownList={buddyData}
                  value={String(formData.buddy_id || '')}
                />
                <FormDropdown
                  name='mentor_id'
                  label='Mentor'
                  onChange={handleChange}
                  dropdownList={mentorData}
                  value={String(formData.mentor_id || '')}
                />
              </Row>
              <Row>
                <FormDropdown
                  name='active_case_id'
                  label='Case'
                  onChange={handleChange}
                  dropdownList={caseDropdown}
                  valueKey='id'
                  labelKey='case_code_and_name'
                  value={String(formData.active_case_id || '')}
                />
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              {finishLoadData && (
                <div className='form-footer-btn-group'>
                  <div>
                    <RemoveButton id={alumni.bcger_id} onClick={handleRemove} />
                  </div>
                  <div className='form-footer-btn-group-right'>
                    <Button data-testid='cancel' outlined onClick={onHideHandler}>
                      Cancel
                    </Button>
                    <Button data-testid='submit' type='submit'>
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <GeneralDialog
        isOpen={showRemoveDialog}
        title='Warning'
        message='Are you sure you want to remove this record?'
        positiveLabel='Cancel'
        negativeLabel='Remove'
        onClickPositive={handlePostiveClick}
        onClickNegative={handleNegativeClick}
      />
    </>
  );
};
AlumniForm.propTypes = {
  alumni: PropTypes.object,
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
};
export default AlumniForm;
