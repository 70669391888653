import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllStaffingRosterMemberCandidates } from '../api/staffing';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchStaffingRosterMemberCandidates = createAsyncThunk(
  'staffingRosterMemberCandidates/fetchAllStaffingRosterMemberCandidates',
  async () => {
    return await fetchAllStaffingRosterMemberCandidates();
  }
);

const staffingRosterMemberCandidatesSlice = createSlice({
  name: 'staffingRosterMemberCandidates',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStaffingRosterMemberCandidates.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchStaffingRosterMemberCandidates.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchStaffingRosterMemberCandidates.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default staffingRosterMemberCandidatesSlice.reducer;

export const selectAllStaffingRosterMemberCandidates = (state) => state.staffingRosterMemberCandidates.data;
export const selectStaffingRosterMemberCandidatesStatus = (state) => state.staffingRosterMemberCandidates.status;
export const selectStaffingRosterMemberCandidatesError = (state) => state.staffingRosterMemberCandidates.error;
