import { fetchWithCredentials, patchWithCsrf, postWithCsrf } from './restClient';
import { acDevelopmentUrl, plDevelopmentUrl, reviewUrl } from './urls';

export const getACDevelopment = fetchWithCredentials(acDevelopmentUrl);

export const getPLDevelopment = fetchWithCredentials(plDevelopmentUrl);

export const editDevelopment = (id) =>
  patchWithCsrf(`${reviewUrl}${id}/`, {
    'Content-Type': 'application/json',
  });

export const createReview = postWithCsrf(reviewUrl, { 'Content-Type': 'application/json' });
