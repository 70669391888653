import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Col, FloatingLabel, Form, Row, Offcanvas } from 'react-bootstrap';
import { Button, toastifyTheme } from '../../theme';
import PropTypes from 'prop-types';
import { Affiliation, Ethnic, Gender, OffAxis, Role, Score, Staffing, defaultStaffing } from './Types';
import { usePatchWithCsrf } from '../../hooks/restClient';
import { getEditRosterMemberUrl } from '../../urls';
import { withNotAvailableOption } from '../OptionSelectUtils';
import { asSelectOptions } from '../types';
import { backendErrorParser } from '../backendErrorParser';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import RemoveButton from '../common/RemoveButton/RemoveButton';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog';
import { Level3RestrictedComponent, Level2And3RestrictedComponent } from '../Forms/RestrictedComponent';
import {
  selectBuddyData,
  selectBuddyStatus,
  selectCaseCandidatesData,
  selectCaseCandidatesStatus,
  selectMentorData,
  selectMentorStatus,
} from '../../store/candidates';
import { selectUserLevel } from '../../store/authentication';
import { toast } from 'react-toastify';

const EditRosterMemberForm = (props) => {
  const currentRoster = props.selectedBcger;
  const buddyData = useSelector(selectBuddyData);
  const mentorData = useSelector(selectMentorData);
  const casedData = useSelector(selectCaseCandidatesData);
  const buddyStatus = useSelector(selectBuddyStatus);
  const mentorStatus = useSelector(selectMentorStatus);
  const caseStatus = useSelector(selectCaseCandidatesStatus);
  const userLevel = useSelector(selectUserLevel);
  const csrf = useSelector((state) => state.auth?.csrf);
  const [isRolePLSelected, setIsRolePLSelected] = useState(props.selectedBcger?.role === 'PL' || false);
  const [formMessage, setFormMessage] = useState();
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [caseDropdownData, setCaseDropdownData] = useState([]);

  const nameRef = useRef();
  const affiliationRef = useRef();
  const preferencesRef = useRef();
  const tenureRef = useRef();
  const notesRef = useRef();
  const rfStartRef = useRef();
  const rfEndRef = useRef();
  const staffingRef = useRef();
  const roleRef = useRef();
  const scoreRef = useRef();
  const offAxisRef = useRef();
  const genderRef = useRef();
  const ethnicRef = useRef();
  const buddyRef = useRef();
  const mentorRef = useRef();
  const caseRef = useRef();

  const patch = usePatchWithCsrf();

  const populateRosterMemberInfo = () => {
    nameRef.current.value = currentRoster.fullname;
    affiliationRef.current.value = currentRoster.affiliation;
    preferencesRef.current.value = currentRoster.preferences;
    tenureRef.current.value = currentRoster.tenure;
    notesRef.current.value = currentRoster.notes;
    const rfStartFromServer = currentRoster.rf_start;
    if (rfStartFromServer) rfStartRef.current.value = new Date(`${rfStartFromServer} GMT`).toISOString().split('T')[0];

    const rfEndFromServer = currentRoster.rf_end;
    if (rfEndFromServer) rfEndRef.current.value = new Date(`${rfEndFromServer} GMT`).toISOString().split('T')[0];

    staffingRef.current.value = currentRoster.staffing;
    if (currentRoster.role) roleRef.current.value = currentRoster.role;
    if (currentRoster.score) scoreRef.current.value = currentRoster.score;
    if (currentRoster.off_axis) offAxisRef.current.value = currentRoster.off_axis;
    genderRef.current.value = currentRoster.gender;
    ethnicRef.current.value = currentRoster.ethnic;
    buddyRef.current.value = String(currentRoster.buddy_id);
    mentorRef.current.value = String(currentRoster.mentor_id);
    caseRef.current.value = String(currentRoster.case_id);
  };

  const finishLoadData =
    currentRoster && buddyStatus === 'succeeded' && mentorStatus === 'succeeded' && caseStatus === 'succeeded';

  useEffect(() => {
    if (finishLoadData) {
      normalizeCaseDropdown();
      populateRosterMemberInfo();
    }
  }, [props.show, finishLoadData]);

  useEffect(() => {
    caseRef.current.value = String(currentRoster.case_id);
  }, [caseDropdownData]);

  const normalizeCaseDropdown = () => {
    let currentCases = [...casedData];
    if (
      currentRoster.case_code !== undefined &&
      find(currentCases, { case_code: currentRoster.case_code }) === undefined
    ) {
      currentCases.push({
        id: currentRoster.case_id,
        case_code: currentRoster.case_code,
        case_name: currentRoster.case_name,
      });
    }
    setCaseDropdownData(currentCases);
  };

  const buddiesDropdown = withNotAvailableOption(
    buddyData.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );
  const mentorsDropdown = withNotAvailableOption(
    mentorData.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );

  const casesDropdown = withNotAvailableOption(
    caseDropdownData.map((item) => (
      <option key={item.id} value={item.id}>
        {`${item.case_code} - ${item.case_name}`}
      </option>
    ))
  );

  const handleRoleChange = (e) => {
    setIsRolePLSelected(props.selectedBcger?.role === 'PL' || e.target.value === 'PL');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormMessage(null);
    const data = new FormData(event.currentTarget);
    const affiliation = data.get('affiliation');
    const preferences = data.get('preferences');
    const tenure = data.get('tenure');
    const notes = data.get('notes');
    const rf_start = data.get('rfStart') ? data.get('rfStart') : null;
    const rf_end = data.get('rfEnd') ? data.get('rfEnd') : null;
    const staffing = data.get('staffing');
    const role = data.get('role');
    const score = data.get('score');
    const off_axis = data.get('offAxis');
    const gender = data.get('gender');
    const ethnic = data.get('ethnic');
    const buddy = data.get('buddyId');
    const mentor = data.get('mentorId');
    const caseId = data.get('caseId');
    const body = {
      affiliation,
      preferences,
      notes,
      rf_start,
      rf_end,
      staffing,
      role,
      score,
      off_axis,
      gender,
      ethnic,
      buddy,
      mentor,
      active_case: caseId,
      tenure,
    };
    const response = await patch(getEditRosterMemberUrl(currentRoster.bcger_id), {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      toast.success(`Roster member ${data.get('staff')} updated successfully`, toastifyTheme);
      props.setShow(false);
    } else {
      const errorBody = await response.json();
      setFormMessage(backendErrorParser(errorBody));
    }
  };

  const onHideHandler = (e) => {
    e?.preventDefault();
    props.setShow(false);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    setShowRemoveDialog(true);
  };

  const handlePostiveClick = () => {
    setShowRemoveDialog(false);
  };

  const handleNegativeClick = async (event) => {
    event.preventDefault();
    setShowRemoveDialog(false);
    setFormMessage(null);
    const response = await props.removeRosterAPI(currentRoster.bcger_id)(csrf);
    if (response.success) {
      props.setShow(false);
      toast.success(props.removeSuccessFormMessage, toastifyTheme);
    } else {
      (async () => {
        setFormMessage(backendErrorParser(errorBody));
        await populateRosterMemberInfo();
      })();
    }
  };

  return (
    <>
      <Offcanvas show={props.show} onHide={onHideHandler} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Edit roster member</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Name' controlId='floatingSelect'>
                    <Form.Control name='staff' ref={nameRef} readOnly />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Col xs={5}>
                  <Form.Group>
                    <FloatingLabel label='Affiliation' controlId='floatingSelect'>
                      <Form.Select data-testid='select-affiliation' name='affiliation' ref={affiliationRef}>
                        {asSelectOptions(Affiliation)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='Preferences' className='mb-3'>
                      <Form.Control
                        data-testid='input-preferences'
                        name='preferences'
                        as='textarea'
                        placeholder='IPA, FPA, etc.'
                        ref={preferencesRef}
                      />
                      <div className='text-muted'>IPA, FPA, etc. </div>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='Notes' className='mb-3'>
                      <Form.Control
                        data-testid='input-notes'
                        name='notes'
                        as='textarea'
                        placeholder=''
                        ref={notesRef}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='Tenure' className='mb-3'>
                      <Form.Control
                        data-testid='input-tenure'
                        name='tenure'
                        type='number'
                        min='0'
                        max='99'
                        ref={tenureRef}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='RF Start' className='mb-3'>
                      <Form.Control data-testid='input-rfstart' name='rfStart' type='date' ref={rfStartRef} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='RF End' className='mb-3'>
                      <Form.Control data-testid='input-rfend' name='rfEnd' type='date' ref={rfEndRef} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Staffing' className='mb-3'>
                      <Form.Select
                        data-testid='select-staffing'
                        name='staffing'
                        defaultValue={defaultStaffing}
                        ref={staffingRef}
                      >
                        {asSelectOptions(Staffing)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Level3RestrictedComponent userLevel={userLevel}>
                  <Col>
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId='floatingSelect' label='Role' className='mb-3'>
                        <Form.Select data-testid='select-role' name='role' ref={roleRef} onChange={handleRoleChange}>
                          {asSelectOptions(Role)}
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Level3RestrictedComponent>
              </Row>
              <Row>
                <Level2And3RestrictedComponent userLevel={userLevel} isRolePLSelected={isRolePLSelected}>
                  <>
                    <Col>
                      <Form.Group className='mb-3'>
                        <FloatingLabel controlId='floatingSelect' label='Score' className='mb-3'>
                          <Form.Select data-testid='select-score' name='score' ref={scoreRef}>
                            {asSelectOptions(Score)}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <FloatingLabel controlId='floatingSelect' label='Off-axis' className='mb-3'>
                          <Form.Select data-testid='select-offAxis' name='offAxis' ref={offAxisRef}>
                            {asSelectOptions(OffAxis)}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </>
                </Level2And3RestrictedComponent>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Gender' className='mb-3'>
                      <Form.Select data-testid='select-gender' name='gender' ref={genderRef}>
                        {asSelectOptions(Gender)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='DEI' className='mb-3'>
                      <Form.Select data-testid='select-ethnic' name='ethnic' ref={ethnicRef}>
                        {asSelectOptions(Ethnic)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Buddy' className='mb-3'>
                      <Form.Select data-testid='select-buddy' name='buddyId' ref={buddyRef}>
                        {buddiesDropdown}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Mentor' className='mb-3'>
                      <Form.Select data-testid='select-mentor' name='mentorId' ref={mentorRef}>
                        {mentorsDropdown}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='Case' className='mb-3'>
                    <Form.Select data-testid='select-case' name='caseId' ref={caseRef}>
                      {casesDropdown}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              {finishLoadData && (
                <div className='form-footer-btn-group'>
                  <div>
                    <RemoveButton id={Number(currentRoster.bcger_id)} onClick={handleRemove}>
                      Remove
                    </RemoveButton>
                  </div>
                  <div className='form-footer-btn-group-right'>
                    <Button outlined onClick={onHideHandler} data-testid='cancel'>
                      Cancel
                    </Button>
                    <Button type='submit' data-testid='submit'>
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <GeneralDialog
        isOpen={showRemoveDialog}
        title='Warning'
        message='Are you sure you want to remove this record?'
        positiveLabel='Cancel'
        negativeLabel='Remove'
        onClickPositive={handlePostiveClick}
        onClickNegative={handleNegativeClick}
      />
    </>
  );
};

EditRosterMemberForm.propTypes = {
  setShow: PropTypes.func,
  show: PropTypes.bool,
  selectedBcger: PropTypes.object,
  removeSuccessFormMessage: PropTypes.string,
  removeRosterAPI: PropTypes.func,
};

export default EditRosterMemberForm;
