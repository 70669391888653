import React from 'react';
import PropTypes from 'prop-types';

const CapacityBlock = ({ role = '', members = [] }) => {
  const memberNameList = [];
  const memberScoreList = [];
  const getMemberList = (members) =>
    members.map((member, idx) => {
      memberNameList.push(
        <div key={idx} className='member-name-info'>
          {member.fullname ?? '--'}
        </div>
      );
      memberScoreList.push(
        <div key={idx} className='member-score-info'>
          {member.score ?? '--'}
        </div>
      );
    });
  getMemberList(members);
  return (
    <div className='capacity-block-container'>
      <div className='capacity-block-header'>
        <div className='capacity-block-header-role'>
          <div className={`role-badge ${role}`}>{role}</div>
        </div>
        <div className='capacity-block-header-total'>{members.length != 0 ? members.length : '--'}</div>
      </div>
      <div className='capacity-block-body'>
        <div className='member-name'>
          <div className='member-name-label capacity-block-body-label'>Name</div>
          {memberNameList}
        </div>
        <div className='member-score'>
          <div className='member-score-label capacity-block-body-label'>Score</div>
          {memberScoreList}
        </div>
      </div>
    </div>
  );
};

CapacityBlock.propTypes = {
  role: PropTypes.string,
  count: PropTypes.number,
  members: PropTypes.array,
};

export default CapacityBlock;
