import React from 'react';
import GenericViewLayout from '../GenericHeader';

import banner from '../../icons/greenBanner.svg';
import { DevelopmentListView } from './DevelopmentListView';
import { getPLDevelopment } from '../../api/development';

const PLDevelopment = () => {
  return (
    <>
      <GenericViewLayout header={'PL development'} banner={banner} />
      <DevelopmentListView api={getPLDevelopment} />
    </>
  );
};
export default PLDevelopment;
