import styled, { createGlobalStyle } from 'styled-components';

export const toastifyTheme = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const Button = styled.button`
  font-size: 1em;
  margin-right: 1em;
  padding: 0.5rem 1.5rem;
  border-radius: 0.1875rem;
  color: ${(props) => (props.outlined ? props.theme.primary : props.theme.bgWhite)};
  background-color: ${(props) => (props.outlined ? props.theme.bgWhite : props.theme.primary)};
  border: ${(props) => (props.outlined ? `0.03125rem solid ${props.theme.primary}` : '0')};
`;

export const GlobalStyle = createGlobalStyle`
  .form-select, .form-text, .form-control:not([type=search]) {
    background: transparent;
    border: none;
    border-bottom: 0.0625rem solid #E3E3E3;
    padding-left: 0;
    border-radius: 0;
    :focus {
      border-bottom: 0.0625rem solid ${(props) => props.theme.primary};
      box-shadow: none;
    }
    height: 4.6875rem;
    margin-top: 0.9375rem;
  }

  .form-control {
    display: inline-block;
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: ${(props) => props.theme.bgGrey};
    background-clip: content-box;
    border: none;
  }

  .form-floating > label {
    padding: 0;
  }

  .form-select, .form-control {
    text-indent: 0.1rem;
  }

  thead {
    font-size: 0.875rem;
    font-weight: 500;
    background-color: ${(props) => props.theme.bgGrey};
  }

  th {
    padding: 1rem !important;
  }

  .sticky-col {
    position: sticky;
    left: 0;
    width: 25rem;
    max-width: 25rem;
    min-width: 25rem;
    background-color: ${(props) => props.theme.bgWhite} !important;
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sticky-header {
    position: sticky;
    left: 0;
    width: 25rem;
    max-width: 25rem;
    min-width: 25rem;
    background-color: ${(props) => props.theme.bgGrey} !important;
  }

  tbody > tr {
    height: 6.3125rem;
    font-size: 0.875rem;
  }

  td {
    vertical-align: middle;
    padding: 1rem !important;
  }

  .accordion-button {
    color: #8a92a6;
    border-radius: 0.25rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    :focus,:active {
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      box-shadow: none;
    }
    :not(.collapsed) {
      background-color: #8a92a6;
      color: ${(props) => props.theme.white};
      box-shadow: none;
    }
    :after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }

  .accordion-item {
    border: none;
  }

  .offcanvas{
    width: 33%;
    padding-left: 0% 2%;
  }

  .role-badge {
    border-radius: 1.25rem;
    width: auto;
    height: 1.5rem;
    padding: 0 0.9375rem;
    font-size: 0.626rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .OP {
    background: #D1D8F7;
    color: #4755A8 !important;
    font-weight: 500;
    border-radius: 1.25rem;
  }

  .Developing {
    background: ${(props) => props.theme.yellow} !important;
    color: ${(props) => props.theme.white} !important;
    font-weight: 500;
    border-radius: 1.25rem;
  }

  .Anchor {
    background: ${(props) => props.theme.blueAnchor} !important;
    color: ${(props) => props.theme.white} !important;
    font-weight: 500;
    border-radius: 1.25rem;
  }

  .Utility {
    background: ${(props) => props.theme.orange} !important;
    color: ${(props) => props.theme.white} !important;
    font-weight: 500;
    border-radius: 1.25rem;
  }

  .PL {
    background: ${(props) => props.theme.purple} !important;
    color: ${(props) => props.theme.white} !important;
    font-weight: 500;
    border-radius: 1.25rem;
  }

  .fullname-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      div:first-child {
        font-weight: 500;
        font-size: 0.875rem;
        color: #232D42;
      };
      div:nth-child(2) {
        color: #ADB5BD;
      };
  }

  .rbc-event-content svg {
    display: none;
  }

  .rbc-event-content:hover svg {
    display: inline;
  }

  .toolbar-navigation-button {
    border: none;
    padding: 0.1875rem 0.4375rem;
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    margin-right: 0.1875rem;
  }

  .today {
    background: #369BFF;
    margin-left: 1rem !important;
  }

  .accordion-button .icon.active {
    display: none;
  }

  .accordion-button:active .icon,
  .accordion-button:focus .icon {
    display: none;
  }

  .accordion-button:active .icon.active,
  .accordion-button:focus .icon.active {
    display: inline-block;
  }

  .cta-button {
    cursor: pointer;
  }
  .cta-button:hover {
    opacity: 80%;
  }
  .remove-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.red};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 17px;
    gap: 15px;
  }
  .staffing-form .remove-button-text {
    display: none;
  }
  .staffing-form .remove-button {
    height: fit-content;
  }
  .staffing-form img {
    pointer-events: none;
  }
  .add-new-btn-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 0.0625rem dashed ${(props) => props.theme.greenBorder};
    border-radius: 0.25rem;
    width: 2rem;
    height: 2rem;
    pointer-events: none;
  }
  .add-new-btn-plus-icon{
    color: ${(props) => props.theme.greenBorder};
    font-size: 1rem;
    pointer-events: none;
  }
  .add-new-btn-text{
    pointer-events: none;
  }
  .add-new-btn-container{
    color: ${(props) => props.theme.greenBorder};
    display: flex;
    align-items: center;
    gap: 0.5625rem;
    cursor: pointer;
  }
  .form-scrollable {
    overflow: scroll;
  }

  .header-button {
    min-width: 14rem;
    width: auto;
    background-color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    padding: .7rem .75rem;
    color: #31c67f;
    font-size: 1rem;
    line-height: 1.21rem;
    margin: 0.5rem 0;
    cursor: pointer;
  }

  .header-button:hover {
    color: #2cb272;
  }

  .optimization-item-container{
    width: 100%;
  }

  .optimization-capacity{
    position:relative;
    white-space: nowrap;
    overflow: scroll;
  }

  .optimization-help-text{
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${(props) => props.theme.textSecondary};
  }

  .capacity-container{
    border-radius: 0.5rem !important;
    width: 100%;
    border: 1px solid #E3E3E3 !important;
    display: inline-grid;
    overflow: hidden;
    max-width: 21rem;
    margin-right: 1.25rem;
  }
  .capacity-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.1875rem;
    background-color: ${(props) => props.theme.bgGrey} !important;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    padding: 1.5rem;
  }
  .capacity-block-container{
    background-color: ${(props) => props.theme.bgWhite} !important;
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
  }

  .capacity-block-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  .capacity-block-header-role .role-badge{
    height: 1.8125rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.05em;
    padding: 0.375rem 0.75rem;
  }
  .capacity-block-header-total{
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
  }
  .capacity-block-body{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75rem;
  }
  .capacity-block-body-label{
    color: ${(props) => props.theme.textSecondary} !important;
    font-size: 0.75rem;
    line-height: 1.5625rem;
  }

  .member-name{

  }
  .member-score{
    text-align: right;
  }

  .secondary-text{
    color: #8A92A6;
  }
  .date-in-the-future{
    color: ${(props) => props.theme.blue};
  }
  .date-in-the-past{
    color: ${(props) => props.theme.green};
  }
  .error-input-field {
    border-bottom: 0.0625rem solid ${(props) => props.theme.red} !important;
  }
`;

const theme = {
  primary: '#31C67F',
  bgWhite: '#FFFFFF',
  bgGrey: '#F5F5F5',
  white: '#FFFFFF',
  textSecondary: '#8A92A6',
  red: '#C03221',
  blue: '#369BFF',
  green: '#31C67F',
  yellow: '#F2C947',
  purple: '#8360BF',
  blueAnchor: '#5099E9',
  orange: '#F2A548',
  greenBorder: '#31C67F',
  font: {
    medium: '1.32vh',
    h6: '1.07vh',
  },
};

export default theme;
