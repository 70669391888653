import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import GenericViewLayout from '../GenericHeader';
import AddNewRosterPipelineMemberForm from './AddNewRosterPipelineMemberForm';
import { PotentialPipelineListView } from './PotentialPipelineListView';
import banner from '../../icons/blueBanner.svg';
import Button from 'react-bootstrap/Button';
import { fetchPipeline } from '../../store/pipeline';

const PotentialPipeline = () => {
  const [addFormIsShown, setAddFormIsShown] = useState(false);
  const dispatch = useDispatch();

  function showAddFormHandler() {
    setAddFormIsShown(true);
  }

  function hideAddFormHandler() {
    setAddFormIsShown(false);
  }

  const onNewItemAddedHandler = () => {
    dispatch(fetchPipeline());
  };

  return (
    <>
      <GenericViewLayout
        header={'Potential ringfence pipeline'}
        rightButton={
          <Button
            variant='light'
            data-testid='add-pipeline-roster'
            onClick={showAddFormHandler}
            className='header-button'
          >
            + Add new pipeline roster
          </Button>
        }
        banner={banner}
      />
      <div>
        <PotentialPipelineListView />
      </div>
      <AddNewRosterPipelineMemberForm
        show={addFormIsShown}
        onClose={hideAddFormHandler}
        onNewItemAdded={onNewItemAddedHandler}
      />
    </>
  );
};
export default PotentialPipeline;
