import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Offcanvas, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CaseAssignMembersForm from './CaseAssignMembersForm';
import EditCaseInfoForm from './EditCaseInfoForm';
import RemoveButton from '../common/RemoveButton/RemoveButton';
import { Button, toastifyTheme } from '../../theme';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog';
import { deleteCase } from '../../api/case';
import { toast } from 'react-toastify';

const EditCaseForm = (props) => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [editCaseFormMessage, setEditCaseFormMessage] = useState();
  const [assignFormMessage, setAssignCaseFormMessage] = useState();
  const csrf = useSelector((state) => state.auth?.csrf);
  const submitCaseInfoRef = useRef();
  const submitCaseAssigneesRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    submitCaseInfoRef.current.click();
    submitCaseAssigneesRef.current.click();
  };

  const handleRemove = (e) => {
    e.preventDefault();
    setShowRemoveDialog(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    props.handleClose();
  };

  const handlePostiveClick = () => {
    setShowRemoveDialog(false);
  };

  const handleNegativeClick = async () => {
    setShowRemoveDialog(false);
    const response = await deleteCase(csrf, props.case.id);
    if (response.success) {
      props.handleClose();
      toast.success(
        `Case ${props.case.case_name} removed. Unassigned all roster members assigned to this case.`,
        toastifyTheme
      );
    } else {
      const errorBody = data;
      setEditCaseFormMessage(`Case removal failed: ${backendErrorParser(errorBody)}`);
    }
  };

  const showSuccessToastMessage = (message) => {
    props.handleClose();
    toast.success(message, toastifyTheme);
  };

  return (
    <>
      <Offcanvas show={props.show} onHide={props.handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Edit case</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EditCaseInfoForm
            case={props.case}
            submitRef={submitCaseInfoRef}
            showFormMessage={(msg) => setEditCaseFormMessage(msg)}
            showToastMessage={showSuccessToastMessage}
          />
          <br />
          <CaseAssignMembersForm
            caseId={props.case.id}
            from={props.from}
            submitRef={submitCaseAssigneesRef}
            showFormMessage={(msg) => setAssignCaseFormMessage(msg)}
            showToastMessage={showSuccessToastMessage}
          />
          <br />
          <br />
          <Form>
            <div className='form-footer'>
              {editCaseFormMessage && <div className='form-footer-message'>Edit case: {editCaseFormMessage}</div>}
              {assignFormMessage && <div className='form-footer-message'>Assign members: {assignFormMessage}</div>}
              <div className='form-footer-btn-group'>
                <div>
                  <RemoveButton id={props.case.id} onClick={handleRemove} />
                </div>
                <div className='form-footer-btn-group-right'>
                  <Button data-testid='edit-case-cancel-btn' outlined onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button data-testid='edit-case-submit-btn' type='submit' onClick={handleSubmit}>
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <GeneralDialog
        isOpen={showRemoveDialog}
        title='Warning'
        message='Are you sure you want to remove this case?'
        positiveLabel='Cancel'
        negativeLabel='Remove'
        onClickPositive={handlePostiveClick}
        onClickNegative={handleNegativeClick}
      />
    </>
  );
};

EditCaseForm.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  case: PropTypes.object,
  from: PropTypes.string,
};

export default EditCaseForm;
