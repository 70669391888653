import React from 'react';
import moment from 'moment';

export const TimelineField = (date) => {
  const currentDate = moment().format('MM-DD-YYYY');
  return moment(date, 'MM-DD-YYYY').isSameOrAfter(currentDate, 'day') ? (
    <span className='date-in-the-future'>{date}</span>
  ) : (
    <span className='date-in-the-past'>{date}</span>
  );
};
