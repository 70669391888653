import {
  currentRosterUrl,
  alumniUrl,
  buddyCandidatesUrl,
  mentorCandidatesUrl,
  getCasePrincipalCandidatesUrl,
  removeCurrentAlumniUrl,
  removePipelineRosterUrl,
  removeCurrentRosterUrl,
  getCurrentPipelineUrl,
} from './urls';
import { fetchWithCredentials, patchWithCsrf, deleteWithCsrf } from './restClient';

export const getCurrentRosters = async (query = '') => {
  return await fetchWithCredentials(`${currentRosterUrl}${query}`)();
};
export const getPotentialPipeline = fetchWithCredentials(getCurrentPipelineUrl);
export const getAlumni = fetchWithCredentials(alumniUrl);
export const editAlumni = (id) =>
  patchWithCsrf(`${alumniUrl}${id}/`, {
    'Content-Type': 'application/json',
  });
export const removeAlumni = (id) =>
  deleteWithCsrf(removeCurrentAlumniUrl(id), {
    'Content-Type': 'application/json',
  });
export const removePipelineRoster = (id) =>
  deleteWithCsrf(removePipelineRosterUrl(id), {
    'Content-Type': 'application/json',
  });
export const removeCurrentRoster = (id) =>
  patchWithCsrf(removeCurrentRosterUrl(id), {
    'Content-Type': 'application/json',
  });
export const fetchBuddyCandidates = fetchWithCredentials(buddyCandidatesUrl);
export const fetchMentorCandidates = fetchWithCredentials(mentorCandidatesUrl);
export const fetchPrincipalCandidates = fetchWithCredentials(getCasePrincipalCandidatesUrl);
