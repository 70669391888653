import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar, Table } from 'react-bootstrap';
import EditCaseForm from './EditCaseForm';
import { fetchPastCases, selectPastCases, selectPastCaseById } from '../../store/pastCase';

import { useSelector, useDispatch } from 'react-redux';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import { fetchCasePrincipal } from '../../store/candidates';

export function PastCasesListView() {
  let content;
  const dispatch = useDispatch();
  const pastCases = useSelector(selectPastCases);

  const [showEditCaseForm, setShowEditCaseForm] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState();
  const selectedCase = useSelector((state) => selectPastCaseById(state, selectedCaseId));

  useEffect(() => {
    if (showEditCaseForm) return;
    dispatch(fetchPastCases());
    dispatch(fetchCasePrincipal());
  }, [showEditCaseForm, dispatch]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setSelectedCaseId(e.target.id);
    setShowEditCaseForm(true);
  }, []);
  function hideEditFormHandler() {
    setShowEditCaseForm(false);
  }

  const tableListView = (data) => {
    return data.map((item) => (
      <tr key={item.id} id={item.id} onClick={handleClick} data-testid={`row-${item.id}`}>
        <td id={item.id}>{item.case_code}</td>
        <td id={item.id}>{item.case_name}</td>
        <td id={item.id}>{item.client}</td>
        <td id={item.id}>
          Start: {item.start_date} <br />
          End: {item.end_date}
        </td>
        <td id={item.id}>{item.length_weeks ? `${item.length_weeks} weeks` : <></>}</td>
        <td id={item.id}>{item.description}</td>
        <td id={item.id}>{item.budget.toLocaleString()}</td>
        <td id={item.id}>{item.principal_display_name}</td>
        <td id={item.id}>{item.xo}</td>
        <td id={item.id}>{item.mdps}</td>
        <td id={item.id}>{item.priority}</td>
        <td data-testid={`complexity-cell-${item.id}`} id={item.id}>
          {item.complexity}
        </td>
        <td id={item.id}>{item.probability}</td>
      </tr>
    ));
  };

  switch (pastCases.status) {
    case 'succeeded':
    case 'loading':
      content = tableListView(pastCases.data);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={9}>
            <ErrorWithRetry
              text={pastCases.error}
              onRetry={(_) => {
                dispatch(fetchPastCases());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table'>
          <thead>
            <tr>
              <th>Case code</th>
              <th>Case Name</th>
              <th>Client</th>
              <th>Timeline</th>
              <th>Length</th>
              <th>Description</th>
              <th>Budget</th>
              <th>Principal</th>
              <th>XO</th>
              <th>MDPs</th>
              <th>Priority</th>
              <th>Complexity</th>
              <th>Probability</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
        {pastCases.status === 'loading' && (
          <div className='table-loading-container'>
            <LoadingSpinner />
          </div>
        )}
        {selectedCase && (
          <EditCaseForm
            case={selectedCase}
            show={showEditCaseForm}
            handleClose={hideEditFormHandler}
            from={'pastCases'}
          />
        )}
      </div>
    </>
  );
}
