export const getAllStaffUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/`;
export const loginUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/login/`;
export const csrfUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/csrf/`;
export const importStaffUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/import/`;

export const createRosterMemberUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/`;
export const getBuddyCandidatesUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/buddy-candidates/`;
export const getMentorCandidatesUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/mentor-candidates/`;
export const getCasePrincipalCandidatesUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/case-principal-candidates/`;
export const getStaffingRosterMemberCandidatesUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/roster-member-candidates/`;
const ROSTER_MEMBER_URL = `${process.env.REACT_APP_BACKEND_URL}/roster-members/`;
const DASHBOARD_URL = `${ROSTER_MEMBER_URL}dashboard/`;
export const availableCapacityUrl = `${DASHBOARD_URL}available-capacity/`;

export function getRosterMemberProfileByIdUrl(id) {
  return `${process.env.REACT_APP_BACKEND_URL}/roster-members/${id}/`;
}
export function getEditRosterMemberUrl(id) {
  return `${process.env.REACT_APP_BACKEND_URL}/roster-members/${id}/`;
}

export function getRemoveCurrentRosterUrl(id) {
  return `${process.env.REACT_APP_BACKEND_URL}/roster-members/${id}/remove-current-roster/`;
}

export const getCurrentRosterUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/current-roster/`;
export const getCurrentPipelineUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/pipeline/`;

const caseUrl = `${process.env.REACT_APP_BACKEND_URL}/cases/`;
export const createCaseUrl = caseUrl;
export const getCasesUrl = caseUrl;
export const getCurrentAndUpcomingCasesUrl = `${caseUrl}current-upcoming/`;
export const getPastCasesUrl = `${caseUrl}past/`;
export const getCaseByIdUrl = (id) => `${caseUrl}${id}/`;
export const getUpdateCaseInfoUrl = (id) => `${caseUrl}${id}/`;
export const getDeleteCaseUrl = (id) => `${caseUrl}${id}/`;
export const getCaseAssignMembersUrl = (caseId) => `${caseUrl}${caseId}/assign-members/`;

export const getStaffingByCaseUrl = `${caseUrl}staffing/`;

export const getAddExperienceCandidatesUrl = `${process.env.REACT_APP_BACKEND_URL}/roster-members/add-experience-candidates/`;
export const createExperienceUrl = `${process.env.REACT_APP_BACKEND_URL}/experience/`;
export const getAllExperienceUrl = `${process.env.REACT_APP_BACKEND_URL}/experience/`;
export function getDeleteExperienceUrl(id) {
  return `${process.env.REACT_APP_BACKEND_URL}/experience/${id}/`;
}

export const getExperienceByIdUrl = (id) => `${process.env.REACT_APP_BACKEND_URL}/experience/${id}/`;
