export function sortByCaseCode(loadedCases) {
  return loadedCases.sort(function (left, right) {
    const case_codeA = left.case_code.toUpperCase();
    const case_codeB = right.case_code.toUpperCase();
    if (case_codeA < case_codeB) {
      return -1;
    }
    if (case_codeA > case_codeB) {
      return 1;
    }
    return 0;
  });
}
