import React, { useState } from 'react';
import GenericViewLayout from '../GenericHeader';
import banner from '../../icons/greenBanner.svg';
import { StaffingByCaseListView } from './StaffingByCaseListView';

const StaffingByCase = () => {
  return (
    <>
      <GenericViewLayout topHeader={'Staffing'} header={'Case-by-case staffing view'} banner={banner} />
      <div>
        <StaffingByCaseListView />
      </div>
    </>
  );
};
export default StaffingByCase;
