import { useSelector } from 'react-redux';

export const usePostWithCsrf = () => {
  const csrf = useSelector((state) => state.auth?.csrf);
  return async (url, { headers, ...otherOptions }) => {
    return await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRFToken': csrf,
        ...headers,
      },
      credentials: 'include',
      ...otherOptions,
    });
  };
};

export const usePatchWithCsrf = () => {
  const csrf = useSelector((state) => state.auth?.csrf);
  return async (url, { headers, ...otherOptions } = { headers: {} }) => {
    return await fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRFToken': csrf,
        ...headers,
      },
      credentials: 'include',
      ...otherOptions,
    });
  };
};
export const useDeleteWithCsrf = () => {
  const csrf = useSelector((state) => state.auth?.csrf);
  return async (url, { headers, ...otherOptions } = { headers: {} }) => {
    return await fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRFToken': csrf,
        ...headers,
      },
      credentials: 'include',
      ...otherOptions,
    });
  };
};
export const useGet = () => {
  return async (url, { headers, ...otherOptions } = {}) => {
    return await fetch(url, {
      credentials: 'include',
      ...otherOptions,
    });
  };
};
