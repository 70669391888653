import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/authentication';
import LogoutIcon from '../icons/logout.svg';
import SettingsIcon from '../icons/settings.svg';
import styles from './Welcome.module.css';

function Welcome() {
  const dispatch = useDispatch();
  const displayName = useSelector((state) => {
    return state.auth?.user?.username || state.auth?.user?.email;
  });

  const handleSelect = (eventKey, event) => {
    if (eventKey === 'logout') {
      dispatch(logout());
    }
  };

  return (
    <>
      <div className={`col-sm-10 ${styles['user-account']}`}>
        <div className={styles['user-initial']}>{displayName && displayName[0].toUpperCase()}</div>
        <DropdownButton align='end' id='account-menu' variant={null} title={displayName || ''} onSelect={handleSelect}>
          <Dropdown.Item>
            <img src={SettingsIcon} className='link-icon' />
            Settings
          </Dropdown.Item>
          <Dropdown.Item eventKey='logout'>
            <img src={LogoutIcon} className='link-icon' />
            Log out
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </>
  );
}

export default Welcome;
