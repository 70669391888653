import React, { useState } from 'react';
import GenericViewLayout from '../GenericHeader';
import AddNewRosterPipelineMemberForm from './AddNewRosterPipelineMemberForm';
import { CurrentRosterListView } from './CurrentRosterListView';
import banner from '../../icons/greenBanner.svg';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { fetchCurrentRosters } from '../../store/currenRoster';

const CurrentRoster = () => {
  const [addFormIsShown, setAddFormIsShown] = useState(false);
  const dispatch = useDispatch();

  function showAddFormHandler() {
    setAddFormIsShown(true);
  }
  function hideAddFormHandler() {
    setAddFormIsShown(false);
  }
  const onNewItemAddedHandler = () => {
    dispatch(fetchCurrentRosters());
  };

  return (
    <>
      <GenericViewLayout
        header={'Current RF roster'}
        rightButton={
          <Button variant='light' data-testid='add-new-roster' onClick={showAddFormHandler} className='header-button'>
            + Add new roster
          </Button>
        }
        banner={banner}
      />
      <div>
        <CurrentRosterListView />
      </div>
      <AddNewRosterPipelineMemberForm
        show={addFormIsShown}
        onClose={hideAddFormHandler}
        onNewItemAdded={onNewItemAddedHandler}
      />
    </>
  );
};
export default CurrentRoster;
