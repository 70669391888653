import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '../../../icons/Edit.svg';

const EditButton = ({ id, onClick = () => {}, styles = {} }) => {
  return (
    <img
      src={EditIcon}
      id={id}
      onClick={onClick}
      className='cta-button'
      style={{ ...styles, padding: '0.625rem' }}
      data-testid={`edit-${id}`}
    />
  );
};

EditButton.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.object,
};

export default EditButton;
