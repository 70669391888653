import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as RemoveIcon } from '../../icons/Remove.svg';
import styles from './BaseMonthEvent.module.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const BaseMonthEvent = ({ id, name, color, handleRemove }) => {
  return (
    <div className={styles['month-event']}>
      <div
        style={{
          backgroundColor: color,
        }}
        className={styles['month-event-name']}
      >
        {name}
      </div>
      {handleRemove && <RemoveIcon id={id} onClick={handleRemove} />}
    </div>
  );
};

BaseMonthEvent.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  color: PropTypes.string,
  handleRemove: PropTypes.func,
};

export default BaseMonthEvent;
