import React from 'react';
import { Spinner } from 'react-bootstrap';

export const LoadingSpinner = () => {
  return (
    <div className='spinner-container'>
      <Spinner animation='border' variant='success' />
    </div>
  );
};
