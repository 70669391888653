import React, { useEffect, useState } from 'react';
import styles from './StaffListView.module.css';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaff, selectAllStaff, selectStaffStatus, selectStaffError } from '../../../store/staff';
import { LoadingSpinner } from '../../common/Spinner/Spinner';
import ErrorWithRetry from '../../common/ErrorWithRetry/ErrorWithRetry';

// DEPRECATED: This page is removed on Sidebar as Wudi request
export default function StaffListView() {
  const staffData = useSelector(selectAllStaff);
  const status = useSelector(selectStaffStatus);
  const error = useSelector(selectStaffError);
  const dispatch = useDispatch();
  let content;

  useEffect(() => {
    if (status == 'idle') {
      dispatch(fetchStaff());
    }
  }, [status, dispatch]);

  const tableListView = (data) =>
    data.map((item) => (
      <tr key={item.id} className={styles['staff-row']}>
        <td>
          {item.fullname} ({item.cohort})
        </td>
        <td>{item.bcg_email}</td>
      </tr>
    ));

  switch (status) {
    case 'succeeded':
      content = tableListView(staffData);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={2}>
            <ErrorWithRetry
              text={error}
              onRetry={(e) => {
                dispatch(fetchStaff());
              }}
            />
          </td>
        </tr>
      );
      break;
    case 'loading':
      content = (
        <tr>
          <td colSpan={2}>
            <LoadingSpinner />
          </td>
        </tr>
      );
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless>
          <thead className={styles['sticky-header']}>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
      </div>
    </>
  );
}
