import React from 'react';
import GenericViewLayout from '../GenericHeader';
import { AlumniListView } from './AlumniListView';
import banner from '../../icons/purpleBanner.svg';

const Alumni = () => {
  return (
    <>
      <GenericViewLayout header={'RF alumni'} banner={banner} />
      <div>
        <AlumniListView />
      </div>
    </>
  );
};
export default Alumni;
