import * as urls from './urls';
import { postWithCsrf, fetchWithCredentials, deleteWithCsrf } from './restClient';

const fetchCandidates = (getCandidateUrl) => async () => {
  const response = await fetch(getCandidateUrl, { credentials: 'include' });
  if (!response.ok) {
    throw new Error('Get candidates failed');
  }
  const responseData = await response.json();
  const candidates = responseData.map((item) => ({
    bcger_id: item.bcger_id,
    fullname: item.fullname,
  }));

  return candidates;
};

export const fetchCasePrincipalCandidates = fetchCandidates(urls.getCasePrincipalCandidatesUrl);
export const createCase = postWithCsrf(urls.createCaseUrl, { 'Content-Type': 'application/json' });
export const deleteCase = (csrl, caseId) =>
  deleteWithCsrf(urls.getDeleteCaseUrl(caseId), {
    'Content-Type': 'application/json',
  })(csrl);
export const fetchCasesByMonth = (month, year) =>
  fetchWithCredentials(`${urls.getCurrentAndUpcomingCasesUrl}?month=${month}&year=${year}`)();
export const fetchAllCases = fetchWithCredentials(urls.getCasesUrl);
export const fetchCurrentAndUpcomingCases = fetchWithCredentials(urls.getCurrentAndUpcomingCasesUrl);
export const fetchCaseAsigneeMembers = (caseId) => fetchWithCredentials(urls.getCaseAssigneeMembersUrl(caseId))();
export const fetchPastCasesApi = fetchWithCredentials(urls.getPastCasesUrl);
