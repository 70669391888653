import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import EditRosterMemberForm from './EditRosterMemberForm';
import Ethnic from '../../icons/Ethnic.svg';
import EditButton from '../common/EditButton/EditButton';
import { useSelector, useDispatch } from 'react-redux';
import { BsChevronExpand } from 'react-icons/bs';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import {
  fetchCurrentRosters,
  selectCurrentRosters,
  selectCurrentRostersStatus,
  selectCurrentRostersError,
  selectRosterById,
  SORTED_BY_STAFF,
  SORTED_BY_CASE,
} from '../../store/currenRoster';
import { selectUserLevel } from '../../store/authentication';
import {
  fetchBuddy,
  fetchCaseCandidates,
  fetchMentor,
  selectBuddyStatus,
  selectCaseCandidatesStatus,
  selectMentorStatus,
} from '../../store/candidates';
import { fetchStaff, selectStaffStatus } from '../../store/staff';
import { removeCurrentRoster } from '../../api/roster';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import BCGerName from '../common/BCGerName/BCGerName';

export function CurrentRosterListView() {
  let content;
  const dispatch = useDispatch();
  const staffStatus = useSelector(selectStaffStatus);
  const buddyStatus = useSelector(selectBuddyStatus);
  const mentorStatus = useSelector(selectMentorStatus);
  const caseStatus = useSelector(selectCaseCandidatesStatus);
  const rosterData = useSelector(selectCurrentRosters);
  const status = useSelector(selectCurrentRostersStatus);
  const error = useSelector(selectCurrentRostersError);
  const userLevel = useSelector(selectUserLevel);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedBcgerId, setSelectedBcgerId] = useState();
  const selectedBcger = useSelector((state) => selectRosterById(state, selectedBcgerId));

  const [sortBy, setSortBy] = useState(SORTED_BY_STAFF);

  useEffect(() => {
    if (showEditForm) return;
    if (staffStatus === 'idle') {
      dispatch(fetchStaff());
    }
    if (buddyStatus === 'idle') {
      dispatch(fetchBuddy());
    }
    if (mentorStatus === 'idle') {
      dispatch(fetchMentor());
    }
    if (caseStatus === 'idle') {
      dispatch(fetchCaseCandidates());
    }
    dispatch(fetchCurrentRosters(buildQueryParams(sortBy)));
  }, [showEditForm]);

  const handleEditClick = useCallback((e) => {
    e.preventDefault();
    setSelectedBcgerId(e.target.id);
    setShowEditForm(true);
  }, []);

  const handleSort = (condition) => {
    if (condition === sortBy) return;

    dispatch(fetchCurrentRosters(buildQueryParams(condition)));
    setSortBy(condition);
  };

  const buildQueryParams = (condition) => {
    return `?sort_by=${condition}`;
  };

  const tableListView = (data) => {
    return data.map((item) => (
      <tr key={item.bcger_id} data-testid={`row-${item.bcger_id}`}>
        <td>
          <EditButton id={item.bcger_id} onClick={handleEditClick} />
        </td>
        <td>
          <div className='fullname-col'>
            <BCGerName bcger={item} />
          </div>
        </td>
        {userLevel !== 3 && (
          <td>
            <div className={`role-badge ${item.role}`}>{item.role}</div>
          </td>
        )}
        <td>
          Start: {item.rf_start} <br />
          End: {item.rf_end}
        </td>
        <td>{item.preferences}</td>
        <td data-testid={`notes-cell-${item.bcger_id}`}>{item.notes}</td>
        <td data-testid={`tenure-cell-${item.bcger_id}`}>{item.tenure}</td>
        <td>{item.staffing}</td>
        {userLevel !== 3 && <td>{item.score}</td>}
        {userLevel !== 3 && <td>{item.off_axis}</td>}
        <td>
          {item.case_code}
          <br />
          {item.case_name}
        </td>
        <td>{item.case_end}</td>
        <td>{item.hours_lw}</td>
        <td>{item.hours_5w}</td>
        <td>{item.gender}</td>
        <td>{item.ethnic === 'Y' && <img src={Ethnic} />}</td>
        <td>{item.buddy_name}</td>
        <td>{item.mentor_name}</td>
      </tr>
    ));
  };

  switch (status) {
    case 'succeeded':
    case 'loading':
      content = tableListView(rosterData);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={9}>
            <ErrorWithRetry
              text={error}
              onRetry={(_) => {
                dispatch(fetchCurrentRosters(buildQueryParams(sortBy)));
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              {userLevel !== 3 && <th>Role</th>}
              <th>RF Timeline</th>
              <th>Preference</th>
              <th>Note</th>
              <th>Tenure</th>
              <th>
                Staffing
                <BsChevronExpand
                  color={sortBy == SORTED_BY_STAFF ? '#31C67F' : ''}
                  className='sort-icon'
                  data-testid='sort-by-staff'
                  onClick={(e) => {
                    handleSort(SORTED_BY_STAFF);
                  }}
                />
              </th>
              {userLevel !== 3 && <th>Score</th>}
              {userLevel !== 3 && <th>Off-axis</th>}
              <th>
                Case
                <BsChevronExpand
                  color={sortBy == SORTED_BY_CASE ? '#31C67F' : ''}
                  className='sort-icon'
                  data-testid='sort-by-case'
                  onClick={(e) => {
                    handleSort(SORTED_BY_CASE);
                  }}
                />
              </th>
              <th>Case End</th>
              <th>Hours (LW)</th>
              <th>Hours (5W)</th>
              <th>Gender</th>
              <th>DEI</th>
              <th>Buddy</th>
              <th>Mentor</th>
            </tr>
          </thead>
          <tbody data-testid='roster-table-body'>{content}</tbody>
        </Table>
      </div>
      {status === 'loading' && (
        <div className='table-loading-container'>
          <LoadingSpinner />
        </div>
      )}
      {selectedBcger && (
        <EditRosterMemberForm
          selectedBcger={selectedBcger}
          show={showEditForm}
          setShow={setShowEditForm}
          removeSuccessFormMessage={'Roster member moved to Alumni successfully'}
          removeRosterAPI={removeCurrentRoster}
        />
      )}
    </>
  );
}
