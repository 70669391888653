import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Box, Button, Input } from '@mui/material';
import { usePostWithCsrf } from '../../hooks/restClient';
import { Modal } from 'react-bootstrap';
import { LoadingSpinner } from '../common/Spinner/Spinner';

const UploadInfo = ({ uploadResult }) => {
  if (!uploadResult) return null;

  if (uploadResult.error) {
    return <Alert severity='error'>Upload failed</Alert>;
  }

  const { finishedTime, newBcgerImported, existingBcgerDetected } = uploadResult;
  const formattedTime = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(
    Date.parse(finishedTime)
  );

  return (
    <Alert severity='success'>
      <AlertTitle>Your last staff import finished on {formattedTime} (your current timezone)</AlertTitle>
      New bcgers imported: {newBcgerImported}. Existing bcgers detected: {existingBcgerDetected}
    </Alert>
  );
};

UploadInfo.propTypes = {
  uploadResult: PropTypes.shape({
    error: PropTypes.bool,
    finishedTime: PropTypes.string,
    newBcgerImported: PropTypes.number,
    existingBcgerDetected: PropTypes.number,
  }),
};

export default function ImportStaff(props) {
  const [file, setFile] = useState(null);
  const [uploadResult, setUploadResult] = useState(null);
  const [isUploading, setIsUploading] = useState(null);
  const submitDisabled = React.useMemo(() => !file, [file]);

  const handleInputChange = React.useCallback(
    (event) => {
      setFile(event.target.files[0]);
    },
    [setFile]
  );

  const post = usePostWithCsrf();

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      setIsUploading(true);
      const uploadFile = async (file) => {
        const data = new FormData();
        data.append('attachment', file);

        const url = `${process.env.REACT_APP_BACKEND_URL}/staff/import/`;
        const response = await post(url, {
          body: data,
        });

        setIsUploading(false);
        if (response.ok) {
          return setUploadResult(await response.json());
        } else {
          setUploadResult({ error: true });
        }
      };

      uploadFile(file);
    },
    [post, file, setUploadResult]
  );

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import staff from xlsx</Modal.Title>
        </Modal.Header>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <UploadInfo uploadResult={uploadResult} />
          <Input
            inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }}
            type='file'
            onChange={handleInputChange}
            data-testid='file-input'
          />
          {isUploading && <LoadingSpinner />}
          <Button type='submit' variant='contained' disabled={submitDisabled} data-testid='submit'>
            Upload
          </Button>
        </Box>
      </Modal>
    </>
  );
}
ImportStaff.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
};
