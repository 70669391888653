export const ROSTER_MEMBER_URL = `${process.env.REACT_APP_BACKEND_URL}/roster-members/`;

export const createRosterMemberUrl = `${ROSTER_MEMBER_URL}`;
export const buddyCandidatesUrl = `${ROSTER_MEMBER_URL}buddy-candidates/`;
export const mentorCandidatesUrl = `${ROSTER_MEMBER_URL}mentor-candidates/`;
export const getCasePrincipalCandidatesUrl = `${ROSTER_MEMBER_URL}case-principal-candidates/`;
export const getAddExperienceCandidatesUrl = `${ROSTER_MEMBER_URL}add-experience-candidates/`;
export const currentRosterUrl = `${ROSTER_MEMBER_URL}current-roster/`;
export const developmentUrl = `${ROSTER_MEMBER_URL}development/`;
export const acDevelopmentUrl = `${developmentUrl}ac/`;
export const plDevelopmentUrl = `${developmentUrl}pl/`;
export const reviewUrl = `${ROSTER_MEMBER_URL}reviews/`;
export const outageUrl = `${ROSTER_MEMBER_URL}outages/`;
export const outageCandidatesUrl = `${ROSTER_MEMBER_URL}outages/outage-candidates/`;
export const alumniUrl = `${ROSTER_MEMBER_URL}alumni/`;
export const getCurrentPipelineUrl = `${ROSTER_MEMBER_URL}pipeline/`;
export const getRosterMemberProfileByIdUrl = (id) => `${ROSTER_MEMBER_URL}${id}/`;
export const getEditRosterMemberUrl = (id) => `${ROSTER_MEMBER_URL}${id}/`;
export const removeCurrentRosterUrl = (id) => `${ROSTER_MEMBER_URL}${id}/remove-current-roster/`;
export const removeCurrentAlumniUrl = (id) => `${ROSTER_MEMBER_URL}${id}/`;
export const removePipelineRosterUrl = (id) => `${ROSTER_MEMBER_URL}${id}/`;

const DASHBOARD_URL = `${ROSTER_MEMBER_URL}dashboard/`;
export const totalRingfenceRosterUrl = `${DASHBOARD_URL}total-ringfence-roster/`;
export const staffedOnActiveCaseUrl = `${DASHBOARD_URL}staffed-on-active-cases/`;
export const casesStartingNextWeekUrl = `${DASHBOARD_URL}cases-starting-next-week/`;
export const availableCapacityUrl = `${DASHBOARD_URL}available-capacity/`;

export const loginUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/login/`;
export const csrfUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/csrf/`;

export const getAllStaffUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/`;
export const createNewBCGerUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/`;
export const importStaffUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/import/`;

const caseUrl = `${process.env.REACT_APP_BACKEND_URL}/cases/`;
export const createCaseUrl = caseUrl;
export const getCasesUrl = caseUrl;
export const getCurrentAndUpcomingCasesUrl = `${caseUrl}current-upcoming/`;
export const getPastCasesUrl = `${caseUrl}past/`;
export const getCaseByIdUrl = (id) => `${caseUrl}${id}/`;
export const getUpdateCaseInfoUrl = (id) => `${caseUrl}${id}/`;
export const getDeleteCaseUrl = (id) => `${caseUrl}${id}/`;
export const getCaseAssignMembersUrl = (caseId) => `${caseUrl}${caseId}/assign-members/`;
export const getCaseAssigneeMembersUrl = (caseId) => `${caseUrl}${caseId}/assignee-members/`;
export const getCaseAssignStaffingUrl = (caseId) => `${caseUrl}${caseId}/assign-staffing/`;

export const getStaffingByCaseUrl = `${caseUrl}staffing/`;
export const getStaffingRosterMemberCandidatesUrl = `${process.env.REACT_APP_BACKEND_URL}/staff/roster-member-candidates/`;
export const finalizeStaffingUrl = (caseId) => `${caseUrl}staffing/${caseId}/finalize/`;
export const deleteStaffingUrl = (id) => `${caseUrl}staffing/${id}/`;

export const createExperienceUrl = `${process.env.REACT_APP_BACKEND_URL}/experience/`;
export const getAllExperienceUrl = `${process.env.REACT_APP_BACKEND_URL}/experience/`;
export function getDeleteExperienceUrl(id) {
  return `${process.env.REACT_APP_BACKEND_URL}/experience/${id}/`;
}
export const getExperienceByIdUrl = (id) => `${process.env.REACT_APP_BACKEND_URL}/experience/${id}/`;
