import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Button, toastifyTheme } from '../../theme';
import { getOutageCandidates } from '../../api/outage';
import { createOutage } from '../../api/outage';
import { FormField } from '../Forms/FormField';
import { FormDropdown } from '../Forms/FormDropdown';
import { create } from '../../store/outage';
import { toast } from 'react-toastify';
import { dateFnsLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';

const OutageForm = ({ showForm, setShowForm, startDate }) => {
  const csrf = useSelector((state) => state.auth?.csrf);
  const [dropdownList, setDropdownList] = useState(null);
  const [formData, setFormData] = useState({});
  const [helpText, setHelpText] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    startDate ? setFormData({ outage_start_date: startDate }) : setFormData({});
    setHelpText({});
    (async () => {
      const outageCandidates = await getOutageCandidates();
      setDropdownList(outageCandidates);
    })();
  }, [showForm]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await createOutage(csrf, {
      body: JSON.stringify(formData),
    });

    if (res.success) {
      dispatch(create(res.data));
      setShowForm(false);
      toast.success('Your changes were saved successfully', toastifyTheme);
    } else {
      setHelpText(res.data);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value || '' });
  };

  const onHideHandler = () => {
    setShowForm(false);
  };

  const normalizeHelpText = (data) => {
    if (!data) return '';
    if (Array.isArray(data)) {
      return data.join(',');
    }
    return data;
  };

  return (
    <>
      <Offcanvas show={showForm} onHide={onHideHandler} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Add new outage</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <FormDropdown
                  name='roster_member_id'
                  label='Name'
                  dropdownList={dropdownList}
                  onChange={handleChange}
                  helpText={normalizeHelpText(helpText['roster_member_id'])}
                />
              </Row>
              <Row>
                <FormField
                  className='mb-3 col-6'
                  name='outage_start_date'
                  type='date'
                  label='Outage Start'
                  onChange={handleChange}
                  helpText={normalizeHelpText(helpText['outage_start_date'])}
                  otherProps={{ defaultValue: startDate }}
                />
                <FormField
                  className='mb-3 col-6'
                  name='outage_end_date'
                  label='Outage End'
                  onChange={handleChange}
                  type='date'
                  helpText={normalizeHelpText(helpText['outage_end_date'])}
                />
              </Row>
            </div>
            <div className='form-footer'>
              <div className='form-footer-btn-group'>
                <div></div>
                <div className='form-footer-btn-group-right'>
                  <Button type='button' outlined onClick={onHideHandler}>
                    Cancel
                  </Button>
                  <Button type='submit' data-testid='outage-form-add-btn'>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
OutageForm.propTypes = {
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
  startDate: PropTypes.string,
};
export default OutageForm;
