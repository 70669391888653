import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { fetchAllStaff } from '../api/staff';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchStaff = createAsyncThunk('staff/fetchAllStaff', async () => {
  return await fetchAllStaff();
});

const staffSlice = createSlice({
  name: 'staff',
  initialState: initialState,
  reducers: {
    addNewBCGer(state, action) {
      state.data = [...state.data, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStaff.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchStaff.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = orderBy(action.payload, [(item) => item.fullname.toLowerCase()], ['asc']);
    });
    builder.addCase(fetchStaff.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default staffSlice.reducer;

export const { addNewBCGer } = staffSlice.actions;
export const selectAllStaff = (state) => state.staff.data;
export const selectStaffStatus = (state) => state.staff.status;
export const selectStaffError = (state) => state.staff.error;
