import { sortBy, remove as _remove, get, reduce, map, mapValues } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const outageSlice = createSlice({
  name: 'outages',
  initialState: {},
  reducers: {
    list(state, action) {
      const { month, outages } = action.payload;
      return { ...state, [month]: outages };
    },
    remove(state, action) {
      const { outageId } = action.payload;
      mapValues(state, (o) => _remove(o, ({ id }) => id == outageId));
      return state;
    },
    create(state, action) {
      const outages = action.payload;
      let newOutages = {};
      map(outages, (o) => {
        const outageDate = moment(o.outage_date, 'MM/DD/YYYY').toDate();
        const month = `${outageDate.getMonth() + 1}-${outageDate.getFullYear()}`;
        const outagesInMonth = get(newOutages, month, state[month] && [...state[month]]);
        if (outagesInMonth) {
          outagesInMonth.push(o);
          newOutages[month] = outagesInMonth;
        }
      });
      newOutages = reduce(
        newOutages,
        (sortedOutages, value, key) => {
          sortedOutages[key] = sortBy(value, 'name');
          return sortedOutages;
        },
        {}
      );
      return { ...state, ...newOutages };
    },
  },
});

export const { list, remove, create } = outageSlice.actions;
export default outageSlice.reducer;
