import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import EditButton from '../common/EditButton/EditButton';
import DevelopmentForm from './DevelopmentForm';
import { find } from 'lodash';
import BCGerName from '../common/BCGerName/BCGerName';

export function DevelopmentListView({ api }) {
  const [showForm, setShowForm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [developments, setDevelopments] = useState([]);

  const getData = useCallback(async () => {
    const response = await api();
    setDevelopments(response);
  }, []);

  useEffect(() => {
    if (showForm == true) return;
    getData();
  }, [getData, showForm]);

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    setSelectedId(Number(e.target.id));
    setShowForm(true);
  }, []);

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Role</th>
              <th>Reviewer</th>
              <th>Review Date</th>
              <th>Experience</th>
              <th>Strengths</th>
              <th>AFDs</th>
              <th>Next Steps</th>
              <th>Overall</th>
              <th>PS&I</th>
              <th>C&P</th>
              <th>P&E</th>
              <th>CI</th>
              <th>TC</th>
              <th>RM</th>
            </tr>
          </thead>
          <tbody>
            {developments.map((item) => (
              <tr key={item.bcger}>
                <td>
                  <EditButton id={item.bcger} onClick={handleEdit} />
                </td>
                <td>
                  <div className='fullname-col'>
                    <BCGerName bcger={item} />
                  </div>
                </td>
                <td>
                  <div className={`role-badge ${item.role}`}>{item.role}</div>
                </td>
                <td>{item.latest_review?.reviewer}</td>
                <td>{item.latest_review?.review_date}</td>
                <td>
                  {item.all_experience?.map((exp) => (
                    <p key={exp.id}>
                      {exp.case_name} {exp.module}
                    </p>
                  ))}
                </td>
                <td>{item.latest_review?.strengths}</td>
                <td>{item.latest_review?.AFDs}</td>
                <td>{item.latest_review?.next_step}</td>
                <td>{item.latest_review?.score_overall}</td>
                <td>{item.latest_review?.score_PSI}</td>
                <td>{item.latest_review?.score_CP}</td>
                <td>{item.latest_review?.score_PE}</td>
                <td>{item.latest_review?.score_CI}</td>
                <td>{item.latest_review?.score_TC}</td>
                <td>{item.latest_review?.score_RM}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <DevelopmentForm
          showForm={showForm}
          setShowForm={setShowForm}
          development={find(developments, ['bcger', selectedId])}
        />
      </div>
    </>
  );
}

DevelopmentListView.propTypes = {
  api: PropTypes.func.isRequired,
};
