import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllExperience } from '../api/experience';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchExperience = createAsyncThunk('acpl/case-history', async () => {
  return await getAllExperience();
});

const experienceSlice = createSlice({
  name: 'caseHistory',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExperience.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchExperience.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchExperience.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default experienceSlice.reducer;

export const selectExperience = (state) => state.experience;
export const selectExperienceById = (state, id) => {
  return state.experience.data.find((item) => Number(item.id) === Number(id));
};
