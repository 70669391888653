import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPastCasesApi } from '../api/case';
import { fetchCaseAssigneeMembers, INTERNAL_CASE_CODES } from './candidates';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchPastCases = createAsyncThunk('case/past-case', async () => {
  return await fetchPastCasesApi();
});

const pastCaseSlice = createSlice({
  name: 'pastCase',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPastCases.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchPastCases.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchPastCases.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(fetchCaseAssigneeMembers.fulfilled, (state, action) => {
      state.data = state.data.map((content, i) => {
        if (Number(content.id) === Number(action.meta.arg)) {
          return {
            ...content,
            assignees: action.payload,
          };
        } else {
          return content;
        }
      });
    });
  },
});

export default pastCaseSlice.reducer;

export const selectPastCases = (state) => {
  return {
    ...state.pastCases,
    data: state.pastCases.data.filter((item) => !INTERNAL_CASE_CODES.includes(item.case_code)),
  };
};
export const selectPastCaseById = (state, id) => {
  return state.pastCases.data.find((item) => Number(item.id) === Number(id));
};
