import React from 'react';
import PropTypes from 'prop-types';
import { BsFillArrowLeftSquareFill } from 'react-icons/bs';
export default function GenericHeader(props) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${props.banner})`,
          height: '14.625rem',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          top: '6.8125rem',
          left: '2.5rem',
          right: '2.5rem',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <h5>{props.header}</h5>
          </div>
          <div>{props.rightButton}</div>
        </div>
      </div>
    </>
  );
}

GenericHeader.propTypes = {
  header: PropTypes.string.isRequired,
  rightButton: PropTypes.node,
  banner: PropTypes.node,
};
