import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getOutagesByMonth, deleteOutage } from '../../api/outage';
import { get } from 'lodash';
import { list, remove } from '../../store/outage';
import { useSearchParams } from 'react-router-dom';
import CalendarToolbar from '../Calendar/CalendarToolbar';
import toColour from '../../utils/toColour';
import BaseMonthEvent from '../Calendar/BaseMonthEvent';
import MonthView from '../Calendar/CustomMonthView';

const localizer = momentLocalizer(moment);

const MonthEvent = ({ event }) => {
  const csrf = useSelector((state) => state.auth?.csrf);
  const dispatch = useDispatch();

  const handleRemove = (e) => {
    deleteOutage(e.currentTarget.id)(csrf);
    dispatch(remove({ outageId: e.currentTarget.id }));
  };

  return (
    <BaseMonthEvent
      id={event.id}
      name={event.name}
      color={toColour(event.name, event.roster_member_id)}
      handleRemove={handleRemove}
    />
  );
};

MonthEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

export function OutageCalendarView({ onSelectCell }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const outages = useSelector((state) => state.outage);
  const [outagesInMonth, setOutagesInMonth] = useState([]);
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState({
    month: Number(searchParams.get('month')) || today.getMonth() + 1,
    year: Number(searchParams.get('year')) || today.getFullYear(),
  });

  const processOutages = useCallback(
    (outages) =>
      outages.map((o) => ({
        start: moment(o.outage_date, 'MM/DD/YYYY').toDate(),
        end: moment(o.outage_date, 'MM/DD/YYYY').toDate(),
        ...o,
      })),
    []
  );

  const fetchOutages = async (month, year) => {
    let o = get(outages, `${month}-${year}`);
    if (!o) {
      o = await getOutagesByMonth(month, year);
      dispatch(list({ month: `${month}-${year}`, outages: o }));
    }
    await setOutagesInMonth(processOutages(o));
  };

  const handleNavigate = (newDate) => {
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    setSelectedMonth({ month, year });
    fetchOutages(month, year);
  };

  useEffect(() => {
    const { month, year } = selectedMonth;
    fetchOutages(month, year);
  }, [outages]);

  const defaultDate = useMemo(() => new Date(selectedMonth.year, selectedMonth.month - 1, 1), [selectedMonth]);
  return (
    <div className='table-wrapper'>
      <Calendar
        localizer={localizer}
        events={outagesInMonth}
        style={{
          padding: '0 0.626rem 0.626rem 0.626rem',
          backgroundColor: 'white',
          color: '#8A92A6',
        }}
        views={{ month: MonthView }}
        components={{ toolbar: CalendarToolbar, month: { event: MonthEvent } }}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: { background: 'none' },
        })}
        date={defaultDate}
        onNavigate={handleNavigate}
        popup={true}
        messages={{
          showMore: (total) => (
            <span style={{ color: '#31C67F', paddingLeft: '0.5rem', fontSize: '0.875rem' }}>+{total} more</span>
          ),
        }}
        selectable={true}
        onSelectSlot={onSelectCell}
      />
    </div>
  );
}
OutageCalendarView.propTypes = {
  onSelectCell: PropTypes.func,
};
