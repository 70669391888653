import React, { Component } from 'react';
import { FixedSizeList as List } from 'react-window';
import PropTypes from 'prop-types';

const height = 35;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List height={maxHeight} itemCount={children.length} itemSize={height} initialScrollOffset={initialOffset}>
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
  static get propTypes() {
    return {
      options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      maxHeight: PropTypes.number,
      getValue: PropTypes.func,
    };
  }
}

export default MenuList;
