import React from 'react';

export function asSelectOptions(type) {
  let result = [];
  for (let i = 0; i < type.length; i++) {
    result.push(
      <option key={type[i].key} value={type[i].key}>
        {type[i].viewableText}
      </option>
    );
  }
  return result;
}
