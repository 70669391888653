const withCsrf =
  (method) =>
  (url, headers = {}) =>
  async (csrf, { ...otherOptions } = {}) => {
    const response = await fetch(url, {
      method: method,
      headers: {
        'X-CSRFToken': csrf,
        ...headers,
      },
      credentials: 'include',
      ...otherOptions,
    });
    const responseData = response.status == 204 ? {} : await response.json();
    return { success: response.ok, data: responseData };
  };

export const postWithCsrf = withCsrf('POST');
export const patchWithCsrf = withCsrf('PATCH');
export const deleteWithCsrf = withCsrf('DELETE');

export const fetchWithCredentials = (url) => async () => {
  const response = await fetch(url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Fetch request failed');
  }
  return await response.json();
};
