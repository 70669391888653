import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import { getCurrentPipelineUrl } from '../../urls';
import EditRosterMemberForm from './EditRosterMemberForm';
import Ethnic from '../../icons/Ethnic.svg';
import EditButton from '../common/EditButton/EditButton';
import {
  fetchBuddy,
  fetchCaseCandidates,
  fetchMentor,
  selectBuddyStatus,
  selectCaseCandidatesStatus,
  selectMentorStatus,
} from '../../store/candidates';
import { fetchStaff, selectStaffStatus } from '../../store/staff';
import { selectPipeline, fetchPipeline, selectPipelineById } from '../../store/pipeline';
import { selectUserLevel } from '../../store/authentication';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import { removePipelineRoster } from '../../api/roster';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import BCGerName from '../common/BCGerName/BCGerName';

export function PotentialPipelineListView() {
  let content;
  const dispatch = useDispatch();
  const staffStatus = useSelector(selectStaffStatus);
  const buddyStatus = useSelector(selectBuddyStatus);
  const mentorStatus = useSelector(selectMentorStatus);
  const caseStatus = useSelector(selectCaseCandidatesStatus);
  const pipeline = useSelector(selectPipeline);
  const [selectedBcgerId, setSelectedBcgerId] = useState();
  const [showEditForm, setShowEditForm] = useState(false);
  const userLevel = useSelector(selectUserLevel);
  const selectedBcger = useSelector((state) => selectPipelineById(state, selectedBcgerId));

  useEffect(() => {
    if (showEditForm) return;
    if (staffStatus === 'idle') {
      dispatch(fetchStaff());
    }
    if (buddyStatus === 'idle') {
      dispatch(fetchBuddy());
    }
    if (mentorStatus === 'idle') {
      dispatch(fetchMentor());
    }
    if (caseStatus === 'idle') {
      dispatch(fetchCaseCandidates());
    }
    dispatch(fetchPipeline());
  }, [showEditForm]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setSelectedBcgerId(e.target.id);
    setShowEditForm(true);
  }, []);

  const tableListView = (data) => {
    return data.map((item) => (
      <tr key={item.bcger_id}>
        <td>
          <EditButton id={item.bcger_id} onClick={handleClick} />
        </td>
        <td>
          <div className='fullname-col'>
            <BCGerName bcger={item} />
          </div>
        </td>
        {userLevel !== 3 && (
          <td>
            <div className={`role-badge ${item.role}`}>{item.role}</div>
          </td>
        )}
        <td>
          Start: {item.rf_start} <br />
          End: {item.rf_end}
        </td>
        <td>{item.preferences}</td>
        <td data-testid={`notes-cell-${item.bcger_id}`}>{item.notes}</td>
        <td data-testid={`tenure-cell-${item.bcger_id}`}>{item.tenure}</td>
        <td>{item.staffing}</td>
        {userLevel !== 3 && <td>{item.score}</td>}
        {userLevel !== 3 && <td>{item.off_axis}</td>}
        <td>
          {item.case_code}
          <br />
          {item.case_name}
        </td>
        <td>{item.hours_lw}</td>
        <td>{item.hours_5w}</td>
        <td>{item.gender}</td>
        <td>{item.ethnic === 'Y' && <img src={Ethnic} />}</td>
        <td>{item.buddy_name}</td>
        <td>{item.mentor_name}</td>
      </tr>
    ));
  };

  switch (pipeline.status) {
    case 'succeeded':
    case 'loading':
      content = tableListView(pipeline.data);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={9}>
            <ErrorWithRetry
              text={pipeline.error}
              onRetry={(_) => {
                dispatch(fetchPipeline());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              {userLevel !== 3 && <th>Role</th>}
              <th>RF Timeline</th>
              <th>Preference</th>
              <th>Note</th>
              <th>Tenure</th>
              <th>Staffing</th>
              {userLevel !== 3 && <th>Score</th>}
              {userLevel !== 3 && <th>Off-axis</th>}
              <th>Case</th>
              <th>Hours (LW)</th>
              <th>Hours (5W)</th>
              <th>Gender</th>
              <th>DEI</th>
              <th>Buddy</th>
              <th>Mentor</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
      </div>
      {pipeline.status === 'loading' && (
        <div className='table-loading-container'>
          <LoadingSpinner />
        </div>
      )}
      {selectedBcger && (
        <EditRosterMemberForm
          selectedBcger={selectedBcger}
          show={showEditForm}
          setShow={setShowEditForm}
          removeSuccessFormMessage={'Pipeline roster was removed successfully!'}
          removeRosterAPI={removePipelineRoster}
        />
      )}
    </>
  );
}
