import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { reduce } from 'lodash';

import CalendarToolbar from '../Calendar/CalendarToolbar';
import { fetchCasesByMonth } from '../../api/case';
import BaseMonthEvent from '../Calendar/BaseMonthEvent';
import toColour from '../../utils/toColour';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MonthView from '../Calendar/CustomMonthView';

const localizer = momentLocalizer(moment);

const MonthEvent = ({ event }) => {
  return <BaseMonthEvent name={event.case_name} color={toColour(event.case_name)} />;
};

MonthEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

export function CurrentAndUpcomingCasesCalendarView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [casesInMonth, setCasesInMonth] = useState([]);
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState({
    month: Number(searchParams.get('month')) || today.getMonth() + 1,
    year: Number(searchParams.get('year')) || today.getFullYear(),
  });

  const processCases = useCallback((cases) =>
    reduce(
      cases,
      (flattenCase, element) => {
        for (var m = moment(element.start_date); m.diff(element.end_date, 'days') <= 0; m.add(1, 'days')) {
          flattenCase.push({
            case_name: element.case_name,
            start: m.toDate(),
            end: m.toDate(),
          });
        }
        return flattenCase;
      },
      []
    )
  );

  const fetchCases = async (month, year) => {
    const cases = await fetchCasesByMonth(month, year);
    await setCasesInMonth(processCases(cases));
  };

  const handleNavigate = (newDate) => {
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    setSelectedMonth({ month, year });
    fetchCases(month, year);
  };

  useEffect(() => {
    const { month, year } = selectedMonth;
    fetchCases(month, year);
  }, []);

  const defaultDate = useMemo(() => new Date(selectedMonth.year, selectedMonth.month - 1, 1), [selectedMonth]);
  return (
    <div className='tab-calendar-wrapper'>
      <Calendar
        localizer={localizer}
        events={casesInMonth}
        style={{
          padding: '0 0.626rem 0.626rem 0.626rem',
          backgroundColor: 'white',
          color: '#8A92A6',
        }}
        views={{ month: MonthView }}
        components={{ toolbar: CalendarToolbar, month: { event: MonthEvent } }}
        date={defaultDate}
        onNavigate={handleNavigate}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: { background: 'none' },
        })}
        popup={true}
        messages={{
          showMore: (total) => (
            <span style={{ color: '#31C67F', paddingLeft: '0.5rem', fontSize: '0.875rem' }}>+{total} more</span>
          ),
        }}
      />
    </div>
  );
}
