import React from 'react';
import GenericViewLayout from '../GenericHeader';

import banner from '../../icons/greenBanner.svg';
import { DevelopmentListView } from './DevelopmentListView';
import { getACDevelopment } from '../../api/development';

const ACDevelopment = () => {
  return (
    <>
      <GenericViewLayout header={'AC development'} banner={banner} />
      <DevelopmentListView api={getACDevelopment} />
    </>
  );
};
export default ACDevelopment;
