import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FloatingLabel, Form } from 'react-bootstrap';

const NumberInputField = ({ label = '', id = '', name = '', placeholder = '', refProp = undefined }) => {
  const addCommas = (num) => (num ? BigInt(num).toLocaleString() : '');
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');

  const handleChange = (event) => {
    if (refProp) {
      refProp.current.value = addCommas(removeNonNumeric(event.target.value));
    }
  };

  return (
    <FloatingLabel label={label}>
      <Form.Control
        data-testid={id}
        name={name}
        placeholder={placeholder}
        type='text'
        onInput={handleChange}
        ref={refProp}
      />
    </FloatingLabel>
  );
};

NumberInputField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default NumberInputField;
