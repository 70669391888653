import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FloatingLabel, Form, Row, Offcanvas } from 'react-bootstrap';
import { Button, toastifyTheme } from '../../theme';
import PropTypes from 'prop-types';
import { usePatchWithCsrf } from '../../hooks/restClient';
import { backendErrorParser } from '../backendErrorParser';
import { getExperienceByIdUrl } from '../../urls';
import RemoveButton from '../common/RemoveButton/RemoveButton';
import { getDeleteExperienceUrl } from '../../urls';
import { useDeleteWithCsrf } from '../../hooks/restClient';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog';
import { toast } from 'react-toastify';

const EditExperienceForm = (props) => {
  const currentExperience = props.experience;
  const [formMessage, setFormMessage] = useState();
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const nameRef = useRef();
  const caseRef = useRef();
  const moduleExperienceRef = useRef();
  const patch = usePatchWithCsrf();
  const deleteWithCsrf = useDeleteWithCsrf();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormMessage(null);
    const response = await patch(getExperienceByIdUrl(currentExperience.id), {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        module: moduleExperienceRef.current.value,
      }),
    });
    if (response.ok) {
      props.setShow(false);
      toast.success('Experience updated successfully', toastifyTheme);
    } else {
      const errorBody = await response.json();
      setFormMessage(backendErrorParser(errorBody));
    }
  };

  const finishLoadData = currentExperience.id ? true : false;

  const handleRemove = (e) => {
    e.preventDefault();
    setShowRemoveDialog(true);
  };

  const handlePostiveClick = () => {
    setShowRemoveDialog(false);
  };

  const handleNegativeClick = async () => {
    setShowRemoveDialog(false);
    deleteWithCsrf(getDeleteExperienceUrl(currentExperience.id)).then((response) => {
      if (response.ok) {
        props.setShow(false);
        toast.success('Experience removed successfully', toastifyTheme);
      } else {
        const errorBody = response.json();
        setFormMessage(backendErrorParser(errorBody));
      }
    });
  };

  const onHideHandler = () => props.setShow(false);

  useEffect(() => {
    if (finishLoadData) {
      nameRef.current.value = currentExperience.fullname;
      caseRef.current.value = `${currentExperience.case_id} - ${currentExperience.case_name} (${currentExperience.case_client})`;
      moduleExperienceRef.current.value = currentExperience.module;
    }
  }, [props.show, finishLoadData]);

  return (
    <>
      <Offcanvas show={props.show} onHide={onHideHandler} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Edit case experience</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Name'>
                    <Form.Control ref={nameRef} readOnly data-testid='name-input' />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Case code - Case Name (Client)'>
                    <Form.Control ref={caseRef} readOnly data-testid='case-input' />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Module/Experience'>
                    <Form.Control ref={moduleExperienceRef} data-testid='module-input' />
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              {finishLoadData && (
                <div className='form-footer-btn-group'>
                  <div>
                    <RemoveButton id={Number(currentExperience.id)} onClick={handleRemove} />
                  </div>
                  <div className='form-footer-btn-group-right'>
                    <Button outlined onClick={onHideHandler} data-testid='cancel'>
                      Cancel
                    </Button>
                    <Button type='submit' onClick={handleSubmit} data-testid='submit'>
                      Update
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <GeneralDialog
        isOpen={showRemoveDialog}
        title='Warning'
        message='Are you sure you want to remove this record?'
        positiveLabel='Cancel'
        negativeLabel='Remove'
        onClickPositive={handlePostiveClick}
        onClickNegative={handleNegativeClick}
      />
    </>
  );
};

EditExperienceForm.propTypes = {
  setShow: PropTypes.func,
  show: PropTypes.bool,
  experience: PropTypes.object,
};

export default EditExperienceForm;
