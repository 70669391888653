import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Table } from 'react-bootstrap';
import EditCaseForm from './EditCaseForm';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCaseCandidates,
  fetchCasePrincipal,
  selectCaseCandidatesStatus,
  selectCurrentAndUpcomingCasesData,
  selectCaseCandidatesById,
  selectCaseCandidatesError,
} from '../../store/candidates';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import { LoadingSpinner } from '../common/Spinner/Spinner';

export function CurrentAndUpcomingCasesListView({ showAddCaseForm }) {
  let content;
  const dispatch = useDispatch();
  const caseStatus = useSelector(selectCaseCandidatesStatus);
  const casesData = useSelector(selectCurrentAndUpcomingCasesData);
  const casesError = useSelector(selectCaseCandidatesError);

  const [showEditCaseForm, setShowEditCaseForm] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState();
  const selectedCase = useSelector((state) => selectCaseCandidatesById(state, selectedCaseId));

  useEffect(() => {
    if (!showEditCaseForm && !showAddCaseForm) {
      dispatch(fetchCasePrincipal());
      dispatch(fetchCaseCandidates());
    }
  }, [showEditCaseForm, showAddCaseForm, dispatch]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setSelectedCaseId(e.target.id);
    setShowEditCaseForm(true);
  }, []);

  function showProgressBar(item) {
    const remainingProgress = item.remaining_progress;
    if (!remainingProgress) return <></>;

    function calculateBarVariant(remainingProgress) {
      if (remainingProgress <= 32) return 'danger';
      if (33 <= remainingProgress && remainingProgress <= 66) return 'warning';
      if (67 <= remainingProgress && remainingProgress <= 100) return 'success';
    }

    return (
      <div style={{ pointerEvents: 'none' }}>
        {remainingProgress ? (
          <ProgressBar variant={calculateBarVariant(remainingProgress)} now={remainingProgress} />
        ) : (
          <></>
        )}
      </div>
    );
  }

  function hideEditFormHandler() {
    setShowEditCaseForm(false);
  }

  const tableListView = (data) => {
    return data.map((item) => (
      <tr key={item.id} id={item.id} onClick={handleClick} data-testid={`row-${item.id}`}>
        <td id={item.id}>{item.case_code}</td>
        <td id={item.id}>{item.case_name}</td>
        <td id={item.id}>{item.client}</td>
        <td id={item.id}>
          Start: {item.start_date} <br />
          End: {item.end_date}
        </td>
        <td id={item.id}>{item.length_weeks ? `${item.length_weeks} weeks` : <></>}</td>
        <td id={item.id}>
          {item.remaining_weeks ? `${item.remaining_weeks} weeks left` : <></>}
          <br />
          {showProgressBar(item)}
        </td>
        <td id={item.id}>{item.description}</td>
        <td id={item.id}>{item.budget.toLocaleString()}</td>
        <td id={item.id}>{item.principal_display_name}</td>
        <td id={item.id}>{item.xo}</td>
        <td id={item.id}>{item.mdps}</td>
        <td id={item.id}>{item.priority}</td>
        <td data-testid={`complexity-cell-${item.id}`} id={item.id}>
          {item.complexity}
        </td>
        <td id={item.id}>{item.probability}</td>
      </tr>
    ));
  };

  switch (caseStatus) {
    case 'succeeded':
    case 'loading':
      content = tableListView(casesData);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={9}>
            <ErrorWithRetry
              text={casesError}
              onRetry={(_) => {
                dispatch(fetchCaseCandidates());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='tab-content-wrapper'>
        <Table borderless className='listview-table'>
          <thead>
            <tr>
              <th>Case code</th>
              <th>Case Name</th>
              <th>Client</th>
              <th>Timeline</th>
              <th>Length</th>
              <th>Remaining time</th>
              <th>Description</th>
              <th>Budget</th>
              <th>Principal</th>
              <th>XO</th>
              <th>MDPs</th>
              <th>Priority</th>
              <th>Complexity</th>
              <th>Probability</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
        {caseStatus === 'loading' && (
          <div className='table-loading-container'>
            <LoadingSpinner />
          </div>
        )}
        {selectedCase && (
          <EditCaseForm
            case={selectedCase}
            show={showEditCaseForm}
            handleClose={hideEditFormHandler}
            from={'currentAndUpcomingCases'}
          />
        )}
      </div>
    </>
  );
}

CurrentAndUpcomingCasesListView.propTypes = {
  showAddCaseForm: PropTypes.bool,
};
