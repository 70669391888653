import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBuddyCandidates, fetchMentorCandidates, fetchPrincipalCandidates } from '../api/roster';
import { fetchCurrentAndUpcomingCases, fetchCaseAsigneeMembers } from '../api/case';

export const initialState = {
  buddy: {
    data: [],
    status: 'idle',
    error: null,
  },
  mentor: {
    data: [],
    status: 'idle',
    error: null,
  },
  currentAndUpcomingCases: {
    data: [],
    status: 'idle',
    error: null,
  },
  casePrincipal: {
    data: [],
    status: 'idle',
    error: null,
  },
};

export const INTERNAL_CASE_CODES = ['OUTAGE'];

export const fetchBuddy = createAsyncThunk('candidates/buddy', async () => {
  return await fetchBuddyCandidates();
});

export const fetchMentor = createAsyncThunk('candidates/mentor', async () => {
  return await fetchMentorCandidates();
});

export const fetchCaseCandidates = createAsyncThunk('candidates/currentAndUpcomingCases', async () => {
  return await fetchCurrentAndUpcomingCases();
});

export const fetchCasePrincipal = createAsyncThunk('candidates/casePrincipal', async () => {
  return await fetchPrincipalCandidates();
});

export const fetchCaseAssigneeMembers = createAsyncThunk('candidates/caseAssigneeMembers', async (caseId) => {
  return await fetchCaseAsigneeMembers(caseId);
});

const candidatesSlice = createSlice({
  name: 'candidates',
  initialState: initialState,
  reducers: {
    resetCurrentAndUpcomingCases(state, _) {
      state.currentAndUpcomingCases.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuddy.pending, (state, _) => {
      state.buddy.status = 'loading';
    });
    builder.addCase(fetchBuddy.fulfilled, (state, action) => {
      state.buddy.status = 'succeeded';
      state.buddy.data = action.payload;
    });
    builder.addCase(fetchBuddy.rejected, (state, action) => {
      state.buddy.status = 'failed';
      state.buddy.error = action.error.message;
    });
    builder.addCase(fetchMentor.pending, (state, _) => {
      state.mentor.status = 'loading';
    });
    builder.addCase(fetchMentor.fulfilled, (state, action) => {
      state.mentor.status = 'succeeded';
      state.mentor.data = action.payload;
    });
    builder.addCase(fetchMentor.rejected, (state, action) => {
      state.mentor.status = 'failed';
      state.mentor.error = action.error.message;
    });
    builder.addCase(fetchCaseCandidates.pending, (state, _) => {
      state.currentAndUpcomingCases.status = 'loading';
    });
    builder.addCase(fetchCaseCandidates.fulfilled, (state, action) => {
      state.currentAndUpcomingCases.status = 'succeeded';
      state.currentAndUpcomingCases.data = action.payload;
    });
    builder.addCase(fetchCaseCandidates.rejected, (state, action) => {
      state.currentAndUpcomingCases.status = 'failed';
      state.currentAndUpcomingCases.error = action.error.message;
    });
    builder.addCase(fetchCasePrincipal.pending, (state, _) => {
      state.casePrincipal.status = 'loading';
    });
    builder.addCase(fetchCasePrincipal.fulfilled, (state, action) => {
      state.casePrincipal.status = 'succeeded';
      state.casePrincipal.data = action.payload;
    });
    builder.addCase(fetchCasePrincipal.rejected, (state, action) => {
      state.casePrincipal.status = 'failed';
      state.casePrincipal.error = action.error.message;
    });
    builder.addCase(fetchCaseAssigneeMembers.fulfilled, (state, action) => {
      state.currentAndUpcomingCases.data = state.currentAndUpcomingCases.data.map((content, i) => {
        if (Number(content.id) === Number(action.meta.arg)) {
          return {
            ...content,
            assignees: action.payload,
          };
        } else {
          return content;
        }
      });
    });
  },
});

export default candidatesSlice.reducer;

export const { resetCurrentAndUpcomingCases } = candidatesSlice.actions;

export const selectBuddyData = (state) => state.candidates.buddy.data;
export const selectBuddyStatus = (state) => state.candidates.buddy.status;

export const selectMentorData = (state) => state.candidates.mentor.data;
export const selectMentorStatus = (state) => state.candidates.mentor.status;

export const selectCaseCandidatesData = (state) => state.candidates.currentAndUpcomingCases.data;
export const selectCaseCandidatesById = (state, id) =>
  state.candidates.currentAndUpcomingCases.data.find((item) => Number(item.id) === Number(id));
export const selectCaseCandidatesStatus = (state) => state.candidates.currentAndUpcomingCases.status;
export const selectCaseCandidatesError = (state) => state.candidates.currentAndUpcomingCases.error;
export const selectCurrentAndUpcomingCasesData = (state) =>
  state.candidates.currentAndUpcomingCases.data.filter((item) => !INTERNAL_CASE_CODES.includes(item.case_code));

export const selectCasePrincipalData = (state) => state.candidates.casePrincipal.data;
export const selectCasePrincipalStatus = (state) => state.candidates.casePrincipal.status;
