import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import EditExperienceForm from './EditExperienceForm';
import EditButton from '../common/EditButton/EditButton';
import PropTypes from 'prop-types';
import { fetchExperience, selectExperience, selectExperienceById } from '../../store/experience';
import { selectUserLevel } from '../../store/authentication';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import { LoadingSpinner } from '../common/Spinner/Spinner';

export function ExperienceListView(props) {
  let content;
  const dispatch = useDispatch();
  const experience = useSelector(selectExperience);
  const userLevel = useSelector(selectUserLevel);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState();
  const selectedExperienceData = useSelector((state) => selectExperienceById(state, selectedExperience));

  useEffect(() => {
    if (showEditForm || props.showAddNewForm) return;
    dispatch(fetchExperience());
  }, [props.showAddNewForm, showEditForm, dispatch]);

  const handleEdit = (e) => {
    e.preventDefault();
    setSelectedExperience(e.target.id);
    setShowEditForm(true);
  };

  const tableListView = (data) => {
    return data.map((item, index, array) => (
      <tr
        key={item.id}
        data-testid={`row-${item.id}`}
        style={{
          borderTop: index > 0 && data[index - 1].fullname != item.fullname ? '0.626rem solid #F5F5F5' : '0',
        }}
      >
        <td>
          <EditButton id={item.id} onClick={handleEdit} />
        </td>
        <td>
          <div className='fullname-col'>
            <div>{item.fullname}</div>
          </div>
        </td>
        {userLevel !== 3 && (
          <td>
            <div className={`role-badge ${item.role}`}>{item.role}</div>
          </td>
        )}
        <td>
          {item.case_code} <br />
          {item.case_name}
        </td>
        <td>{item.case_client}</td>
        <td>{item.principal}</td>
        <td>{item.module}</td>
      </tr>
    ));
  };

  switch (experience.status) {
    case 'succeeded':
    case 'loading':
      content = tableListView(experience.data);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={9}>
            <ErrorWithRetry
              text={experience.error}
              onRetry={(_) => {
                dispatch(fetchExperience());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <div className='table-wrapper'>
        <Table borderless className='listview-table case-history-table'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              {userLevel !== 3 && <th>Role</th>}
              <th>Case</th>
              <th>Client</th>
              <th>Principal</th>
              <th>Module/Experience</th>
            </tr>
          </thead>
          <tbody data-testid='roster-table-body'>{content}</tbody>
        </Table>
        {experience.status === 'loading' && (
          <div className='table-loading-container'>
            <LoadingSpinner />
          </div>
        )}
        <EditExperienceForm
          experience={selectedExperienceData ?? { id: '' }}
          show={showEditForm}
          setShow={setShowEditForm}
        />
      </div>
    </>
  );
}

ExperienceListView.propTypes = {
  showAddNewForm: PropTypes.bool,
};
