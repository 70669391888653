import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAlumni } from '../api/roster';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchAlumni = createAsyncThunk('roster/alumni', async () => {
  return await getAlumni();
});

const alumniSlice = createSlice({
  name: 'alumni',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAlumni.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchAlumni.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchAlumni.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default alumniSlice.reducer;

export const selectAlumni = (state) => state.alumni;
export const selectAlumniById = (state, id) => {
  return state.alumni.data.find((item) => Number(item.bcger_id) === Number(id));
};
