export function sortByFullname(loadedStaff) {
  if (!loadedStaff) return [];
  return loadedStaff.sort(function (left, right) {
    const fullnameA = left.fullname.toUpperCase();
    const fullnameB = right.fullname.toUpperCase();
    if (fullnameA < fullnameB) {
      return -1;
    }
    if (fullnameA > fullnameB) {
      return 1;
    }
    return 0;
  });
}
