import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

export function FormDropdown({
  classes = 'mb-3',
  name,
  label,
  onChange = () => {},
  dropdownList,
  helpText = '',
  valueKey = 'bcger_id',
  labelKey = 'fullname',
  value,
}) {
  return (
    <Form.Group className={classes}>
      <FloatingLabel label={label} controlId='floatingSelect'>
        <Form.Select onChange={onChange} name={name} data-testid={`select-${name}`} value={value}>
          <option key='not selected' value=''></option>
          {dropdownList?.map((item) => (
            <option key={item[valueKey]} value={item[valueKey]} label={item[labelKey]} />
          ))}
        </Form.Select>
        <Form.Text className='text-muted'>{helpText}</Form.Text>
      </FloatingLabel>
    </Form.Group>
  );
}

FormDropdown.propTypes = {
  classes: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  dropdownList: PropTypes.array,
  helpText: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  value: PropTypes.string,
};
