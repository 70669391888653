import React, { useRef, useState } from 'react';
import { createRosterMemberUrl } from '../../urls';
import { Col, FloatingLabel, Form, Offcanvas, Row } from 'react-bootstrap';
import { Button, toastifyTheme } from '../../theme';
import PropTypes from 'prop-types';
import { Affiliation, defaultStaffing, Ethnic, Gender, OffAxis, Role, Score, Staffing } from './Types';
import { usePostWithCsrf } from '../../hooks/restClient';
import { withNotAvailableOption } from '../OptionSelectUtils';
import { asSelectOptions } from '../types';
import { backendErrorParser } from '../backendErrorParser';
import MenuList from '../common/MenuList/MenuList';
import { Level3RestrictedComponent, Level2And3RestrictedComponent } from '../Forms/RestrictedComponent';
import {
  selectBuddyData,
  selectBuddyStatus,
  selectCaseCandidatesData,
  selectCaseCandidatesStatus,
  selectMentorData,
  selectMentorStatus,
} from '../../store/candidates';
import { useSelector, useDispatch } from 'react-redux';
import { addNewBCGer, selectAllStaff, selectStaffStatus } from '../../store/staff';
import { selectUserLevel } from '../../store/authentication';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { createNewBCGerUrl } from '../../api/urls';

const AddNewRosterPipelineMemberForm = (props) => {
  const staffData = useSelector(selectAllStaff);
  const buddyData = useSelector(selectBuddyData);
  const mentorData = useSelector(selectMentorData);
  const casedData = useSelector(selectCaseCandidatesData);
  const staffStatus = useSelector(selectStaffStatus);
  const buddyStatus = useSelector(selectBuddyStatus);
  const mentorStatus = useSelector(selectMentorStatus);
  const caseStatus = useSelector(selectCaseCandidatesStatus);
  const userLevel = useSelector(selectUserLevel);
  const [formMessage, setFormMessage] = useState();
  const [bcgerOption, setBcgerOption] = useState({});
  const selectStaffingRef = useRef();
  const [isRolePLSelected, setIsRolePLSelected] = useState(false);
  const [creatingNewBcger, setCreatingNewBcger] = useState(false);
  const dispatch = useDispatch();

  const finishLoadData =
    staffStatus === 'succeeded' &&
    buddyStatus === 'succeeded' &&
    mentorStatus === 'succeeded' &&
    caseStatus === 'succeeded';

  function cancelHandler(e) {
    e.preventDefault();
    props.onClose();
  }

  const staffDropdown = staffData.map((staff) => ({ value: staff.id, label: staff.fullname }));

  const casesDropdown = withNotAvailableOption(
    casedData.map((item) => (
      <option key={item.id} value={item.id}>
        {`${item.case_code} - ${item.case_name}`}
      </option>
    ))
  );

  const buddiesDropdown = withNotAvailableOption(
    buddyData.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );

  const mentorsDropdown = withNotAvailableOption(
    mentorData.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );
  const post = usePostWithCsrf();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormMessage(null);
    const data = new FormData(event.currentTarget);
    const bcger = bcgerOption.value; // data.get('bcger');
    const affiliation = data.get('affiliation');
    const preferences = data.get('preferences');
    const tenure = data.get('tenure') <= 99 ? data.get('tenure') : 99;
    const notes = data.get('notes');
    const rf_start = data.get('rfStart') ? data.get('rfStart') : null;
    const rf_end = data.get('rfEnd') ? data.get('rfEnd') : null;
    const staffing = data.get('staffing');
    const role = data.get('role');
    const score = data.get('score');
    const off_axis = data.get('offAxis');
    const gender = data.get('gender');
    const ethnic = data.get('ethnic');
    const buddy = data.get('buddyId');
    const mentor = data.get('mentorId');
    const caseId = data.get('caseId');
    const body = {
      bcger,
      affiliation,
      preferences,
      notes,
      rf_start,
      rf_end,
      staffing,
      role,
      score,
      off_axis,
      gender,
      ethnic,
      buddy,
      mentor,
      active_case: caseId,
      tenure,
    };
    const response = await post(createRosterMemberUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      event.target.reset();
      setFormDefaultValue();
      props.onNewItemAdded();
      props.onClose();
      toast.success(`Roster member ${bcgerOption.label} registered successfully`, toastifyTheme);
    } else {
      const errorBody = await response.json();
      setFormMessage(backendErrorParser(errorBody));
    }
  };

  const setFormDefaultValue = () => {
    selectStaffingRef.current.value = defaultStaffing;
  };

  const handleRoleChange = (e) => {
    setIsRolePLSelected(e.target.value === 'PL');
  };

  const handleCreateBCGer = async (inputValue) => {
    setCreatingNewBcger(true);
    const response = await post(createNewBCGerUrl, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fullname: inputValue }),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(addNewBCGer(data));
      setBcgerOption({ value: data.id, label: data.fullname });
      toast.success(`Added new BCGer ${data.fullname}`, toastifyTheme);
    } else {
      setFormMessage('Create new BCGer failed. Please try again later');
    }
    setCreatingNewBcger(false);
  };

  return (
    <>
      <Offcanvas show={props.show} onHide={props.onClose} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Add new roster/pipeline member</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <Form.Group className='mb-3'>
                  <Form.Label>Name</Form.Label>
                  {staffStatus === 'succeeded' ? (
                    <CreatableSelect
                      isClearable
                      isDisabled={creatingNewBcger}
                      isLoading={creatingNewBcger}
                      onChange={setBcgerOption}
                      onCreateOption={handleCreateBCGer}
                      components={{ MenuList }}
                      options={staffDropdown}
                      value={bcgerOption}
                      defaultValue={bcgerOption}
                      onKeyDown={(event) => {
                        if (!/^[A-Za-z0-9, ()]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  ) : (
                    <LoadingSpinner />
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Col xs={5}>
                  <Form.Group>
                    <FloatingLabel label='Affiliation' controlId='floatingSelect'>
                      <Form.Select name='affiliation' data-testid='select-affiliation'>
                        {asSelectOptions(Affiliation)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='Preferences' className='mb-3'>
                      <Form.Control
                        name='preferences'
                        as='textarea'
                        placeholder='IPA, FPA, etc.'
                        data-testid='input-preferences'
                      />
                      <div className='text-muted'>IPA, FPA, etc. </div>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='Notes' className='mb-3'>
                      <Form.Control name='notes' as='textarea' placeholder='...' data-testid='input-notes' />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='Tenure' className='mb-3'>
                      <Form.Control name='tenure' type='number' min='0' max='99' data-testid='input-tenure' />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='RF Start' className='mb-3'>
                      <Form.Control name='rfStart' type='date' data-testid='input-rfstart' />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingTextarea' label='RF End' className='mb-3'>
                      <Form.Control name='rfEnd' type='date' data-testid='input-rfend' />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Staffing' className='mb-3'>
                      <Form.Select
                        name='staffing'
                        defaultValue={defaultStaffing}
                        ref={selectStaffingRef}
                        data-testid='select-staffing'
                      >
                        {asSelectOptions(Staffing)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <Level3RestrictedComponent userLevel={userLevel}>
                      <FloatingLabel controlId='floatingSelect' label='Role' className='mb-3'>
                        <Form.Select name='role' data-testid='select-role' onChange={handleRoleChange}>
                          {asSelectOptions(Role)}
                        </Form.Select>
                      </FloatingLabel>
                    </Level3RestrictedComponent>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Level2And3RestrictedComponent userLevel={userLevel} isRolePLSelected={isRolePLSelected}>
                  <>
                    <Col>
                      <Form.Group className='mb-3'>
                        <FloatingLabel controlId='floatingSelect' label='Score' className='mb-3'>
                          <Form.Select name='score' data-testid='select-score'>
                            {asSelectOptions(Score)}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className='mb-3'>
                        <FloatingLabel controlId='floatingSelect' label='Off-axis' className='mb-3'>
                          <Form.Select name='offAxis' data-testid='select-offAxis'>
                            {asSelectOptions(OffAxis)}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </>
                </Level2And3RestrictedComponent>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Gender' className='mb-3'>
                      <Form.Select name='gender' data-testid='select-gender'>
                        {asSelectOptions(Gender)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Ethnic' className='mb-3'>
                      <Form.Select name='ethnic' data-testid='select-ethnic'>
                        {asSelectOptions(Ethnic)}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Buddy' className='mb-3'>
                      <Form.Select data-testid='select-buddy' name='buddyId'>
                        {buddiesDropdown}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <FloatingLabel controlId='floatingSelect' label='Mentor' className='mb-3'>
                      <Form.Select data-testid='select-mentor' name='mentorId'>
                        {mentorsDropdown}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='Case' className='mb-3'>
                    <Form.Select data-testid='select-case' name='caseId'>
                      {casesDropdown}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              {finishLoadData && (
                <div className='form-footer-btn-group'>
                  <div></div>
                  <div className='form-footer-btn-group-right'>
                    <Button outlined onClick={cancelHandler} data-testid='cancel'>
                      Cancel
                    </Button>
                    <Button data-testid='submit' type='submit'>
                      Add
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

AddNewRosterPipelineMemberForm.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  onNewItemAdded: PropTypes.func,
};

export default AddNewRosterPipelineMemberForm;
