import { find } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { fetchStaffedOnActiveCase } from '../../api/dashboard';
import { useSelector } from 'react-redux';
import styles from './Dashboard.module.css';

const StaffedOnActiveCases = () => {
  const userLevel = useSelector((state) => state.auth?.user?.level);
  const [data, setData] = useState({});

  const getData = useCallback(async () => {
    const _data = await fetchStaffedOnActiveCase();
    await setData(_data);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const activeCaseGroupByRole = () => {
    if (!data || !data.staff_on_active_cases_by_roles) return <></>;
    const dataGroupedByRole = data.staff_on_active_cases_by_roles.reduce((obj, item) => {
      obj[item.role] = item.count;
      return obj;
    }, {});

    return (
      <div className={styles.summary}>
        <div className={`${styles['summary-box']} ${styles['green-invert']}`}>
          <p className={styles['summary-box-title']}>Total</p>
          <p data-testid='total-count' className={styles['summary-box-content']}>
            {data?.total_staff_on_active_cases || 0}
          </p>
        </div>
        <div className={styles.detail}>
          <div data-testid='pl-count'>{dataGroupedByRole['PL'] || 0}</div>
          <div className='PL'>PL</div>
          <div data-testid='anchor-count'>| {dataGroupedByRole['Anchor'] || 0}</div>
          <div className='Anchor'>Anchor</div>
          <div data-testid='utility-count'>| {dataGroupedByRole['Utility'] || 0}</div>
          <div className='Utility'>Utility</div>
          <div data-testid='developing-count'>| {dataGroupedByRole['Developing'] || 0}</div>
          <div className='Developing'>Developing</div>
        </div>
      </div>
    );
  };

  const activeCaseGroupByStaffing = () => {
    if (!data || !data.staff_on_active_cases_by_staffing) return <></>;
    const dataGroupedByStaffing = data.staff_on_active_cases_by_staffing.reduce(
      (obj, item) => {
        if (item.staffing === 'PL') {
          obj[item.staffing] = item.count;
        } else if (['APC', 'C2', 'A2'].includes(item.staffing)) {
          obj['second_year'] += Number(item.count);
        } else if (['C1', 'A1'].includes(item.staffing)) {
          obj['first_year'] += Number(item.count);
        }
        return obj;
      },
      { PL: 0, second_year: 0, first_year: 0 }
    );

    return (
      <div className={styles.summary}>
        <div className={`${styles['summary-box']} ${styles['green-invert']}`}>
          <p className={styles['summary-box-title']}>Total</p>
          <p data-testid='total-count' className={styles['summary-box-content']}>
            {data?.total_staff_on_active_cases}
          </p>
        </div>
        <div className={styles.detail}>
          <div data-testid='pl-count'>{dataGroupedByStaffing['PL'] || 0}</div>
          <div className='PL'>PL</div>
          <div data-testid='second-year-count'>| {dataGroupedByStaffing['second_year'] || 0}</div>
          <div className='Anchor'>Second years</div>
          <div data-testid='first-year-count'>| {dataGroupedByStaffing['first_year'] || 0}</div>
          <div className='Utility'>First years</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <h3>Staffed on active cases</h3>
      <>
        {userLevel !== 3 && activeCaseGroupByRole()}
        {userLevel === 3 && activeCaseGroupByStaffing()}
        <div className='row-nowrap no-scroll-display d-flex justify-content-start align-items-start flex-wrap'>
          {data?.principal_active_cases?.map((ele, id) => (
            <div className={styles['active-case-box']} key={id}>
              <div className={styles['active-case-divrincipal']}>{ele.fullname}</div>
              <div className={styles['active-case-count']}>{ele.active_case_count} active cases</div>
              <div className={`${styles['active-case-names']}`}>
                {ele.active_case_names.map((ele, _id) => (
                  <div key={_id}>
                    <div className={`${styles['case-name-tag']} ${styles['active-case-name']}`}>{ele}</div>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    </>
  );
};

export default StaffedOnActiveCases;
