import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { fetchCasesStartingNextWeek } from '../../api/dashboard';
import styles from './Dashboard.module.css';
import { map } from 'lodash';

const CasesStartingNextWeek = () => {
  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    const _data = await fetchCasesStartingNextWeek();
    setData(
      map(_data, (ele) => {
        const startDate = new Date(ele.start_date);
        return {
          ...ele,
          month: startDate.toLocaleString('default', { month: 'long' }),
          date: startDate.getDate(),
        };
      })
    );
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h3>Cases starting next week</h3>
      <div className={styles['cases-wrapper']}>
        {map(data, (ele, id) => (
          <div key={id} className={styles.summary}>
            <div className={`${styles['summary-box']} ${styles.black}`}>
              <p className={styles['summary-box-title']}>{ele.month}</p>
              <p className={styles['summary-box-content']}>{ele.date}</p>
            </div>
            <div style={{ textAlign: 'left' }}>
              <div>Client: {ele.client}</div>
              <div className={styles['case-name-tag']} style={{ backgroundColor: '#EBEEFD' }}>
                {ele.case_name}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CasesStartingNextWeek;
