import React from 'react';

export const Affiliation = [
  { key: 'RF', viewableText: 'RF' },
  { key: 'GP', viewableText: 'GP' },
  { key: 'P', viewableText: 'P' },
  { key: 'A', viewableText: 'A' },
];

export const Staffing = [
  { key: 'N/A', viewableText: 'N/A' },
  { key: 'PL', viewableText: 'PL' },
  { key: 'A2', viewableText: 'A2' },
  { key: 'APC', viewableText: 'APC' },
  { key: 'C2', viewableText: 'C2' },
  { key: 'A1', viewableText: 'A1' },
  { key: 'C1', viewableText: 'C1' },
];

export const defaultStaffing = 'APC';

export const Role = [
  { key: 'OP', viewableText: 'OP' },
  { key: 'PL', viewableText: 'PL' },
  { key: 'Anchor', viewableText: 'Anchor' },
  { key: 'Utility', viewableText: 'Utility' },
  { key: 'Developing', viewableText: 'Developing' },
];

export const Score = [
  { key: '0', viewableText: '0' },
  { key: '1', viewableText: '1' },
  { key: '2', viewableText: '2' },
  { key: '3', viewableText: '3' },
  { key: '4', viewableText: '4' },
  { key: '5', viewableText: '5' },
  { key: '6', viewableText: '6' },
  { key: '7', viewableText: '7' },
  { key: '8', viewableText: '8' },
  { key: '9', viewableText: '9' },
  { key: '10', viewableText: '10' },
];

export const OffAxis = [
  { key: 'Y', viewableText: 'Yes' },
  { key: 'N', viewableText: 'No' },
];
export const Gender = [
  { key: 'F', viewableText: 'F' },
  { key: 'M', viewableText: 'M' },
];
export const Ethnic = [
  { key: 'Y', viewableText: 'Yes' },
  { key: 'N', viewableText: 'No' },
];
