import React from 'react';
import PropTypes from 'prop-types';
import CapacityBlock from './CapacityBlock';
import Divider from '@mui/material/Divider';
import { red } from '@mui/material/colors';

const Capacity = ({ label, capacity_list = [] }) => {
  const capacityBlockList = (capacity_list) => (
    <>
      <CapacityBlock role='PL' members={capacity_list.find((capacity) => capacity.role == 'PL')?.roster_members} />
      <Divider
        sx={{
          backgroundColor: 'transparent',
        }}
      />
      <CapacityBlock
        role='Anchor'
        members={capacity_list.find((capacity) => capacity.role == 'Anchor')?.roster_members}
      />
      <Divider
        sx={{
          backgroundColor: 'transparent',
        }}
      />
      <CapacityBlock
        role='Utility'
        members={capacity_list.find((capacity) => capacity.role == 'Utility')?.roster_members}
      />
      <Divider
        sx={{
          backgroundColor: 'transparent',
        }}
      />
      <CapacityBlock
        role='Developing'
        members={capacity_list.find((capacity) => capacity.role == 'Developing')?.roster_members}
      />
    </>
  );
  return (
    <div className='capacity-container'>
      <div className='capacity-header'>{label}</div>
      <div className='capacity-body'>{capacityBlockList(capacity_list)}</div>
    </div>
  );
};

Capacity.propTypes = {
  label: PropTypes.string,
  capacity_list: PropTypes.array,
};

export default Capacity;
