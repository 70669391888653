import { mapValues, reduce } from 'lodash';

export const availableCapacityFormatter = (data) =>
  mapValues(data, (capacity) => {
    return reduce(
      capacity,
      (res, val) => {
        res[val.role] = val;
        return res;
      },
      {}
    );
  });
