import React from 'react';
import PropTypes from 'prop-types';

const AddNewButton = ({ text = 'Add new', onClick = () => {}, styles = {} }) => {
  return (
    <div className='add-new-btn-container' data-testid='add-new-btn' onClick={onClick}>
      <div className='add-new-btn-icon'>
        <div className='add-new-btn-plus-icon'>+</div>
      </div>
      <div className='add-new-btn-text'>{text}</div>
    </div>
  );
};

AddNewButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.object,
};

export default AddNewButton;
