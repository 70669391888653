import React, { useState } from 'react';
import GenericViewLayout from '../GenericHeader';
import banner from '../../icons/greenBanner.svg';
import { OutageCalendarView } from './OutageCalendarView';
import OutageForm from './OutageForm';
import Button from 'react-bootstrap/Button';
import moment from 'moment/moment';
const Outage = () => {
  const [showForm, setShowForm] = useState(false);
  const [startDate, setstartDate] = useState('');

  const showAddFormHandler = (e) => {
    setstartDate();
    setShowForm(true);
  };

  const onCellClick = async (cellInfo) => {
    const cellDate = cellInfo.start;
    await setstartDate(moment(cellDate).format('YYYY-MM-DD'));
    setShowForm(true);
  };

  return (
    <>
      <GenericViewLayout
        header={'Outages'}
        rightButton={
          <Button
            variant='light'
            onClick={showAddFormHandler}
            className='header-button'
            data-testid='add-new-outage-btn'
          >
            + Add new outage
          </Button>
        }
        banner={banner}
      />
      <OutageCalendarView onSelectCell={onCellClick} />
      <OutageForm showForm={showForm} setShowForm={setShowForm} startDate={startDate} />
    </>
  );
};
export default Outage;
