import React from 'react';

export const Priority = [
  { key: '0', viewableText: '0' },
  { key: '1', viewableText: '1' },
  { key: '2', viewableText: '2' },
  { key: '3', viewableText: '3' },
  { key: '4', viewableText: '4' },
  { key: '5', viewableText: '5' },
  { key: '6', viewableText: '6' },
  { key: '7', viewableText: '7' },
  { key: '8', viewableText: '8' },
  { key: '9', viewableText: '9' },
  { key: '10', viewableText: '10' },
];

export const Complexity = [
  { key: '1', viewableText: '1' },
  { key: '2', viewableText: '2' },
  { key: '3', viewableText: '3' },
  { key: '4', viewableText: '4' },
  { key: '5', viewableText: '5' },
];

export const Probability = [
  { key: 'Sold', viewableText: 'Sold' },
  { key: 'Probably sold', viewableText: 'Probably sold' },
  { key: 'Lead', viewableText: 'Lead' },
  { key: 'Extension - High', viewableText: 'Extension - High' },
  { key: 'Extension - Low', viewableText: 'Extension - Low' },
];
