import React, { useState } from 'react';
import ImportStaff from './ImportStaff';
import GenericHeader from '../GenericHeader';
import banner from '../../icons/greenBanner.svg';
import Button from 'react-bootstrap/Button';

const Import = () => {
  const [importFormIsShown, setImportFormIsShown] = useState(false);
  const hideImportFormHandler = () => {
    setImportFormIsShown(false);
  };
  const showImportFormHandler = () => {
    setImportFormIsShown(true);
  };

  return (
    <>
      <div style={{ height: 400, width: '100%' }}>
        <GenericHeader
          header={'Import red zone report'}
          rightButton={
            <Button
              variant='light'
              data-testid='show-import-form-button'
              onClick={showImportFormHandler}
              className='header-button'
            >
              Import Staff
            </Button>
          }
          banner={banner}
        ></GenericHeader>
        <ImportStaff show={importFormIsShown} handleClose={hideImportFormHandler} />
      </div>
    </>
  );
};

export default Import;
