import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import PropTypes, { string } from 'prop-types';
import TextField from '@mui/material/TextField';

export function FormField({
  className = 'mb-3',
  name,
  label,
  type,
  value,
  onChange = () => {},
  readOnly = false,
  disabled = false,
  helpText = '',
  otherProps = {},
}) {
  let inputLabelProps = {};
  if (type == 'date') {
    inputLabelProps = {
      InputLabelProps: { shrink: true },
    };
  }
  return (
    <Form.Group className={className}>
      <TextField
        {...inputLabelProps}
        label={label}
        variant='standard'
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        InputProps={{
          readOnly: readOnly,
        }}
        disabled={disabled}
        aria-label={label}
        fullWidth
        {...otherProps}
      />
      <Form.Text className='text-muted'>{helpText}</Form.Text>
    </Form.Group>
  );
}

FormField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  otherProps: PropTypes.object,
};
