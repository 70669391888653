import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentRosters } from '../api/roster';

export const SORTED_BY_CASE = 'case';
export const SORTED_BY_STAFF = 'staff';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchCurrentRosters = createAsyncThunk('roster/current-rosters', async (query = '') => {
  return await getCurrentRosters(query);
});

const currentRosterSlice = createSlice({
  name: 'currentRoster',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentRosters.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchCurrentRosters.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchCurrentRosters.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default currentRosterSlice.reducer;

export const selectCurrentRosters = (state) => state.currentRoster.data;
export const selectRosterById = (state, id) => {
  return state.currentRoster.data.find((item) => Number(item.bcger_id) === Number(id));
};
export const selectCurrentRostersStatus = (state) => state.currentRoster.status;
export const selectCurrentRostersError = (state) => state.currentRoster.error;
export const selectCurrentRostersByName = (state) => {
  const data = [...state.currentRoster.data];
  return data.sort((a, b) => a.fullname?.localeCompare(b.fullname));
};
