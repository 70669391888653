import React, { useEffect } from 'react';
import GenericViewLayout from '../GenericHeader';
import banner from '../../icons/greenBanner.svg';
import Capacity from './Capacity';
import { Row } from 'react-bootstrap';
import styles from '../Dashboard/Dashboard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchStaffingCapacity,
  selectAllStaffingCapacity,
  selectStaffingCapacityStatus,
  selectStaffingCapacityError,
} from '../../store/staffingCapacity';
import ErrorWithRetry from '../common/ErrorWithRetry/ErrorWithRetry';
import { LoadingSpinner } from '../common/Spinner/Spinner';
import ManualDraftStaffingForm from './ManualDraftStaffingForm';

const Optimization = () => {
  const staffingCapacityData = useSelector(selectAllStaffingCapacity);
  const status = useSelector(selectStaffingCapacityStatus);
  const error = useSelector(selectStaffingCapacityError);
  const dispatch = useDispatch();
  let content;

  useEffect(() => {
    dispatch(fetchStaffingCapacity());
  }, [dispatch]);

  const staffingCapacityListView = (capacity_list) => (
    <div className='optimization-capacity'>
      <Capacity label='Free this week' capacity_list={capacity_list['this_week_capacity']} />
      <Capacity label='Free next week' capacity_list={capacity_list['next_week_capacity']} />
      <Capacity label='Free next two weeks' capacity_list={capacity_list['week_after_next_capacity']} />
    </div>
  );

  switch (status) {
    case 'succeeded':
    case 'loading':
      content = staffingCapacityListView(staffingCapacityData);
      break;
    case 'failed':
      content = (
        <tr>
          <td colSpan={2}>
            <ErrorWithRetry
              text={error}
              onRetry={(e) => {
                dispatch(fetchStaff());
              }}
            />
          </td>
        </tr>
      );
      break;
  }

  return (
    <>
      <GenericViewLayout topHeader={'Staffing'} header={'Automated optimization'} banner={banner} />
      <div className={styles.dbcontainer}>
        <Row className='mb-3'>
          <div className='col'>
            <div className={styles['dashboard-panel']}>
              <div className='optimization-item-container'>
                <h3 className='mb-3'>Capacity over next 2 weeks</h3>
                {content}
              </div>
            </div>
          </div>
        </Row>
        <Row className='mb-3'>
          <div className='col-lg-6 col-md-12'>
            <div className={styles['dashboard-panel']}>
              <h3 className='mb-2 d-block'>Automated staffing algorithm</h3>
            </div>
          </div>
          <div className='col-lg-6 col-md-12'>
            <div className={styles['dashboard-panel']}>
              <div className='optimization-item-container'>
                <h3 className='mb-2 d-block'>Manual draft staffing</h3>
                <div className='optimization-help-text mb-3'>
                  Please select a case from the list below to manually assign staff.
                </div>
                <ManualDraftStaffingForm />
              </div>
            </div>
          </div>
        </Row>
      </div>
      {status === 'loading' && (
        <div className='table-loading-container'>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default Optimization;
