import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Button, toastifyTheme } from '../../theme';
import { editDevelopment, createReview } from '../../api/development';
import { FormField } from '../Forms/FormField';
import { toast } from 'react-toastify';
import { backendErrorParser } from '../backendErrorParser';

const ACDevelopmentForm = ({ development = {}, showForm, setShowForm }) => {
  const { name, role, all_experience, latest_review, bcger } = development;
  const csrf = useSelector((state) => state.auth?.csrf);
  const [formMessage, setFormMessage] = useState(null);
  const [formData, setFormData] = useState({
    reviewer: '',
    reviewer: '',
    review_date: '',
    strengths: '',
    AFDs: '',
    next_step: '',
    score_overall: '',
    score_PSI: '',
    score_CP: '',
    score_PE: '',
    score_CI: '',
    score_TC: '',
    score_RM: '',
    roster_member: '',
  });

  const formatDate = (date) => date && new Date(`${date} GMT`).toISOString().split('T')[0];

  useEffect(() => {
    setFormData({
      ...latest_review,
      review_date: formatDate(latest_review?.review_date),
      roster_member: bcger,
    });
    setFormMessage(null);
  }, [showForm]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = latest_review
      ? await editDevelopment(latest_review.id)(csrf, {
          body: JSON.stringify(formData),
        })
      : await createReview(csrf, {
          body: JSON.stringify(formData),
        });
    if (res.success) {
      setShowForm(false);
      toast.success('Your changes were saved successfully!', toastifyTheme);
    } else {
      setFormMessage(backendErrorParser(res.data));
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onHideHandler = (e) => {
    e?.preventDefault();
    setShowForm(false);
  };

  return (
    <>
      <Offcanvas show={showForm} onHide={onHideHandler} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Edit Review</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <FormField className='mb-3 col-6' name='name' value={name ?? ''} disabled={true} label='Name' />
                <FormField className='mb-3 col-6' name='role' value={role ?? ''} disabled={true} label='Role' />
              </Row>
              <Row>
                <FormField
                  className='mb-3 col-6'
                  name='reviewer'
                  value={formData.reviewer ?? ''}
                  label='Reviewer'
                  onChange={handleChange}
                />
                <FormField
                  className='mb-3 col-6'
                  name='review_date'
                  value={formData.review_date ?? ''}
                  label='Review Date'
                  onChange={handleChange}
                  type='date'
                />
              </Row>
              <Row>
                <FormField
                  name='experience'
                  value={
                    all_experience?.reduce((prev, curr) => `${prev} ${'\n'} ${curr.case_name} (${curr.module})`, '') ??
                    ''
                  }
                  label='Experience'
                  disabled={true}
                />
              </Row>
              <Row>
                <FormField
                  name='strengths'
                  value={formData.strengths ?? ''}
                  label='Strengths'
                  onChange={handleChange}
                />
              </Row>
              <Row>
                <FormField name='AFDs' value={formData.AFDs ?? ''} label='AFDs' onChange={handleChange} />
              </Row>
              <Row>
                <FormField
                  name='next_step'
                  value={formData.next_step ?? ''}
                  label='Next Steps'
                  onChange={handleChange}
                />
              </Row>
              <Row>
                <FormField
                  className='mb-3 col-3'
                  name='score_overall'
                  value={formData.score_overall ?? ''}
                  label='Overall'
                  onChange={handleChange}
                />
                <FormField
                  className='mb-3 col-3'
                  name='score_PSI'
                  value={formData.score_PSI ?? ''}
                  label='PS&I'
                  onChange={handleChange}
                />
                <FormField
                  className='mb-3 col-3'
                  name='score_CP'
                  value={formData.score_CP ?? ''}
                  label='C&P'
                  onChange={handleChange}
                />
                <FormField
                  className='mb-3 col-3'
                  name='score_PE'
                  value={formData.score_PE ?? ''}
                  label='P&E'
                  onChange={handleChange}
                />
              </Row>
              <Row>
                <FormField
                  className='mb-3 col-3'
                  name='score_CI'
                  value={formData.score_CI ?? ''}
                  label='CI'
                  onChange={handleChange}
                />
                <FormField
                  className='mb-3 col-3'
                  name='score_TC'
                  value={formData.score_TC ?? ''}
                  label='TC'
                  onChange={handleChange}
                />
                <FormField
                  className='mb-3 col-3'
                  name='score_RM'
                  value={formData.score_RM ?? ''}
                  label='RM'
                  onChange={handleChange}
                />
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              <div className='form-footer-btn-group'>
                <div></div>
                <div className='form-footer-btn-group-right'>
                  <Button data-testid='cancel' outlined onClick={onHideHandler}>
                    Cancel
                  </Button>
                  <Button data-testid='submit' type='submit'>
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
ACDevelopmentForm.propTypes = {
  development: PropTypes.object,
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
};
export default ACDevelopmentForm;
