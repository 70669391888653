import React, { useEffect, useRef, useState } from 'react';
import { usePostWithCsrf } from '../../hooks/restClient';
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { backendErrorParser } from '../backendErrorParser';
import { withNotAvailableOption } from '../OptionSelectUtils';
import { getCaseAssignMembersUrl } from '../../api/urls';
import { useSelector, useDispatch } from 'react-redux';
import RemoveIcon from '../../icons/Remove.svg';
import { fetchCurrentRosters, selectCurrentRostersByName, selectCurrentRostersStatus } from '../../store/currenRoster';
import { fetchCaseAssigneeMembers, selectCaseCandidatesById } from '../../store/candidates';
import { selectPastCaseById } from '../../store/pastCase';
import styles from './CaseAssignMembersForm.module.css';

const CaseAssignMembersForm = (props) => {
  const currentCase = useSelector((state) => {
    if (props.from === 'currentAndUpcomingCases') {
      return selectCaseCandidatesById(state, props.caseId);
    } else {
      return selectPastCaseById(state, props.caseId);
    }
  });
  const nAssignees = currentCase.assignees?.length ?? 0;
  const post = usePostWithCsrf();
  const dispatch = useDispatch();
  const rosterData = useSelector(selectCurrentRostersByName);
  const rosterStatus = useSelector(selectCurrentRostersStatus);
  const rosterMembersRef = useRef([]);
  const rolesRef = useRef([]);
  const [currentAssigneeIds, setCurrentAssigneeIds] = useState([]);

  const finishLoadData = rosterStatus === 'succeeded' && currentCase.assignees;

  useEffect(() => {
    if (rosterStatus === 'idle') {
      dispatch(fetchCurrentRosters());
    }
    dispatch(fetchCaseAssigneeMembers(props.caseId));
  }, []);

  useEffect(() => {
    if (finishLoadData && nAssignees > 0) {
      currentCase.assignees.forEach((element, index) => {
        rosterMembersRef.current[index].value = element.bcger_id;
        rolesRef.current[index].value = element.role;
      });
      setCurrentAssigneeIds(currentCase.assignees?.map((i) => String(i.bcger_id)));
    }
  }, [finishLoadData]);

  const rosterMembersDropDown = withNotAvailableOption(
    rosterData.map((item) => (
      <option key={item.bcger_id} value={item.bcger_id}>
        {item.fullname}
      </option>
    ))
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    const memberIds = rosterMembersRef.current.filter((i) => i.value).map((i) => String(i.value));

    if (isEqual(memberIds.sort(), currentAssigneeIds.sort())) {
      props.showFormMessage('');
      return;
    }
    const response = await post(getCaseAssignMembersUrl(props.caseId), {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(memberIds),
    });

    if (response.ok) {
      props.showToastMessage(`Assigned ${memberIds.length} members successfully`);
      setCurrentAssigneeIds(memberIds);
    } else {
      const errorBody = await response.json();
      props.showFormMessage(backendErrorParser(errorBody));
    }
  };

  const handleRosterMembersDropdownChange = (event) => {
    const currentRef = event.target;
    const index = rosterMembersRef.current.indexOf(currentRef);

    const member = rosterData.find((it) => it.bcger_id == currentRef.value);

    rolesRef.current[index].value = member ? member.role : '';
  };

  const clearAssignee = (event) => {
    const index = event.target.id;
    if (rosterMembersRef.current[index] && rolesRef.current[index]) {
      rosterMembersRef.current[index].value = '';
      rolesRef.current[index].value = '';
    }
  };

  return (
    <div>
      <h3>Assign Members</h3>
      <Form
        onSubmit={handleSubmit}
        className={`${styles['assign-member-form']} ${finishLoadData ? '' : styles['hide']}`}
      >
        {Array.from(Array(nAssignees + 6).keys()).map((item) => (
          <Row key={item}>
            <Form.Group className='mb-2 col-sm-6'>
              <FloatingLabel label='Roster member' className='mb-3'>
                <Form.Select
                  data-testid={`assignee-name-${item}`}
                  ref={(i) => (rosterMembersRef.current[item] = i)}
                  onChange={handleRosterMembersDropdownChange}
                >
                  {rosterMembersDropDown}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className='mb-1 col-sm-5'>
              <FloatingLabel label='Role'>
                <Form.Control ref={(i) => (rolesRef.current[item] = i)} readOnly />
              </FloatingLabel>
            </Form.Group>
            {currentCase.assignees && currentCase.assignees[item] && (
              <Form.Group className='col-sm-1 cta-button remove-button'>
                <div onClick={clearAssignee} data-testid={`remove-assignee-${item}`}>
                  <img id={item} src={RemoveIcon} />
                </div>
              </Form.Group>
            )}
          </Row>
        ))}
        <button ref={props.submitRef} type='submit' style={{ display: 'none' }}></button>
      </Form>
    </div>
  );
};

CaseAssignMembersForm.propTypes = {
  submitRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  showFormMessage: PropTypes.func,
  showToastMessage: PropTypes.func,
  caseId: PropTypes.number,
  from: PropTypes.string,
};

export default CaseAssignMembersForm;
