import React from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '../../../icons/Remove.svg';

const RemoveButton = ({ id, onClick = () => {}, styles = {} }) => {
  return (
    <div
      className='cta-button remove-button'
      id={id}
      onClick={onClick}
      data-testid={`remove-${id}`}
      style={{ ...styles }}
    >
      <img src={RemoveIcon} />
      <div className='remove-button-text'>Remove</div>
    </div>
  );
};

RemoveButton.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.object,
};

export default RemoveButton;
