import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form, Offcanvas, Row } from 'react-bootstrap';
import { Button, toastifyTheme } from '../../theme';
import PropTypes from 'prop-types';
import { createExperienceUrl, getAddExperienceCandidatesUrl, getCasesUrl } from '../../urls';
import { backendErrorParser } from '../backendErrorParser';
import { sortByFullname } from '../staffSorter';
import { sortByCaseCode } from '../Roster/caseSorter';
import fetchCandidates from '../candidateFetcher';
import { usePostWithCsrf } from '../../hooks/restClient';
import { toast } from 'react-toastify';

const AddExperienceForm = (props) => {
  const post = usePostWithCsrf();

  const [addExperienceCandidates, setAddExperienceCandidates] = useState([]);
  const [cases, setCases] = useState([]);
  const [formMessage, setFormMessage] = useState();

  useEffect(() => {
    const fetchCases = async () => {
      const response = await fetch(getCasesUrl, { credentials: 'include' });
      if (!response.ok) {
        throw new Error('Get cases list failed');
      }
      const responseData = await response.json();
      return responseData.map((item) => ({
        id: item.id,
        case_code: item.case_code,
        case_name: item.case_name,
        client: item.client,
      }));
    };
    fetchCandidates(getAddExperienceCandidatesUrl)
      .then((candidates) => {
        setAddExperienceCandidates(sortByFullname(candidates));
        setFormMessage(null);
      })
      .catch((error) => {
        setFormMessage('Fetch add experience candidates failed');
        console.log(error);
      });

    fetchCases()
      .then((cases) => {
        setCases(sortByCaseCode(cases));
        setFormMessage(null);
      })
      .catch((error) => {
        setFormMessage('Fetch cases failed');
        console.log(error);
      });
  }, []);
  const addExperienceCandidateDropdown = addExperienceCandidates.map((item) => (
    <option key={item.bcger_id} value={item.bcger_id}>
      {item.fullname}
    </option>
  ));

  const casesDropdown = cases.map((item) => (
    <option key={item.id} value={item.id}>
      {`${item.case_code} - ${item.case_name} (${item.client})`}
    </option>
  ));
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormMessage(null);
    const data = new FormData(event.currentTarget);
    const roster_member = data.get('roster-member');
    const module = data.get('module');

    const caseId = data.get('caseId');
    const body = {
      roster_member_id: roster_member,
      module: module,
      case_id: caseId,
    };
    const response = await post(createExperienceUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      props.onClose();
      toast.success('Experience created successfully', toastifyTheme);
    } else {
      const errorBody = await response.json();
      setFormMessage(backendErrorParser(errorBody));
    }
    event.target.reset();
  };

  function cancelHandler(e) {
    e.preventDefault();
    props.onClose();
  }
  return (
    <>
      <Offcanvas show={props.show} onHide={props.onClose} placement='end'>
        <Offcanvas.Header closeButton>
          <h3>Add new case experience</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className='form-body'>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel label='Name' controlId='floatingSelect'>
                    <Form.Select name='roster-member' data-testid='select-roster-member'>
                      {addExperienceCandidateDropdown}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='Case' className='mb-3'>
                    <Form.Select data-testid='select-case' name='caseId'>
                      {casesDropdown}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <FloatingLabel controlId='floatingTextarea' label='Module/Experience' className='mb-3'>
                    <Form.Control name='module' as='textarea' placeholder='dummy' data-testid='input-module' />
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </div>
            <div className='form-footer'>
              {formMessage && <div className='form-footer-message'>{formMessage}</div>}
              <div className='form-footer-btn-group'>
                <div></div>
                <div className='form-footer-btn-group-right'>
                  <Button outlined onClick={cancelHandler} data-testid='cancel'>
                    Cancel
                  </Button>
                  <Button data-testid='submit' type='submit'>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

AddExperienceForm.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
};
export default AddExperienceForm;
