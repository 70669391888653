import React from 'react';
import PropTypes from 'prop-types';
import styles from './CalendarToolbar.module.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const CalendarToolbar = (props) => {
  return (
    <>
      <div className={styles['month-navigator']}>
        <div style={{ float: 'left' }}>
          <button
            aria-label='prev-month'
            className='toolbar-navigation-button'
            type='button'
            onClick={() => props.onNavigate('PREV')}
          >
            <BsChevronLeft />
          </button>
          <button
            aria-label='next-month'
            className='toolbar-navigation-button'
            type='button'
            onClick={() => props.onNavigate('NEXT')}
          >
            <BsChevronRight />
          </button>
          <button className='toolbar-navigation-button today' type='button' onClick={() => props.onNavigate('TODAY')}>
            Today
          </button>
        </div>
        <h6 style={{ color: 'black' }}>{props.label}</h6>
      </div>
    </>
  );
};

CalendarToolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

export default CalendarToolbar;
