import React from 'react';
import PropTypes from 'prop-types';

export function Level3RestrictedComponent({ children, userLevel }) {
  return <>{userLevel !== 3 && children}</>;
}

Level3RestrictedComponent.propTypes = {
  children: PropTypes.node,
  userLevel: PropTypes.number,
};

export function Level2And3RestrictedComponent({ children, userLevel, isRolePLSelected }) {
  return (
    <Level3RestrictedComponent userLevel={userLevel}>
      <>{!(userLevel === 2 && isRolePLSelected) && children}</>
    </Level3RestrictedComponent>
  );
}

Level2And3RestrictedComponent.propTypes = {
  children: PropTypes.node,
  userLevel: PropTypes.number,
  isRolePLSelected: PropTypes.bool,
};
