import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPotentialPipeline } from '../api/roster';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchPipeline = createAsyncThunk('roster/potential-pipeline', async () => {
  return await getPotentialPipeline();
});

const pipelineSlice = createSlice({
  name: 'potentialPipeline',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPipeline.pending, (state, _) => {
      state.status = 'loading';
    });
    builder.addCase(fetchPipeline.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchPipeline.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

export default pipelineSlice.reducer;

export const selectPipeline = (state) => state.pipeline;
export const selectPipelineById = (state, id) => {
  return state.pipeline.data.find((item) => Number(item.bcger_id) === Number(id));
};
